import * as React from 'react';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import JoditEditor from 'jodit-react';
import { createNotice } from '../../services/noticesService';


function UpdateNotices({setOpen,data=null,handleTabelApiCall}){
  const editor = React.useRef(null);
  const [type, setType] = React.useState(data?.type || '');
  const [template,setTemplate] = React.useState(data?.description || '');
  const [startDate,setStartDate] = React.useState(new Date(data?.startDate))
  const [endDate,setEndDate] = React.useState(new Date(data?.endDate))

  const handleChange = (event) => {
    console.log(event)
    setType(event.target.value);
  };

  const handleCreateNotice = async()=>{
    // const time = new Date().getTime().toString();
    if(template.trim() !==''&& startDate && endDate && type){

      const response = await  createNotice({
        ...data,
      description:template,
      startDate:startDate.toISOString(),
      endDate:endDate.toISOString(),
      type,
      createdAt:data?.createdAt || new Date().toISOString()
     })
     console.log(response,'...response')
    }
    handleTabelApiCall()
    setType('')
    setTemplate('')
    setStartDate(new Date())
    setEndDate(new Date())
    setOpen(false)



  }

    console.log(data,"...Data")
    return(
        <>
        <div style={{padding:'30px'}}>
        <h4>Details:</h4>

<div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
<LocalizationProvider dateAdapter={AdapterDayjs} >
      <DemoContainer
        components={[
          'DatePicker'
        ]}
      >

        <DemoItem label="Start Date*">
          <DatePicker defaultValue={dayjs(startDate)}
          onChange={(newValue) => setStartDate(newValue)}
          />
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer
        components={[
          'DatePicker'
        ]}
      >

        <DemoItem label="End Date*" >
          <DatePicker   onChange={(newValue) => setEndDate(newValue)}
 defaultValue={dayjs(endDate)} />
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
    <Box sx={{ minWidth: 190 }}>
<label style={{padding:'5px 0',fontFamily:'"Roboto","Helvetica","Arial",sans-serif',fontWeight:'300'}}>Type*</label>
<FormControl fullWidth>
        {/* <InputLabel id="demo-simple-select-label"> </InputLabel> */}
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={type}
          defaultValue='Information'
        //   label="Age"
          onChange={handleChange}
          name='type'
        >
          <MenuItem value={'Information'}>Information</MenuItem>
          <MenuItem value={'Maintenance'}>Maintenance</MenuItem>
          <MenuItem value={'Upgrade Advise'}>Upgrade Advise</MenuItem>
        </Select>
      </FormControl>
    </Box>



            </div>
    <div style={{margin:"20px 0"}}>
<label style={{padding:'5px 0',fontFamily:'"Roboto","Helvetica","Arial",sans-serif',fontWeight:'300'}}>Support Notice Text*</label>

{/* <textarea style={{width:'100%',minHeight:'244px'}}>
In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.
</textarea> */}

<JoditEditor
                        ref={editor}
                        value={template}
                        config={{
                            //buttons: ["bold","italic","underline","strikethrough","eraser","ul","ol","font","fontsize","paragraph","classSpan","lineHeight","superscript","subscript","cut","copy","paste","selectall","hr"],
                            disablePlugins: ["ordered-list","about","add-new-line","print","fullsize","preview","find","copyformat","video","image","file"]
                        }}
                        //config={config}
                        //tabIndex={1} // tabIndex of textarea
                        onBlur={newContent => setTemplate(newContent)} // preferred to use only this option to update the content for performance reasons
                        //onChange={newContent => {}}
                        className='jodit-wysiwyg'
                        
                    />

    </div>
<div style={{ display:'flex' ,justifyContent:'flex-start',alignItems:'center'}}>
<button onClick={handleCreateNotice} style={{padding:'10px 40px',borderRadius:'5px',background:'#3d3dbe',color:'#fff',marginRight:'10px',fontWeight:'bold',border:'none'}}>UPDATE</button>
<button onClick={()=>setOpen(false)} style={{padding:'10px 40px',borderRadius:'5px',background:'#a9a9a9',color:'#fff',marginRight:'10px',fontWeight:'bold',border:'none'}}>CANCEL</button>
</div>

</div>
        </>
    )
}

export default UpdateNotices;