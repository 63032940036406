import React from "react";
import { FormControl, IconButton, MenuItem } from "@mui/material";
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import './style.css'
import Admin from "../../components/Admin";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Header from "../../components/header";
import Footer from "../../components/footer";
import "./style.css"
import { getAllNotices } from "../../services/noticesService";
import Loader from "../../components/Loader";
import { formatDate, getStatus, timeAgo } from "../../operationalHelper/formattedDate";
import { useSelector } from "react-redux";
import Select, { SelectChangeEvent } from '@mui/material/Select';

const NotificationList = ()=>{
const history = useHistory();
let date = new Date().toJSON();
console.log(date); // 2022-06-17T11:06:50.369Z
const [list,setList]= React.useState([])
const [loading,setLoading]= React.useState(false);
const [active, setActive] = React.useState(date);
// const history = useHistory()


React.useEffect(()=>{
  setActive('Active')

    getNotifications()
},[])

const getNotifications = ()=>{
    setLoading(true)
    getAllNotices().then((res)=>{
        console.log({res})
        setList(res.data?.response)
        setLoading(false)
    })
}


const handleChange = (event) => {
    console.log(event)
    setActive(event.target.value);
    
  };
const user = useSelector((storeState)=>storeState);
    return(
        <>
         <Header parent='homePage'/>

             <Admin/>

<h2 className="notifications-heading">Notifications</h2>
<div style={{display:'flex',justifyContent:'end',marginBottom:'10px'}}>
<FormControl style={{width:'400px',padding:'0 20px'}}>
<label>Select (Active/Expired/View)</label>
<Select
          labelId="Active"
          id="demo-simple-select"
          value={active}
          defaultValue='Active'
        //   label="Age"
          onChange={handleChange}
          name='active'
          label={'Active'}
        >
          
          <MenuItem value={'Active'}>Active</MenuItem>
          <MenuItem value={'Expired'}>Expired</MenuItem>
          <MenuItem value={'View All'}>View All</MenuItem>
        </Select>
</FormControl>

</div>
        <div style={{display:'flex',justifyContent:'center'}}>

<div style={{display:'',textAlign:'center',width:'60%',padding:'0 30px'}}>
    {
  loading ? <Loader/>  : list.Items?.length ?  active ==='View All'?list?.Items?.map(item=>(
    <>

<div 
 className={`div_hover notification-content-div
  ${!item?.userIDs.includes(user?.userId) ? 'conditional-background':''}
  
  ${getStatus(item?.endDate) === 'Expired'? 'disabled' : ''}
  `}
  onClick={()=>history.push({
  pathname:'/notification-details',
  state: item?.Notice_ID
})}


>
<div id="example1">
<p 
dangerouslySetInnerHTML={{__html:item?.description}}
/>
</div>

<div className="flex-time-status">
<p style={{color:'gray'}}>{timeAgo(item?.createdAt)}</p>
<p style={{color:'gray'}}>Created Date: {formatDate(item?.createdAt)}</p>
<p style={{fontWeight:'700'}}>{getStatus(item?.endDate)}</p>
</div>

</div>
</>
      )) :list?.Items?.filter(obj=>active === 'Expired' ? obj?.endDate < new Date().toJSON():  obj.endDate > new Date().toJSON()).map(item=>(
      <>
 
<div 
   className={`div_hover notification-content-div
    ${!item?.userIDs.includes(user?.userId) ? 'conditional-background':''}
    
    ${getStatus(item?.endDate) === 'Expired'? 'disabled' : ''}
    `}
    onClick={()=>history.push({
    pathname:'/notification-details',
    state: item?.Notice_ID
})}


>
<div id="example1">
  <p 
  dangerouslySetInnerHTML={{__html:item?.description}}
  />
</div>

<div className="flex-time-status">
<p style={{color:'gray'}}>{timeAgo(item?.createdAt)}</p>
<p style={{color:'gray'}}>Created Date: {formatDate(item?.createdAt)}</p>
<p style={{fontWeight:'700'}}>{getStatus(item?.endDate)}</p>
</div>

</div>
</>
        )) :<p>No data found!</p>
    }



</div>
    </div>

<Footer/>

        </>
    )
}

export default NotificationList