
/*
    ====
    This helper functions are designed to assist with basic helper needs and
    also as a support to buggy API response from third party applications.
    ====
*/

// ==== Helper for Buggy search post API by forumbee ====
// export function forumbeeResponseHandler(response){
//     if(response === null || response === undefined){
//         response = {}
//     }
//     let responseArr = Object.keys(response)
//     let responseObj = {
//         data: [],
//         count: 0
//     }
//     if(responseArr.length > 0){        
//         responseObj.data= response.data
//         responseObj.count= responseArr.length 
//     }
//     return responseObj
        
// }


export function forumbeeResponseHandler(response){
    if(response === null || response === undefined){
        response = {}
    }
    
    if(response.data === null || response.data === undefined){
        response.data = []
    }
    return response
        
}


// ==== Old static pattern without Atomicity ====
// export function forumbeeResponseHandler(response){
//     let responseArr = Object.keys(response.data)
//     if(responseArr.length > 0)
//         return response.data.data
//     else
//         return []
// }