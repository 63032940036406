import React from "react";
import NoticesTable from "../../components/NoticesTable";
import { Icon, IconButton } from "@mui/material";
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import ModalDialog from "../../components/modal";
import CreateNotices from "./CreateSupportNotices";
import UpdateNotices from "./UpdateNotices";
import Admin from "../../components/Admin";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { getAllNotices } from "../../services/noticesService";
import Loader from "../../components/Loader";
import {useSelector} from "react-redux"
// import {useHistory} from 'react-router-dom'

function SupportNotices(){
    const [open,setOpen] = React.useState(false)
    const [openUpdate,setOpenUpdate] = React.useState(false);
    const [tableData,setTableData]= React.useState(null);
    const [selectedRow,setSelectedRow]= React.useState(null)
    const [loading,setLoading]= React.useState(null)
    const [search,setSearch]= React.useState('')
    const [originalData,setOriginalData]= React.useState([])
    // const history = useHistory()


    React.useEffect(()=>{

        handleTabelApiCall()
    },[])

    const handleTabelApiCall = ()=>{
        setLoading(true)
        getAllNotices().then((res)=>{
            console.log({res})
            setTableData(res.data?.response?.Items)
            setLoading(false)
        }).catch((err=>setLoading(false)))
    }

    const handleSearch =(word)=>{
        console.log(word)
 let newData = [...tableData]
 setOriginalData(tableData)

 const data = newData.filter((item)=>item?.description.includes(word));
 console.log(data)
 setTableData(data)

    }

   const handleChange = (e)=>{
         setSearch(e.target.value)
   }

   const handleReset=()=>{
    setSearch('')
    setTableData(originalData)
   }

   
    return(
        <> 
        <Header parent='homePage'/>
            <Admin/>
        
        <div className="" style={{padding:"80px 40px"}}>
            
            <h2 className="support-notices-heading">Support Notices</h2>

<div style={{display:"flex",justifyContent:"space-between",alignItems:"center",marginBottom:"30px"}}>
    <div style={{width:"50%"}} >
        <input onChange={handleChange} value={search} placeholder="Search Notices" style={{width:"366px",borderRadius:'8px',padding:'10px',marginRight:'20px'}}/>
        <button onClick={()=>handleSearch(search)} style={{borderRadius:'8px',padding:'10px',width:'100px'}}>Search</button>
        <button onClick={()=>handleReset()} style={{borderRadius:'8px',padding:'10px',width:'100px',marginLeft:'10px'}}>Clear</button>
    </div>
    <div>
        <button style={{borderRadius:'8px',padding:'10px 50px'}} onClick={()=>setOpen(true)}>Create Support Notices</button>

    </div>
</div>
{
    loading ? <Loader/> :

        <NoticesTable setSelectedRow={setSelectedRow} loading ={true} data={tableData} setOpenUpdate={setOpenUpdate} updateTableData={handleTabelApiCall}/>
}
        </div>

                            <ModalDialog
                                open={open}
                                title="Create Support Notices"
                                fullScreen={false}
                                component={<CreateNotices  handleTabelApiCall={handleTabelApiCall} setOpen={setOpen}/>}
                                onClose={()=>setOpen(false)}
                            />
                            <ModalDialog
                                open={openUpdate}
                                title="Update Support Notices"
                                fullScreen={false}
                                component={<UpdateNotices handleTabelApiCall={handleTabelApiCall} setOpen={setOpenUpdate} data={selectedRow}/>}
                                onClose={()=>setOpenUpdate(false)}
                            />

                            <Footer/>
        </>
    )
}

export default SupportNotices