import React from 'react'
import {Redirect} from 'react-router-dom'
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react'

import * as PrimaryServices from '../services/service'
import * as GlobalActions from '../store/actions'

import * as webWidgetHelper from '../serviceHelper/webWidgetHelper'

class LogoutUser extends React.Component{
    
    constructor(props){
        super(props)
        this.state={}
    }

     deleteCookie(name) {
        document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      }
      
      // Call the function and pass the name of the cookie you want to delete
      
      componentDidMount(){
          //   this.deleteCookie("azure_cookie");
          PrimaryServices.logoutAzureAD().then(res=>{
              this.props.logoutUser();
              localStorage.removeItem('signState');
              localStorage.removeItem('userType');
              localStorage.removeItem('Azure_AD');
              localStorage.removeItem('userName');
              sessionStorage.removeItem('msal.interaction.status');
              this.props.history.push('/')
                // window.open(window.location.origin + '/', "_self")

          })



            // PrimaryServices.logoutAgent().then((result) => {
            //     console.log("Stage=01")
            //     // debugger;
            //     console.log("Stage=02")
            //     // debugger;
            //     webWidgetHelper.logoutUser()
            //     // this.props.oktaAuth.signOut('/');
            //     // window.open(window.location.origin + '/', "_self")
            // }).catch((err) => {
            //     console.log(err)
            // })


    }

    AuthenticateUser = (codeParams) => {}



    render () {
        return(
                <div>
                    Loading!!
                </div>
        )
    }
}

const mapStateToProps = state => ({
    globalDetails: state

})

const mapDispatchToProps = dispatch => ({

    updateAgentLogin: (data) => dispatch(GlobalActions.updateAgentLogin(true)),
    loginCustomer: (data) => dispatch(GlobalActions.loginCustomer(data)),
    loginModalInstance: (data) => dispatch(GlobalActions.loginModalInstance(data)),
    logoutUser: (data) => dispatch(GlobalActions.logoutUser(data)),

})

export default connect(
    mapStateToProps,
    mapDispatchToProps
) (withOktaAuth(LogoutUser))

// export default ZendeskAuth;