import React from 'react'
import { Link } from 'react-router-dom'
import ls from 'local-storage'
import { connect } from 'react-redux';
// import JSEncrypt from 'jsencrypt'


import Header from '../components/header'
import Footer from '../components/footer'
import LoginModal from '../components/loginModal'
import SignupModal from '../components/signupModal'

import * as PrimaryServices from '../services/service'
import * as GlobalActions from '../store/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import * as SetWidget from '../operationalHelper/populateWidget'
import * as Const from '../constants/constants'


class Agreement extends React.Component {

    constructor(props) {
        super(props)
        this.state = {

        }
    }

    componentDidMount() {
        document.title = "SLA - Aviatrix"
        SetWidget.populateWidget()
    }



    render() {
        return (
            <React.Fragment>
                <div className="min-height-full">
                    {/* ==== Header for Page ==== */}
                    <Header parent="SLA"></Header>
                    <br />
                    <br />
                    <br className="hide-on-mobile"/>
                    <br className="hide-on-mobile"/>
                    <br className="hide-on-mobile"/> 
                    <div className="container width-wrapper pt-5 p-md-b-5">
                        <div className="row">
                        <h2 className="m-0 p-0 fs-8"><strong>Aviatrix Service Level Agreement</strong></h2>
                        <p className="p-0 fs-3 mb-4">To learn more, <a href="https://aviatrix.com/contact/" target="_blank"><span className="text-danger">contact us</span></a></p>
                            <p className="shadow-p">Support Description</p>
                            <div className="col-sm-4 col-md-6 table-col">
                                <span>General Installation</span>
                                <span>Functional Use</span>
                                <span>Support Hours</span>
                                <span>Email Support</span>
                                <span>Phone Support</span>
                            </div>
                            <div className="col-sm-4 col-md-6 table-col text-center">
                                <span>Yes</span>
                                <span>Yes</span>
                                <span>24x7x365</span>
                                <span>Yes</span>
                                <span>Yes</span>
                            </div>
                        </div>
                        <div className="row">
                            <p className="shadow-p">Initial Incident Response</p>
                            <div className="col-md-6 table-col">
                                <span>Critical - P1</span>
                                <span>Major - P2</span>
                                <span>Minor - P3</span>
                            </div>
                            <div className="col-md-6 table-col text-center">
                                <span>1 Hour(s)</span>
                                <span>4 Hour(s)</span>
                                <span>12 Hour(s)</span>
                            </div>
                            <p className="fs-3  mt-5 text-danger"><a href={Const.ReferenceUrl.howToOpenTicketLink} target="_blank">
                                <strong>How to open a support ticket?</strong></a>
                                </p>
                            <p className="fs-3"><strong>Support:</strong> +1 (888) 311-8328</p>
                            <p className="fs-3 pb-4 text-danger"><a href="service-descriptions" target="_blank">
                                <strong>Service Descriptions</strong></a>
                                </p>
                        </div>
                    </div>

                    {/* ==== Login Modal ==== */}
                    <LoginModal></LoginModal>

                    {/* ==== Sign up Modal ==== */}
                    <SignupModal></SignupModal>

                    {/* ==== Footer Component ==== */}
                    <Footer></Footer>
                    <br className="hide-on-mobile"/> 
                    <br className="hide-on-mobile"/> 
                    <br className="hide-on-mobile"/> 

                </div>
            </React.Fragment >
        )
    }
}

export default Agreement


// export default Home;