export const UPDATE_AGENT_LOGIN = "UPDATE_AGENT_LOGIN"

export const LOGIN_CUSTOMER = "LOGIN_CUSTOMER"

export const LOGIN_AGENT = "LOGIN_AGENT"

export const VERIFY_AGENT_JIRA = "VERIFY_AGENT_JIRA"

export const LOG_OUT_USER = "LOG_OUT_USER"

export const DISABLE_JWT = "DISABLE_JWT"

export const LOGIN_MODAL_INSTANCE = "LOGIN_MODAL_INSTANCE"

export const UPDATE_FILTER = "UPDATE_FILTER"

export const ADD_REDIRECT_LINK = "ADD_REDIRECT_LINK"

export const REMOVE_REDIRECT_LINK = "REMOVE_REDIRECT_LINK"

export const TEST_INIT = "TEST_INIT"