import {
    
    UPDATE_AGENT_LOGIN,
    LOGIN_CUSTOMER,
    VERIFY_AGENT_JIRA,
    LOG_OUT_USER,
    DISABLE_JWT,
    LOGIN_MODAL_INSTANCE,
    TEST_INIT,
    UPDATE_FILTER,
    LOGIN_AGENT,
    ADD_REDIRECT_LINK,
    REMOVE_REDIRECT_LINK

} from './actionTypes'

import * as Config from '../constants/constants'

// ==== Additional Constant Filter objects === 

const defaultFilter = [
    {name: "Knowledge Base", selectorKey: "zendesk", selected: false, counter: "kbCount"},
    {name: "Documentation", selectorKey: "git", selected: false, counter: "docsCount"},
    {name: "Community", selectorKey: "forumbee", selected: false, counter: "communityCount"},
]

const customerFilter = [
    {name: "My Requests", selectorKey: "zendeskRequests", selected: false, counter: "requestCount"}
]

const agentFilter = [
    {name: "Tickets", selectorKey: "zendeskTickets", selected: false, counter: "ticketCount"},
    { name: "Jira", selectorKey: "jiraIssues", selected: false, counter: "jiraCount" }
]

// ==== Initial state object ==== 
const initialState = {
    agentLog: false,
    agentJiraLog: false,
    customerLog: false,
    jwtEnable: false,
    jwtToken: null, 
    userType: null,
    signState: false,
    loginModal: null,
    userName: null,
    userEmail: null,
    userId: null,
    searchFilters: defaultFilter,
    allowDownload:false,
    selectedFilter: [],
    selectedFilterNames: [],
    authLevel: null, // ==== enum : okta | zendeek ====

    // displayUserCategory: "agent",
    // displayUserCategory: "customer",
    displayUserCategory: Config.userType,

    redirectLink: null,
    testInit: 175
}


const GlobalDetails = (state=initialState, action) => {

    switch(action.type){
        case UPDATE_AGENT_LOGIN:
            return {
                ...state,
                agentLog: action.payload
            }
            
            case LOGIN_CUSTOMER:
            console.log(action.payload, 'payload test')
            return{
                ...state,
                // customerLog: action.payload.customerLog,
                jwtEnable: action.payload.jwtEnable,
                // jwtToken: action.payload.jwtToken,
                userType: action.payload.userType,
                signState: action.payload.signState,
                userName: action.payload.userName,
                userEmail: action.payload.userEmail,
                userId: action.payload.userId,
                allowDownload:action.payload.allowDownload,
                authLevel: "zendesk",
                searchFilters: [ ...defaultFilter, ...customerFilter],
                role:action.payload?.role
            }

        case LOGIN_AGENT:
            return{
                ...state,
                signState: action.payload.signState,
                userType: action.payload.userType,
                // authLevel: "okta",
                userName:action.payload?.userName,
                authLevel: "azureAD",
                searchFilters: [ ...defaultFilter, ...agentFilter]
            }

        case VERIFY_AGENT_JIRA:
            return{
                ...state,
                agentLog: action.payload,
                searchFilters: [ ...defaultFilter, ...agentFilter]
            }

        case LOG_OUT_USER:
            return {
                ...state,
                agentLog: false,
                agentJiraLog: false,
                customerLog: false,
                jwtEnable: false,
                jwtToken: null, 
                userType: null,
                signState: false,
                userName: null,
                userEmail: null,
                userId: null,
                allowDownload:false,
                searchFilters :defaultFilter,
                selectedFilter: [],
                selectedFilterNames: [],
                redirectLink: null,
                authLevel: null,
            }            
        
        case DISABLE_JWT:
            return {
                ...state,
                jwtEnable: false,
                jwtToken: null
            }

        case LOGIN_MODAL_INSTANCE:
            return {
                ...state,
                loginModal: action.payload
            }

        case UPDATE_FILTER:
            return {
                ...state,
                selectedFilter: action.payload.keys,
                selectedFilterNames: action.payload.names
            }

        case ADD_REDIRECT_LINK:
            console.log("#### LINK ADDED ####")
            return{
                ...state,
                redirectLink: action.payload.link
            }

        case REMOVE_REDIRECT_LINK:
            return {
                ...state,
                redirectLink: null
            }

        case TEST_INIT:
            // alert("This is from Case!!")
            return{
                ...state,
                testInit: Math.random()
            }

        default: 
            return {...state}
    }
}

export default GlobalDetails;