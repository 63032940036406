import { store } from '../store/store'

export function populateWidget() {
    // return

    let state = store.getState()
    if(state.displayUserCategory === "customer"){
        return
    }
    let userDetails = {
        name: state.userName,
        email: state.userEmail
    }
    if(userDetails.name === null || userDetails.email === null){
        return
    }

    // window.zE('webWidget', 'prefill', {
    //     name: {
    //         value: userDetails.name,
    //         readOnly: true // optional
    //     },
    //     email: {
    //         value: userDetails.email,
    //         readOnly: true // optional
    //     }
    //     });
}