import React from 'react'
import { Link } from 'react-router-dom'
import ls from 'local-storage'
import { connect } from 'react-redux';
// import JSEncrypt from 'jsencrypt'


import Header from '../components/header'
import Footer from '../components/footer'
import LoginModal from '../components/loginModal'
import SignupModal from '../components/signupModal'

import * as PrimaryServices from '../services/service'
import * as GlobalActions from '../store/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import * as SetWidget from '../operationalHelper/populateWidget'
import * as Const from '../constants/constants'


class Tou extends React.Component {

    constructor(props) {
        super(props)
        this.state = {

        }
    }

    componentDidMount() {
        document.title = "Service Descriptions - Aviatrix"
        SetWidget.populateWidget()
    }



    render() {
        return (
            <React.Fragment>
                <div className="min-height-full">
                    {/* ==== Header for Page ==== */}
                    <Header parent="SLA"></Header>
                    <br />
                    <br />
                    <br className="hide-on-mobile"/>
                    <br className="hide-on-mobile"/>
                    <br className="hide-on-mobile"/> 
                    <div className="container width-wrapper pt-5 p-md-b-6 pb-5">
                        <div className="row">
                        {/* <p className="m-0 pb-4 p-0 fs-8 text-danger text-center">
                                <strong>Supplemental Terms of Use - Aviatrix
                                    </strong>
                        </p> */}
                        {/* <p className="m-0 pt-4 p-0 fs-2 ">
                        This document describes the policies and timeframes related to how Aviatrix determines software End of Engineering (EOE) and End of Life (EOL) milestones. This policy is provided to assist Aviatrix customers and partners in planning and successfully updating to the latest software.      
                        </p> */}
                        <p className="m-0 pt-2 p-0 fs-4 ">
                                <strong>
                                    Aviatrix's Service Descriptions are covered in the following documents.
                                </strong>
                      
                        </p>
                        
                        <p  className="m-0 text-danger pt-4 p-0 fs-4 ">
                        <a style={{borderBottom:'1px solid' }} href={Const.ReferenceUrl.softwareSupportServices} target="_blank">
                                <strong>Software Support Services</strong></a>

                        </p>
                        <p  className="m-0 text-danger pt-4 p-0 fs-4 ">
                        <a style={{borderBottom:'1px solid' }} href={Const.ReferenceUrl.premiumSupportServices} target="_blank">
                                <strong>Premium Support Services</strong></a>

                        </p>
                        <p className="m-0 text-danger pt-4 p-0 fs-4 ">
                        
                        <a style={{borderBottom:'1px solid' }} href={Const.ReferenceUrl.configurationServices} target="_blank">
                                <strong  >Configuration Services</strong></a>
                        </p>
                        <p className="m-0 text-danger pt-4 p-0 fs-4 ">
                        
                        <a style={{borderBottom:'1px solid' }} href={Const.ReferenceUrl.embeddedEngineer} target="_blank">
                                <strong>Embedded Engineer</strong></a>
                        </p>

                        <p className="m-0 text-danger pt-4 p-0 fs-4 ">
                        
                        <a style={{borderBottom:'1px solid' }} href={Const.ReferenceUrl.managedServices} target="_blank">
                                <strong>Managed Services</strong></a>                           
                        </p>
                      
                        <p className="m-0 text-danger pt-4 p-0 fs-4 ">
                        
                        <a style={{borderBottom:'1px solid' }} href={Const.ReferenceUrl.cloudNDeviceSupport} target="_blank">
                                <strong>CloudN Device Support</strong></a>                           
                        </p>
                      
                        
                        </div>
                 
                    </div>

                    {/* ==== Login Modal ==== */}
                    <LoginModal></LoginModal>

                    {/* ==== Sign up Modal ==== */}
                    <SignupModal></SignupModal>

                    {/* ==== Footer Component ==== */}
                    <Footer></Footer>
                    <br className="hide-on-mobile"/> 
                    <br className="hide-on-mobile"/> 
                    <br className="hide-on-mobile"/> 

                </div>
            </React.Fragment >
        )
    }
}

export default Tou


// export default Home;