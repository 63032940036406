import React from 'react';
import Slider from "react-slick";

var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: 'single-slide',
    arrows: false,
    adaptiveHeight: true,
    variableWidth: true
};

class LoginExplanatoryScreen extends React.Component {

    constructor(props) {
        super(props)
        this.state = {

        }
        this.modalValue = null
    }

    componentDidMount() {
        this.modalValue = new window.bootstrap.Modal(document.getElementById("login_coachmark"))
    }

    openCoachmarkModal = () => {
        this.modalValue.toggle()
    }

    continueLoginOperation = () => {
        // this.modalValue.toggle()
        this.props.continueLogin()
    }

    render() {
        return (
            <React.Fragment>
                <div className="modal fade" id="login_coachmark" tabIndex={-1} aria-labelledby="loginCoachmark" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content coachmark-wrapper">
                            <div className="half-col-1">
                                <Slider {...settings}>
                                    <div>
                                        <img src="../img/login_jira.png"></img>
                                    </div>
                                    <div>
                                        <img src="../img/accepting_screen.png"></img>
                                    </div>
                                </Slider>
                            </div>
                            <div className="half-col-2">
                                <div>
                                    <h3 className="fw-800">Data Access</h3>
                                    <p className="m-0">Allow Aviatrix to manage your account data</p>
                                </div>
                                <button className=" btn fs-2 btn-outline-white mx-1 wid-100 coachmarkCTA" onClick={this.continueLoginOperation} data-bs-dismiss="modal"> <span>Next</span> </button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}


export default LoginExplanatoryScreen;