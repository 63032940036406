import React from 'react'
import { Link } from 'react-router-dom'
import ls from 'local-storage'
import { connect } from 'react-redux';
// import JSEncrypt from 'jsencrypt'


import Header from '../components/header'
import Footer from '../components/footer'
import LoginModal from '../components/loginModal'
import SignupModal from '../components/signupModal'

import * as PrimaryServices from '../services/service'
import * as GlobalActions from '../store/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import * as SetWidget from '../operationalHelper/populateWidget'
import * as Const from '../constants/constants'


class NoPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {

        }
    }

    componentDidMount() {
        document.title = "No Page"
        SetWidget.populateWidget()
    }



    render() {
        return (
            <React.Fragment>
                <div className="min-height-full">
                    {/* ==== Header for Page ==== */}
                    <Header parent="SLA"></Header>
                    <br />
                    <br />
                    <br className="hide-on-mobile"/>
                    <br className="hide-on-mobile"/>
                    <br className="hide-on-mobile"/> 
                    <div className="container width-wrapper pt-5 p-md-b-6 pb-5">
                        <div className="row">
                        <div className='col-md-3'></div>
                        <p className="m-0 col-md-6 pt-2 p-0 fs-4 text-center">
                                <strong>
                                    The page is not available anymore.
                                </strong>
                      
                        </p>
                        </div>
                        <div className="row">
                        <div className='col-md-3'></div>
                        <p  className="m-0 col-md-6  md-2 p-0 fs-4 text-center">
                                <strong>You may contact </strong>  <strong  className='text-danger'>webmaster@aviatrix.com</strong>

                        </p>
                        </div>
                        
                       
                      
                        
                        
                 
                    </div>

                    {/* ==== Login Modal ==== */}
                    <LoginModal></LoginModal>

                    {/* ==== Sign up Modal ==== */}
                    <SignupModal></SignupModal>

                    {/* ==== Footer Component ==== */}
                    <Footer></Footer>
                    <br className="hide-on-mobile"/> 
                    <br className="hide-on-mobile"/> 
                    <br className="hide-on-mobile"/> 

                </div>
            </React.Fragment >
        )
    }
}

export default NoPage


// export default Home;