// ==== Libraries used ====
import { withOktaAuth } from '@okta/okta-react'
import React from 'react'
import ls from 'local-storage'
import Cookie from 'js-cookie'
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'

import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import PropagateLoader from "react-spinners/PropagateLoader";
import BarLoader from "react-spinners/BarLoader";
import LoginExplanatoryScreen from './loginExplanatoryScreen'
import OktaSignInWidget from './oktaSignInWidget'

// ==== Local Modules Used ====
import * as PrimaryService from '../services/service'
import * as GlobalActions from '../store/actions'

import * as Const from '../constants/constants'
import ZendeskLoginHelper from '../serviceHelper/zendeskLoginHelper'
import * as SetWidget from '../operationalHelper/populateWidget'
import AzureADLogin from './azureADLogin';

class LoginModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            user: '',
            pass: '',
            loginProgress: false,
            oktaLogSuccess: false,
            toolTipVisible: false,

            userBase: "",
            // userBase: "customer",

            validationObj: {
                email: false,
                pass: false,                
            },

            invalidPassword: false,
        }
        this.coachMarkRef = React.createRef()
        this.override = css`
        display: block;
        margin: 0px auto;
        top: 5px;
        border-color: red;
        `;
        this.onSuccess = this.onSuccess.bind(this);
        this.onError = this.onError.bind(this);
    }

    componentDidMount() {
        this.setState({
            userBase: this.props.globalDetails.displayUserCategory
        }, () => {
            let modal = new window.bootstrap.Modal(document.getElementById("login"))
            this.props.loginModalInstance(modal)
        })
    }

    // ==== Okta Test login section ====
    initOktaSignIn = () => {

        this.setState({
            loginProgress: true
        }, () => {
            console.log(" ###### STARTING OKTA SIGN IN PROCESS ######")
            this.props.oktaAuth.signInWithCredentials({
                username: this.state.user,
                password: this.state.pass,
                loginProcessOpen: false
            }).then((response) => {
                this.onSuccess(response)
                console.log("Debugger from initOkta!")
            }).catch((err) => {
                console.log("This is coming from Catch!!")
                this.setState({
                    invalidPassword: true,
                    loginProgress: false,
                    validationObj: {
                        email: true, 
                        pass: true
                    }
                })
                this.onError(err)
            })
        })

    }


    async onSuccess(res) {
        // console.log("Starting OnSuccess")
        console.log(res?.account,'... success')
        // if (res.status === 'SUCCESS') {
        if (res?.accessToken) {
            //   ls.set('username', this.state.user)
            //   ls.set('password', this.state.pass)      
            ls.set('signState', true)
            ls.set('userType', 'agent')
            ls.set('userName',res?.account?.name)
            // console.log(res)
            // console.log(res.data)
            // console.log(this.props.authState)
            // debugger;
            // await this.authenticateUserProfile("okta", "agent", false)
            let data = {
                signState: true,
                userType: "agent",
                userName:res?.account?.name,
            }
            // this.setState({
            //     loginProcessOpen: false,
            //     loginProgress: false,
            // })
            this.props.globalDetails.loginModal.toggle()
            // window.document.getElementsByClassName('').remove()
            const element = document.getElementsByClassName("modal-backdrop fade show")[0]?.remove()
            console.log(element,'element')
            // element.remove()
            // const modalRemover = document.getElementsByClassName("modal fade show")[0]
            // console.log(element,'element')
            // modalRemover.remove()
            // this.hideModal()
            this.props.loginAgent({...data,userName:res?.account?.name})
            // console.log('session token',res.session.token)
            // debugger;
            // return this.props.oktaAuth.signInWithRedirect({    // this is return
            //     sessionToken: res.session.token    // ==== session setting for Sign-in widget ====
            //     // sessionToken: res.data.sessionToken   // ==== session setting for Custom self designed Sign-in ====
            // });
        }
    }

    onError(err) {
        // this.setState({
        //   errorLog: true
        // })
        console.log('error logging in', err);
    }

    // ==== Zendesk Login Test Section ====

    initZendeskAuth = () => {
        // this.hideModal()
        // return
        this.setState({
            loginProgress: true
        }, () => {
            this.authenticateUserProfile()
        })

    }

    authenticateUserProfile = async (loginType = "zendesk", userType = "customer", customerLog = true) => {
        console.log("zendesk profile auth")
        let userDetails = {
            user: this.state.user,
            pass: this.state.pass,
            username: this.state.user,
            loginType: loginType
        }
        return PrimaryService.authZendeskUser(userDetails).then((result) => {
            console.log(result,"result")
            let newUserDetails = {
                customerLog: customerLog,
                jwtEnable: true,
                jwtToken: result.data.userInfo.JWTLogin,
                userType: userType,
                signState: true,
                userName: result.data.userInfo.users[0].name,
                userEmail: result.data.userInfo.users[0].email,
                userId: result.data.userInfo.users[0].id
            }
            this.props.loginCustomer(newUserDetails)            
            SetWidget.populateWidget()
            this.setState({
                customLog: true,
                loginProgress: false
            }, () => {
                console.log("Printing from Zendesk success!")
                console.log(this.props.authState)
                if (this.props.globalDetails.loginModal !== null) {
                    this.props.globalDetails.loginModal.toggle()
                }
            })
        }).catch((err) => {
            console.log(err)
            this.onError(err)
        })
    }


    // ==== Handlers ====

    hideModal = () => {
        let modal = new window.bootstrap.Modal(document.getElementById("login"))
        modal.toggle()
    }

    inputHander = (e) => {
        let {validationObj} = this.state
        if (e.target.name === "username") {
            validationObj.email = false
            this.setState({
                user: e.target.value,
                validationObj: validationObj
            })
        }
        else if (e.target.name === "password") {
            validationObj.pass = false
            this.setState({
                pass: e.target.value,
                validationObj: validationObj
            })
        }
    }

    initiateLogin = () => {
        let {user, pass, validationObj, userBase} = this.state
        let validationFail = false
        if(user.trim() === ""){
            validationObj.email = true
            validationFail = true
        }

        if(pass.trim() === ""){
            validationObj.pass = true
            validationFail = true
        }

        this.setState({
            validationObj: validationObj
        }, ()=>{
            if(validationFail) { return }

            if(userBase === "agent"){
                this.initOktaSignIn()
            }else{
                // this.initZendeskAuth()

            }
        })
    }

    jumpToZendeskLogin = () => {
        // https://{subdomain}.zendesk.com/oauth/authorizations/new?response_type=code&redirect_uri={your_redirect_url}&client_id={your_unique_identifier}&scope=read%20write
        let subdomain = Const.zendeskSubDomain
        // let redirectUrl = "http://localhost:3000/zendeskAuth"
        let redirectUrl = window.location.origin + "/zendeskAuth"
        let clientId = Const.zendeskClientId
        let scope = "read"
        window.location.href = `https://${subdomain}.zendesk.com/oauth/authorizations/new?response_type=code&redirect_uri=${redirectUrl}&client_id=${clientId}&scope=${scope}`
    }

    changeToSignUpModal = () => {
        let modal = new window.bootstrap.Modal(document.getElementById("signup"));
        modal.toggle(true);
    }

    render() {
        let { user, pass, loginProgress, oktaLogSuccess, validationObj, userBase, invalidPassword } = this.state

        let signState = this.props.globalDetails.signState //ls.get("signState")
        let agentLogSuccess = this.props.globalDetails.agentLog //ls.get("agentLogSuccess")
        console.log("Timely Auth")
        console.log(this.props.authState)


        return (
            <React.Fragment>
                {
                    userBase !== "customer" ?
                        // (this.props.authState.isAuthencticated || signState === true) && !agentLogSuccess ? //&& oktaLogSuccess ? 
                        this.props.authState.isAuthenticated && signState === true && !agentLogSuccess ? // && signState === true
                            // <Redirect to="/jiraAuth" />                            
                            <Redirect to="/zendeskInitAuth" />
                            :
                            <div className="modal fade " id="login" tabIndex={-1} aria-labelledby="loginLabel" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered">

                                    <div className="modal-content coachmark-wrapper">
                                        <div className="half-col-1 single-on-mobile">
                                            {
                                                loginProgress ?
                                                    <center><BarLoader css={this.override} width={"30%"} color={"#e24402"} loading={true} /></center>
                                                    :
                                                    ''
                                            }
                                            {/* <div className="text-end modal-x-wrapper">
                                                <button type="button" className="btn btn-close px-4 modal-close-btn-login" data-bs-dismiss="modal" aria-label="Close"> <img src="img/close.png" /> </button>
                                            </div> */}


                                            {/* ==== Okta Sign in method added here ==== */}
                                            {/* <div>
                                                <OktaSignInWidget
                                                    baseUrl="https://aviatrix.okta.com"
                                                    onSuccess={this.onSuccess}
                                                    onError={this.onError}
                                                ></OktaSignInWidget>
                                            </div> */}

                                            {/* <div className="modal-body px-4"> <br /> */}

                                                {/* ==== Okta Sign in method added here ==== */}
                                                <div>
                                                    <AzureADLogin onSuccess={this.onSuccess} onError={this.onError}/>
                                                    {/* <button >Azure AD Authentication</button> */}
                                                    {/* <OktaSignInWidget
                                                        // baseUrl="https://dev-6271244.okta.com"
                                                        baseUrl="https://aviatrix.okta.com"
                                                        onSuccess={this.onSuccess}
                                                        onError={this.onError}
                                                    ></OktaSignInWidget> */}
                                                </div>

                                                <br />
                                            {/* </div> */}


                                        </div>
                                        <div className="half-col-2 text-start hide-on-mobile">
                                            <div>
                                                <h5 className="fw-800"><center>This Support Portal is for Aviatrix<br></br>internal use only.</center></h5>
                                                <h5 className="fw-800"><center>Please use your AzureAD credentials to login</center></h5><br></br><br></br>
                                                <h3 className="fw-800"><center>Why should I sign in?</center></h3><br></br>
                                                <center><p className="m-0">Once you Sign In, you'll be able to:</p></center><br></br>
                                                <ul>
                                                    <li><p>Search across Zendesk Knowledge Base.</p></li>
                                                    <li><p>Search for user tickets on Zendesk.</p></li>
                                                    <li><p>Search across defects on Jira.</p></li>
                                                </ul>
                                                {/* <p className="m-0">Allow Aviatrix to manage your account data</p> */}
                                                {/* <p className="m-0">Allow Aviatrix to manage your account data</p>
                                                <p className="m-0">Allow Aviatrix to manage your account data</p> */}
                                            </div>
                                        </div>

                                        <div className="text-end modal-x-wrapper">
                                            <button type="button" className="btn btn-close px-4 modal-close-btn-signup" data-bs-dismiss="modal" aria-label="Close"> <img src="img/close.png" /> </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        :
                        <div className="modal fade " id="login" tabIndex={-1} aria-labelledby="loginLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered">

                                <div className="modal-content coachmark-wrapper">
                                    <div className="half-col-1 single-on-mobile">
                                        {
                                            loginProgress ?
                                                <center><BarLoader css={this.override} width={"30%"} color={"#e24402"} loading={true} /></center>
                                                :
                                                ''
                                        }
                                        {/* <div className="text-end modal-x-wrapper">
                                            <button type="button" className="btn btn-close px-4 modal-close-btn-login" data-bs-dismiss="modal" aria-label="Close"> <img src="img/close.png" /> </button>
                                        </div> */}
                                        <div className="modal-body px-4"> <br />
                                            <div className=" d-flex justify-content-between px-1 pb-3">
                                                <h1 className="fs-3">Login</h1>
                                                <a>Need an account? <span className="color-n cursor-p" onClick={this.changeToSignUpModal} data-bs-dismiss="modal" >Sign Up</span></a> </div>
                                            <div className="mb-3">
                                                <input name="username" value={user} placeholder="Email" type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={this.inputHander} />
                                            </div>
                                            <div className="mb-3">
                                                <input name="password" value={pass} placeholder="Password" type="password" className="form-control" id="exampleInputPassword1" onChange={this.inputHander} />
                                            </div>
                                            <div className="row fs-2 py-3">
                                                <div className="col-md-6 text-start">
                                                    <button id="login-button" className="btn btn-outline-danger" name="button" onClick={ZendeskLoginHelper}>Login</button>
                                                </div>
                                                <div className="col-md-6 text-end">
                                                </div>
                                            </div>
                                            <br />
                                        </div>
                                    </div>
                                    <div className="half-col-2 text-start hide-on-mobile">
                                        <div>
                                            <h3 className="fw-800"><center>Why should I Sign UP?</center></h3><br></br>
                                            <center><p className="m-0">Once you Sign Un, you'll be able to:</p></center><br></br>
                                            <ul>
                                                <li><p>Search across private articles.</p></li>
                                                <li><p>Raise a tickets on Zendesk.</p></li>
                                                <li><p>Search for your tickets on Zendesk.</p></li>
                                            </ul>
                                            {/* <p className="m-0">Allow Aviatrix to manage your account data</p>
                                            <p className="m-0">Allow Aviatrix to manage your account data</p> */}
                                        </div>
                                    </div>

                                    <div className="text-end modal-x-wrapper">
                                        <button type="button" className="btn btn-close px-4 modal-close-btn-signup" data-bs-dismiss="modal" aria-label="Close"> <img src="img/close.png" /> </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                }

                {/* ==== Login Explanatory Modal ====  */}
                <LoginExplanatoryScreen ref={this.coachMarkRef} continueLogin={this.initiateLogin}></LoginExplanatoryScreen>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    globalDetails: state

})

const mapDispatchToProps = dispatch => ({

    updateAgentLogin: (data) => dispatch(GlobalActions.updateAgentLogin(true)),
    loginCustomer: (data) => dispatch(GlobalActions.loginCustomer(data)),
    loginModalInstance: (data) => dispatch(GlobalActions.loginModalInstance(data)),

    loginAgent: (data) => dispatch(GlobalActions.loginAgent(data)),

})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withOktaAuth(LoginModal))

// export default withOktaAuth(LoginModal);