

function Footer() {
    return (
        // ==== Footer component ====
        <div className="footer-section">
            <div className={`dpk-container fs-1 credits avn`}>
                <div className="row text-center py-2">
                    {/* <div className="col-md-3 col-6 text-start"> <img className="m-2" id="logoImg2" src="./img/footer.png" height={45} /></div> */}
                    <div className="col-md-3 col-6 text-start"> 
                        <a href="https://www.facebook.com/AviatrixSystems/" target="_blank"><img className="px-2 " height={18} src="./img/fb.png" /></a> 
                        <a href="https://twitter.com/aviatrixsys" target="_blank"><img className="px-2" height={18} src="./img/tw.png" /></a> 
                        <a href="https://www.linkedin.com/company/aviatrix-systems/" target="_blank"><img className="px-2" height={18} src="./img/ln.png" /></a></div>
                    <div className="col-md-9 col-12 fs-2 fs-sm-1 footer-menu lg-txt-r text-start"> 
                    <a href="https://aviatrix.com/terms-of-use/" target="_blank" className="pe-4">Term of Use</a> 
                    <a href="https://aviatrix.com/export-and-contract-compliance/" target="_blank" className="pe-4">Export Compliance</a> 
                    <a href="https://support.aviatrix.com/Aviatrix-EOL-Policy" target="_blank" className="pe-4">End of Life Policy</a>
                    <a href="https://aviatrix.com/aviatrix-end-of-sale-notice/" target="_blank" className="pe-4">End of Sale Policy</a>
                    <a href="https://aviatrix.com/cookie-policy/#dnsmpi" target="_blank" className="pe-4">Do Not Sell My Information</a>
                    <a href="https://aviatrix.com/privacy-policy/" target="_blank" className="pe-4">Privacy</a>
                    </div>
                    
                </div>
            </div>
        </div>

    )
}

export default Footer;