
function selectChip(filterObj) {
    let filterList = Object.keys(filterObj)

    let activeChip = filterList.find(element => filterObj[element].count > 0)
    if(activeChip === undefined){
        return filterList[0]
    }
    return activeChip
}

export default selectChip;