// ==== Libraries used ====
import React from 'react'
import ls from 'local-storage'
import Cookies from 'js-cookie'
import { connect } from 'react-redux';
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import PropagateLoader from "react-spinners/PropagateLoader";
import PulseLoader from "react-spinners/PulseLoader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { faLongArrowAltLeft, faLongArrowAltRight, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import Chip from '@material-ui/core/Chip'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Label, Input, Pagination } from 'reactstrap';
import ReactParser from 'html-react-parser'

import ReactPaginate from 'react-paginate'

// ==== Local Modules used ====
import Footer from '../components/footer'
import Header from '../components/header'
import SignupModal from '../components/signupModal'
import LoginModal from '../components/loginModal'
import * as ForumbeeServiceHelper from '../serviceHelper/forumbeeServiceHelper'
import FilterResponseHelper from '../serviceHelper/filteredResponseHelper'

import * as PrimaryServices from '../services/service'
import * as Const from '../constants/constants'
import Truncate from '../operationalHelper/stringTruncate'
import SetChip from '../operationalHelper/searchResultChipSelector'

import * as GlobalActions from '../store/actions'
import PaginationBar from '../components/paginationBar'
import ZendeskLoginHelper from '../serviceHelper/zendeskLoginHelper'
import * as SetWidget from '../operationalHelper/populateWidget'

// ==== assets ====
// import emptyIllustration from './img/empty_illustration_search.png'

//Material UI Theme for Chips
const ChipTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#FF6307',
        }
    },
});


class Search extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            searchValue: '',
            gitArticles: [],
            zendeskArticles: [],
            zendeskTickets: [],
            zendeskRequests: [],
            forumbeeArr: [],
            issuesArr: [],
            totalResults: 0,
            resultCount: {
                kbCount: 0,
                communityCount: 0,
                docsCount: 0,
                ticketCount: 0,
                requestCount: 0,
                jiraCount: 0
            },

            isOpen: false,
            appliedFilterList: [],
            enabledChip: null,
            enabledFilterList: [],
            appliedFilterNameList: [],

            pageinationLoader: false,

            pageData:{},

            emptyFilterList: []
        }
        this.override = css`
            display: block;
            margin: 0 auto;
            border-color: red;
            `;

        this.dropdownRef = React.createRef();
    }

    componentDidMount() {

        // console.log("####### PRINTING FROM REDUX STORE!! ########")
        // console.log(this.props.globalDetails.testInit)
        // debugger;

        console.log("Printing global values")
        console.log(this.props.globalDetails.selectedFilter)
        console.log(this.props.globalDetails.selectedFilterNames)
        // debugger;
        let preSearchValue = ls.get("searchParam")
        if (preSearchValue !== null && preSearchValue !== undefined) {
            // call search API 
            this.setState({
                searchValue: preSearchValue,
                appliedFilterList: this.props.globalDetails.selectedFilter,
                appliedFilterNameList: this.props.globalDetails.selectedFilterNames
            }, () => {
                this.searchHandler()
                ls.remove("searchParam")
            })
        }
        SetWidget.populateWidget()

    }

    // ==== Input handler ====
    inputHandler = (e) => {
        if (e.target.name === "search") {
            this.setState({ searchValue: e.target.value })
        }
    }

    addToFiltersList = (val) => {
        let { appliedFilterList, appliedFilterNameList } = this.state
        if (appliedFilterList.indexOf(val.selectorKey) == -1) {
            appliedFilterList = [...appliedFilterList, val.selectorKey]
            appliedFilterNameList = [...appliedFilterNameList, val.name]
            // this.setState({ appliedFilterList: [...this.state.appliedFilterList, val], dropdownKey: Math.floor((Math.random() * 100) + 1) })
        } else {
            let newList = appliedFilterList.filter((filter) => { return filter !== val.selectorKey });
            let newNameList = appliedFilterNameList.filter((filter) => { return filter !== val.name });
            appliedFilterList = newList
            appliedFilterNameList = newNameList
            // this.setState({ appliedFilterList: newList, dropdownKey: Math.floor((Math.random() * 100) + 1) });
        }

        this.setState({ appliedFilterList: appliedFilterList, appliedFilterNameList: appliedFilterNameList, dropdownKey: Math.floor((Math.random() * 100) + 1) }, () => {
            this.props.updateFilter({
                keys: this.state.appliedFilterList,
                names: this.state.appliedFilterNameList
            })
        });
    }

    // toggleChips = (filterName) => {
    //     let enabledList = []
    //     let {enabledChip} = this.state
    //     if (this.state.enabledFilterList.indexOf(filterName) == -1) {
    //         enabledList = [...this.state.enabledFilterList, filterName];
    //     } else {
    //         enabledList = this.state.enabledFilterList.filter((filter) => {
    //             return filter !== filterName
    //         })
    //     }
    //     this.setState({ enabledFilterList: enabledList });
    // }
    

    toggleChips = (filterName) => {
        let enabledList = []
        let {enabledChip} = this.state
        if(enabledChip === filterName){
            return
        }

        this.setState({
            enabledChip: filterName
        })
    }


    toggleDropdown = (e) => {
        this.setState({ isOpen: !this.state.isOpen });
    }

    initSearch = (e) => {
        if(e.keyCode === 13){
            this.searchHandler()
        }
    }

    // ==== API calling for search ====
    searchHandler = () => {
        let { searchValue, appliedFilterList, enabledChip, pageData } = this.state
        let tempPageData = {}
        let searchParam = {
            searchValue: searchValue
        }

        if(searchValue.trim() === ""){
            return
        }

        if (this.state.appliedFilterList.length !== 0){
            enabledChip = this.state.appliedFilterNameList[0]
            this.state.appliedFilterNameList.map((value)=>{
                tempPageData[value] = {page: 1, count: 0}
            })
        }else{
            enabledChip = this.props.globalDetails.searchFilters[0].name
            this.props.globalDetails.searchFilters.map((value)=>{
                tempPageData[value.name] = {page: 1, count: 0}
            })
        }

        this.setState({
            gitArticles: [],
            zendeskArticles: [],
            zendeskTickets: [],
            forumbeeArr: [],
            issuesArr: [],
            totalResults: 0,
            searchInProgress: true,
            enabledChip: enabledChip,
            // pageData: pageData
        })

        let userType = this.props.globalDetails.userType //ls.get('userType')
        console.log("###### PRINTING USER #######", userType)
        // debugger;

        let dataBody = {
            filter: appliedFilterList
        }


        if (userType === null || userType === undefined) {
            PrimaryServices.searchAnon(searchParam, dataBody).then((result) => {
                console.log(result)
                let detailedResults = result.data
                let forumbeeResponse = ForumbeeServiceHelper.forumbeeResponseHandler(detailedResults.forumbee)
                let stateObj = {}

                if (appliedFilterList.length > 0) {
                    stateObj = FilterResponseHelper(result, appliedFilterList, tempPageData)
                } else {
                    tempPageData["Knowledge Base"].count = detailedResults.zendesk.count
                    tempPageData["Documentation"].count = detailedResults.git.count
                    tempPageData["Community"].count = forumbeeResponse.paging.count
                    stateObj = {
                        zendeskArticles: detailedResults.zendesk.results,
                        gitArticles: detailedResults.git.results,
                        forumbeeArr: forumbeeResponse.data,
                        totalResults: detailedResults.zendesk.count + detailedResults.git.count + forumbeeResponse.paging.count,
                        enabledChip: SetChip(tempPageData),
                        resultCount: {
                            kbCount: detailedResults.zendesk.count,
                            communityCount:forumbeeResponse.paging.count,
                            docsCount: detailedResults.git.count,
                        },

                        searchInProgress: false,

                        pageData: tempPageData // ==== allocate ====
                    }
                }

                this.setState(stateObj)

            }).catch((err) => {
                console.log(err)
            })
        }

        else if (userType === "customer") {
            PrimaryServices.searchCustomer(searchParam, dataBody).then((result) => {
                let detailedResults = result.data
                let forumbeeResponse = ForumbeeServiceHelper.forumbeeResponseHandler(detailedResults.forumbee)
                let stateObj = {}

                if (appliedFilterList.length > 0) {
                    stateObj = FilterResponseHelper(result, appliedFilterList, tempPageData)
                } else {

                    tempPageData["Knowledge Base"].count = detailedResults.zendesk.count
                    tempPageData["Documentation"].count = detailedResults.git.count
                    tempPageData["Community"].count = forumbeeResponse.paging.count
                    tempPageData["My Requests"].count = detailedResults.zendeskRequests.count


                    stateObj = {
                        zendeskArticles: detailedResults.zendesk.results,
                        gitArticles: detailedResults.git.results,
                        forumbeeArr: forumbeeResponse.data,
                        zendeskRequests: detailedResults.zendeskRequests.requests,
                        totalResults: detailedResults.zendesk.count + detailedResults.git.count + forumbeeResponse.paging.count + detailedResults.zendeskRequests.count,
                        enabledChip: SetChip(tempPageData),
                        resultCount: {
                            kbCount: detailedResults.zendesk.count,
                            communityCount:forumbeeResponse.paging.count,
                            docsCount: detailedResults.git.count,
                            requestCount: detailedResults.zendeskRequests.count,
                        },
                        searchInProgress: false,

                        pageData: tempPageData
                    }
                }
                this.setState(stateObj, () => {
                    console.log(" ###### CALL ENDED ######")
                    console.log(this.state.zendeskArticles.length)
                })
            }).catch((err) => {
                console.log(err)
            })
        }

        else if (userType === "agent") {
            PrimaryServices.searchAgent(searchParam, dataBody).then((result) => {
                let detailedResults = result.data
                let forumbeeResponse = ForumbeeServiceHelper.forumbeeResponseHandler(detailedResults.forumbee)
                let stateObj = {}

                if (appliedFilterList.length > 0) {
                    stateObj = FilterResponseHelper(result, appliedFilterList, tempPageData)
                } else {

                    tempPageData["Knowledge Base"].count = detailedResults.zendesk.count
                    tempPageData["Documentation"].count = detailedResults.git.count
                    tempPageData["Community"].count = forumbeeResponse.paging.count
                    tempPageData["Tickets"].count = detailedResults.zendeskTickets.count
                    tempPageData["Jira"].count = detailedResults.jiraIssues.total             


                    stateObj = {
                        zendeskArticles: detailedResults.zendesk.results,
                        gitArticles: detailedResults.git.results,
                        forumbeeArr: forumbeeResponse.data,
                        zendeskTickets: detailedResults.zendeskTickets.results,
                        issuesArr: detailedResults.jiraIssues.issues,
                        totalResults: detailedResults.zendesk.count + detailedResults.git.count + forumbeeResponse.paging.count + detailedResults.zendeskTickets.count + detailedResults.jiraIssues.total,
                        // totalResults: detailedResults.zendesk.count + detailedResults.git.count + forumbeeResponse.paging.count + detailedResults.zendeskTickets.count,
                        enabledChip: SetChip(tempPageData),
                        searchInProgress: false,

                        resultCount: {
                            kbCount: detailedResults.zendesk.count,
                            communityCount:forumbeeResponse.paging.count,
                            docsCount: detailedResults.git.count,
                            ticketCount: detailedResults.zendeskTickets.count,
                            jiraCount: detailedResults.jiraIssues.total
                        },

                        pageData: tempPageData
                    }
                }
                this.setState(stateObj, () => {
                    console.log(" ###### CALL ENDED ######")
                    console.log(this.state.zendeskArticles.length)
                })
            }).catch((err) => {
                console.log(err)
            })
        }
    }



    // ==== Pagination of Data ====
    paginateData = (increment, pageValue=null) => {
        let {pageData, enabledChip} = this.state

        this.setState({
            pageinationLoader: true
        })

        let data = {
            searchValue: this.state.searchValue,
            page: pageValue !== null ? pageValue :  pageData[enabledChip].page + increment
        }
        let tempStateObj = {}

        pageData[enabledChip].page = data.page

        if(enabledChip === "Knowledge Base"){
            PrimaryServices.getKbPage(data).then((result)=>{
                tempStateObj = {
                    zendeskArticles: result.data.zendesk.results,
                }
                this.setNewPage(tempStateObj, pageData)
            })

        }else if(enabledChip === "Documentation"){
            PrimaryServices.getGitPage(data).then((result)=>{
                tempStateObj = {
                    gitArticles: result.data.git.results,
                }
                this.setNewPage(tempStateObj, pageData)
            })

        }else if(enabledChip === "Community"){
            PrimaryServices.getCommunityPage(data).then((result)=>{
                let forumbeeResponse = ForumbeeServiceHelper.forumbeeResponseHandler(result.data.forumbee)
                tempStateObj = {
                    forumbeeArr: forumbeeResponse.data,
                }
                this.setNewPage(tempStateObj, pageData)
            })

        }else if(enabledChip === "Tickets"){
            PrimaryServices.getTicketPage(data).then((result)=>{
                tempStateObj = {
                    zendeskTickets: result.data.zendeskTickets.results,
                }
                this.setNewPage(tempStateObj, pageData)
            })

        }else if(enabledChip === "Jira"){
            PrimaryServices.getIssuePage(data).then((result)=>{
                tempStateObj = {
                    issuesArr: result.data.jiraIssues.issues,
                }
                this.setNewPage(tempStateObj, pageData)
            })

        }else if(enabledChip === "My Requests"){
            PrimaryServices.getRequestPage(data).then((result)=>{
                tempStateObj = {
                    zendeskRequests: result.data.zendeskRequests.results,
                }
                this.setNewPage(tempStateObj, pageData)
            })
        }

    }

    setNewPage = (tempPageData, pageData) => {
        this.setState({
            ...tempPageData,
            pageData: pageData,
            pageinationLoader: false
        }, ()=>{
            window.scrollTo(0,0)
        })
    }


    // ==== Search result redirection methods ====
    // redirectCustomer = (forwardLink, segment = null) => {
    //     let jwtEnable = this.props.globalDetails.jwtEnable
    //     let userType = this.props.globalDetails.userType
    //     if (userType !== null && userType !== undefined && userType === "customer") {
    //         if (jwtEnable === true) {
    //             ;
    //             let jwtLink = this.props.globalDetails.jwtToken
    //             jwtLink = jwtLink + `&return_to=${forwardLink}`
    //             window.open(jwtLink, "_blank")
    //             this.props.disableJwt()
    //         } else {
    //             window.open(forwardLink, "_blank")
    //         }
    //     }
    //     else if (segment !== null && (userType === null || userType === undefined)) {
    //         this.props.globalDetails.loginModal.toggle()
    //     }
    //     else {
    //         window.open(forwardLink, "_blank")
    //     }
    // }

    redirectCustomer = (forwardLink, segment=null) => {
        let userType = this.props.globalDetails.displayUserCategory
        let signState = this.props.globalDetails.signState
        if(segment === null || signState === true){
            this.redirectOtherLinks(forwardLink)
        }
        else{
            if(userType === "customer"){
                ZendeskLoginHelper("newTab");
                this.props.addRedirect({link: forwardLink})
            }else{
                this.props.globalDetails.loginModal.toggle()
            }
        }
    }


    redirectOtherLinks = (forwardLink) => {
        window.open(forwardLink, "_blank")
    }

    testMethodHandler = () => {
        // console.log("Printing global values")
        // console.log(this.props.globalDetails.selectedFilter)
        // console.log(this.props.globalDetails.selectedFilterNames)
        // debugger;

        // window.zE(function() {
        // window.zE('webWidget', 'helpCenter:reauthenticate');
        //   });

        console.log(this.props.globalDetails.searchFilters)
        console.log(this.state.pageData)
        console.log(this.state.enabledChip)
    }

    render() {

        let { searchValue, zendeskArticles, zendeskTickets, gitArticles, forumbeeArr, issuesArr, totalResults, searchInProgress, zendeskRequests, appliedFilterList,
            appliedFilterNameList, enabledFilterList, enabledChip, pageData, resultCount } = this.state

        // let userType = ls.get('userType')
        let userType = this.props.globalDetails.userType

        let trash2 = Const.zendeskRegexUrl
        let ticketUrl

        // let userDetails ? userType === "end-user" ? ticketUrl = Const.zendeskCustomerTicketUrl : ticketUrl = Const.zendeskAgentTicketUrl : ticketUrl = ""
        (userType !== null && userType !== undefined) ? userType === "customer" ? ticketUrl = Const.zendeskCustomerTicketUrl : ticketUrl = Const.zendeskAgentTicketUrl : ticketUrl = ""

        return (

            <React.Fragment>
                <div className="min-height-full">
                    {/* ==== Header Component ==== */}
                    <Header parent={'search'} />

                    {/* ==== Search option above results ==== */}

                    <div className="container avn"> <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        {/* <div className="row search-rows search-news">
                            <div className="col-md-4">
                            This is Fun
                            </div>
                        </div> */}
                        <div className="row search-rows search-news">
                            <div className="col-md-4">
                                <h1 className="fs-9 text-black" onClick={this.testMethodHandler}> Search Results</h1>
                                <p className="color-n"> {totalResults} Total Results Found</p>
                            </div>
                            <div className="col-md-8 text-end">
                                <div className="multiselect-wrapper search-results-ps">
                                    <div className="input-group mt-4 py-2 w-74 rounded outline-0"> <span className="input-group-text outline-0" id="basic-addon1"> <img id="inputImg" src="./img/Vector.png" height={24} /> </span>
                                        <input type="text" className="form-control ffff py-2 border-0" placeholder="Ask us your Question" name="search" value={searchValue} onChange={this.inputHandler} onKeyDown={this.initSearch} />
                                    </div>
                                    <div className="multiselect-mobile-wr">
                                        {/* <select id="multiselect" multiple="multiple" ref={this.dropdownRef}>
                                        <option value="http://ipv4.download.thinkbroadband.com/5MB.zip">Auto</option>
                                        <option value="http://ipv4.download.thinkbroadband.com/10MB.zip">Auto</option>
                                        <option value="http://ipv4.download.thinkbroadband.com/20MB.zip">Auto</option>
                                        <option value="http://ipv4.download.thinkbroadband.com/50MB.zip">Auto</option>
                                        <option value="http://ipv4.download.thinkbroadband.com/50MB.zip">Auto</option>
                                        <option value="http://ipv4.download.thinkbroadband.com/50MB.zip">Auto</option>
                                        <option value="http://ipv4.download.thinkbroadband.com/50MB.zip">Auto</option>
                                        <option value="http://ipv4.download.thinkbroadband.com/50MB.zip">Auto</option>
                                    </select> */}
                                        <Dropdown isOpen={this.state.isOpen} toggle={this.toggleDropdown} key={this.state.dropdownKey}>
                                            <DropdownToggle caret>
                                                {(this.state.appliedFilterList.length > 1) ? (<span>{`${this.state.appliedFilterList.length} selected`}</span>) : this.state.appliedFilterList.length > 0 ? (this.state.appliedFilterNameList[0]) : 'Select an Option'}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {
                                                    this.props.globalDetails.searchFilters.map((value) => {
                                                        return (
                                                            <div onClick={() => { this.addToFiltersList(value) }}>
                                                                <DropdownItem header > <Label>
                                                                    <Input type="checkbox" checked={this.state.appliedFilterList.indexOf(value.selectorKey) !== -1} />{' '}
                                                                    <span className="dropdown-li-label fs-2">{value.name}</span>
                                                                </Label></DropdownItem>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </DropdownMenu>
                                        </Dropdown>
                                        <input type="submit" onclick="getSelectedValues()" className="btn btn-primary" defaultValue="Search" value="Search" onClick={this.searchHandler} />
                                    </div>
                                </div>
                            </div>
                            <hr className="m-0"/>
                            <div className="d-flex justify-content-between py-3 cloud-wrapper">
                                {/* <div className="d-flex chips-parent">
                                    <ThemeProvider theme={ChipTheme}>
                                        {                        
                                            this.state.appliedFilterList.map((filterName) => {
                                                return (<Chip variant={this.state.enabledFilterList.indexOf(filterName) == -1 ? 'default' : 'outlined'} label={filterName} onClick={() => { this.toggleChips(filterName) }} clickable color="primary" />)
                                            })
                                        }
                                    </ThemeProvider>
                                </div> */}

                                
                                
                                {/* <div className="chips-parent">
                                    <ThemeProvider theme={ChipTheme}>
                                        {                        
                                            appliedFilterList.length === 0 ?
                                                this.props.globalDetails.searchFilters.map((value)=>{
                                                    return (<Chip variant={this.state.enabledFilterList.indexOf(value.name) == -1 ? 'outlined' : 'default' } label={value.name} onClick={() => { this.toggleChips(value.name) }} clickable color="primary" />)
                                                })
                                                :
                                                appliedFilterNameList.map((filterName) => {
                                                    return (<Chip variant={this.state.enabledFilterList.indexOf(filterName) == -1 ? 'outlined' : 'default' } label={filterName} onClick={() => { this.toggleChips(filterName) }} clickable color="primary" />)
                                                })
                                        }
                                    </ThemeProvider>
                                </div> */}


                                <div className="chips-parent">
                                    <ThemeProvider theme={ChipTheme}>
                                        {                        
                                            appliedFilterList.length === 0 ?
                                                this.props.globalDetails.searchFilters.map((value)=>{
                                                    return (<Chip variant={enabledChip !== value.name ? 'outlined' : 'default' } label={value.name} onClick={() => { this.toggleChips(value.name) }} clickable color="primary" />)
                                                })
                                                :
                                                appliedFilterNameList.map((filterName) => {
                                                    return (<Chip variant={enabledChip !== filterName ? 'outlined' : 'default' } label={filterName} onClick={() => { this.toggleChips(filterName) }} clickable color="primary" />)
                                                })
                                        }
                                    </ThemeProvider>
                                </div>


                            </div>
                            <hr />
                        </div>


                        {
                            searchInProgress ?
                                <center>
                                    <center><PropagateLoader css={this.override} size={20} color={"#e24402"} loading={true} /></center>
                                </center>
                                :
                                ''
                        }

                        {/* <center><PropagateLoader css={this.override} size={30} color={"#e24402"} loading={true} /></center> */}

                        {
                            // true == false ?
                                // <div className="no-results-overlay">
                                //     <h4 className="fs-4 fw-800">No Search Results</h4>
                                //     <p className="fs-2">Check your spelling or try reframing the question</p>
                                // </div>
                            searchInProgress ?
                                <center>
                                    <center><PropagateLoader css={this.override} size={20} color={"#e24402"} loading={true} /></center>
                                </center>
                            :
                                (
                                    <React.Fragment>
                                        <div className="container search-results-row">
                                            {
                                                zendeskArticles.length !== 0 && enabledChip === "Knowledge Base" ?
                                                    zendeskArticles.map((value, index) => {
                                                        return (
                                                            <div className="pb-4 selector-pointer" onClick={() => { this.redirectCustomer(value.html_url, value.user_segment_id) }}>
                                                                <h2 className="fs-6 color-n"> {value.title} &nbsp; {value.user_segment_id === null ? '' : <FontAwesomeIcon icon={faLock} />} 
                                                                {/* <span className="category-tag">Knowledge Base</span> */}
                                                                </h2>
                                                                {/* <p>{value.snippet.substring(0,200)}...</p> */}
                                                                <p>{ReactParser(Truncate(value.snippet, 200))}</p>
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    zendeskArticles.length === 0 && enabledChip === "Knowledge Base" && totalResults !== 0 ?
                                                        <div>
                                                            {/* <center><img className="search_illustrator" src="./img/empty_illustration_search.png"/></center> */}
                                                            <h5 className="text-black"><FontAwesomeIcon icon={faCaretRight}/> &nbsp; &nbsp; <b>0 results found in {enabledChip}</b></h5>
                                                            {
                                                                this.props.globalDetails.searchFilters.map((filter) => {
                                                                    return(
                                                                        (resultCount[filter.counter] != 0 && filter.name !== "Knowledge Base") &&                                                                                                                                                         
                                                                            <div><h5 className="text-black"><FontAwesomeIcon icon={faCaretRight} /> &nbsp; &nbsp; {resultCount[filter.counter]} results found in {filter.name}. &nbsp; 
                                                                                <span className="color-n selector-pointer" onClick={()=>{this.toggleChips(filter.name)}}>Click here to browse</span></h5></div>
                                                                    )
                                                                })
                                                            }
                                                        </div> 
                                                        :
                                                        ''
                                                    
                                            }

                                            {
                                                issuesArr.length !== 0 &&  enabledChip === "Jira" ?
                                                    issuesArr.map((value, index) => {
                                                        return (
                                                            <div className="pb-4 selector-pointer" onClick={() => { this.redirectOtherLinks(Const.jiraIssueUrl + value.key) }} >
                                                                <h2 className="fs-6 color-n"> {value.fields.summary} 
                                                                {/* <span className="category-tag">Issue</span> */}
                                                                </h2>
                                                                {/* <p>{value.summaryText.substring(0,200)}...</p> */}
                                                                <p>{value.fields.description?.content[0]?.content[0]?.text ?  ReactParser(Truncate(value.fields.description?.content[0]?.content[0]?.text, 150)): ''}</p>
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    issuesArr.length === 0 &&  enabledChip === "Jira" && totalResults !== 0?
                                                        <div>
                                                            {/* <center><img className="search_illustrator" src="./img/empty_illustration_search.png"/></center> */}
                                                            <h5 className="text-black"><FontAwesomeIcon icon={faCaretRight}/> &nbsp; &nbsp; <b>0 results found in {enabledChip}</b></h5>
                                                            {
                                                                this.props.globalDetails.searchFilters.map((filter) => {
                                                                    return(
                                                                        (resultCount[filter.counter] != 0 && filter.name !== "Jira") &&                                                                                                                                                         
                                                                            <div><h5 className="text-black"><FontAwesomeIcon icon={faCaretRight} /> &nbsp; &nbsp; {resultCount[filter.counter]} results found in {filter.name}. &nbsp; 
                                                                                <span className="color-n selector-pointer" onClick={()=>{this.toggleChips(filter.name)}}>Click here to browse</span></h5></div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        :
                                                        ''
                                            }

                                            {
                                                zendeskTickets.length !== 0 &&  enabledChip === "Tickets" ?
                                                    zendeskTickets.map((value, index) => {
                                                        return (
                                                            <div className="pb-4 selector-pointer" onClick={() => this.redirectCustomer(ticketUrl + value.url.replace(trash2, ''))} >
                                                                <h2 className="fs-6 color-n"> {value.subject} 
                                                                <span className="category-tag">{value.status}</span>
                                                                </h2>
                                                                {/* <p>{value.description.substring(0,200)}...</p> */}
                                                                <p>{ReactParser(Truncate(value.description, 200))}</p>
                                                                {/* <p>{Truncate(value.description, 200)}</p> */}
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    zendeskTickets.length === 0 &&  enabledChip === "Tickets" && totalResults !== 0?
                                                    <div>
                                                        {/* <center><img className="search_illustrator" src="./img/empty_illustration_search.png"/></center> */}
                                                        <h5 className="text-black"><FontAwesomeIcon icon={faCaretRight}/> &nbsp; &nbsp; <b>0 results found in {enabledChip}</b></h5>
                                                            {
                                                                this.props.globalDetails.searchFilters.map((filter) => {
                                                                    return(
                                                                        (resultCount[filter.counter] != 0 && filter.name !== "Tickets") &&                                                                                                                                                         
                                                                            <div><h5 className="text-black"><FontAwesomeIcon icon={faCaretRight} /> &nbsp; &nbsp; {resultCount[filter.counter]} results found in {filter.name}. &nbsp; 
                                                                                <span className="color-n selector-pointer" onClick={()=>{this.toggleChips(filter.name)}}>Click here to browse</span></h5></div>
                                                                    )
                                                                })
                                                            }
                                                    </div>
                                                    :
                                                    ''
                                            }

                                            {
                                                zendeskRequests.length !== 0 &&  enabledChip === "My Requests" ?
                                                    zendeskRequests.map((value, index) => {
                                                        return (
                                                            <div className="pb-4 selector-pointer" onClick={() => this.redirectCustomer(ticketUrl + value.url.replace(trash2, ''))} >
                                                                <h2 className="fs-6 color-n"> {value.subject} 
                                                                {/* <span className="category-tag">Request</span> */}
                                                                </h2>
                                                                {/* <p>{value.description.substring(0,200)}...</p> */}
                                                                <p>{ReactParser(Truncate(value.description, 200))}</p>
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    zendeskRequests.length === 0 &&  enabledChip === "My Requests" && totalResults !== 0?
                                                    <div>
                                                        {/* <center><img className="search_illustrator" src="./img/empty_illustration_search.png"/></center> */}
                                                        <h5 className="text-black"><FontAwesomeIcon icon={faCaretRight}/> &nbsp; &nbsp; <b>0 results found in {enabledChip}</b></h5>
                                                            {
                                                                this.props.globalDetails.searchFilters.map((filter) => {
                                                                    return(
                                                                        (resultCount[filter.counter] != 0 && filter.name !== "My Results") &&                                                                                                                                                         
                                                                            <div><h5 className="text-black"><FontAwesomeIcon icon={faCaretRight} /> &nbsp; &nbsp; {resultCount[filter.counter]} results found in {filter.name}. &nbsp; 
                                                                                <span className="color-n selector-pointer" onClick={()=>{this.toggleChips(filter.name)}}>Click here to browse</span></h5></div>
                                                                    )
                                                                })
                                                            }
                                                    </div>
                                                    :
                                                    ''
                                            }

                                            {
                                                forumbeeArr.length !== 0 &&  enabledChip === "Community" ?
                                                    forumbeeArr.map((value, index) => {
                                                        return (
                                                            <div className="pb-4 selector-pointer" onClick={() => { this.redirectOtherLinks(Const.forumbeeBaseUrl + value.url) }} >
                                                                <h2 className="fs-6 color-n"> {value.title} 
                                                                {/* <span className="category-tag">Community</span> */}
                                                                </h2>
                                                                <p>{ReactParser(Truncate(value.text, 200))}</p>
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    forumbeeArr.length === 0 &&  enabledChip === "Community" && totalResults !== 0?
                                                    <div>
                                                        {/* <center><img className="search_illustrator" src="./img/empty_illustration_search.png"/></center> */}
                                                        <h5 className="text-black"><FontAwesomeIcon icon={faCaretRight}/> &nbsp; &nbsp; <b>0 results found in {enabledChip}</b></h5>
                                                            {
                                                                this.props.globalDetails.searchFilters.map((filter) => {
                                                                    return(
                                                                        (resultCount[filter.counter] != 0 && filter.name !== "Community") &&                                                                                                                                                         
                                                                            <div><h5 className="text-black"><FontAwesomeIcon icon={faCaretRight} /> &nbsp; &nbsp; {resultCount[filter.counter]} results found in {filter.name}. &nbsp; 
                                                                                <span className="color-n selector-pointer" onClick={()=>{this.toggleChips(filter.name)}}>Click here to browse</span></h5></div>
                                                                    )
                                                                })
                                                            }
                                                    </div>
                                                    :
                                                    ''
                                            }


                                            {
                                                gitArticles.length !== 0 &&  enabledChip === "Documentation" ?
                                                    gitArticles.map((value, index) => {
                                                        return (
                                                            <div className="pb-4 selector-pointer" onClick={() => { this.redirectOtherLinks(value.domain + value.path) }} >
                                                                <h2 className="fs-6 color-n"> {value.title} 
                                                                {/* <span className="category-tag">Documentation</span> */}
                                                                </h2>
                                                                {/* <p>{value.blocks[0].content.substring(0,200)}...</p> */}
                                                                <p>{ReactParser(Truncate(value.blocks[0].content, 200))}</p>
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    gitArticles.length === 0 &&  enabledChip === "Documentation" && totalResults !== 0?
                                                    <div>
                                                        {/* <center><img className="search_illustrator" src="./img/empty_illustration_search.png"/></center> */}
                                                        <h5 className="text-black"><FontAwesomeIcon icon={faCaretRight}/> &nbsp; &nbsp; <b>0 results found in {enabledChip}</b></h5>
                                                            {
                                                                this.props.globalDetails.searchFilters.map((filter) => {
                                                                    return(
                                                                        (resultCount[filter.counter] != 0 && filter.name !== "Documentation") &&                                                                                                                                                         
                                                                            <div><h5 className="text-black"><FontAwesomeIcon icon={faCaretRight} /> &nbsp; &nbsp; {resultCount[filter.counter]} results found in {filter.name}. &nbsp; 
                                                                                <span className="color-n selector-pointer" onClick={()=>{this.toggleChips(filter.name)}}>Click here to browse</span></h5></div>
                                                                    )
                                                                })
                                                            }
                                                    </div>
                                                    :
                                                    ''
                                            }

                                            {/* ==== Common No Results Illustration ===== */}
                                            {
                                                (totalResults === 0 && !searchInProgress) && 
                                                    <div>
                                                        <center><img className="search_illustrator" src="./img/empty_illustration_search.png"/></center>
                                                    </div>
                                            }

                                            {/* <div className="pb-4">
                                            <h2 className="fs-6 color-n"> Adding SAAS Application Tenants <span className="category-tag">Community</span></h2>
                                            <p> This deployement guide provides information on prerequisites,how to deplay a ZPA Private Service
                                            Edge on Amazon Web Services(AWS) and verification checks</p>
                                            </div>
                                            <div className="pb-4">
                                            <h2 className="fs-6 color-n"> Adding SAAS Application Tenants</h2>
                                            <p> This deployement guide provides information on prerequisites,how to deplay a ZPA Private Service
                                            Edge on Amazon Web Services(AWS) and verification checks</p>
                                            </div>
                                            <div className="pb-4">
                                            <h2 className="fs-6 color-n"> Adding SAAS Application Tenants</h2>
                                            <p> This deployement guide provides information on prerequisites,how to deplay a ZPA Private Service
                                            Edge on Amazon Web Services(AWS) and verification checks</p>
                                            </div>
                                            <div className="pb-4">
                                            <h2 className="fs-6 color-n"> Adding SAAS Application Tenants</h2>
                                            <p> This deployement guide provides information on prerequisites,how to deplay a ZPA Private Service
                                            Edge on Amazon Web Services(AWS) and verification checks</p>
                                            </div>
                                            <div className="pb-4">
                                            <h2 className="fs-6 color-n"> Adding SAAS Application Tenants</h2>
                                            <p> This deployement guide provides information on prerequisites,how to deplay a ZPA Private Service
                                            Edge on Amazon Web Services(AWS) and verification checks</p>
                                            </div>
                                            <div className="pb-4">
                                            <h2 className="fs-6 color-n"> Adding SAAS Application Tenants</h2>
                                            <p> This deployement guide provides information on prerequisites,how to deplay a ZPA Private Service
                                            Edge on Amazon Web Services(AWS) and verification checks</p>
                                            </div>
                                            <div className="pb-4">
                                            <h2 className="fs-6 color-n"> Adding SAAS Application Tenants</h2>
                                            <p> This deployement guide provides information on prerequisites,how to deplay a ZPA Private Service
                                            Edge on Amazon Web Services(AWS) and verification checks</p>
                                            </div> */}
                                        </div>

                                        {/* ==== Pagination counter ==== */}
                                        {/* <div className="text-center pt-4 pb-5 pagination-wrapper" onClick={this.paginateData} > 
                                            <span className="text-center rounded pagination p-2"> 
                                                <a className="px-3" href> ←</a> 
                                                <a className="active px-3" href>1</a> 
                                                <a className="px-3" href>2</a> 
                                                <a className="px-3" href>3</a> 
                                                <a className="px-3" href>4</a> 
                                                <a className="px-3" href>5</a>
                                                <a className="px-3" href> →</a> </span> <br />
                                            <br />
                                        </div> */}


                                        {
                                            pageData[enabledChip] !== undefined ?
                                            <div>
                                                {
                                                    pageData[enabledChip].count > 10 ?
                                                        <div className="text-center pt-4 pb-5 pagination-wrapper"  > 
                                                            <span className="text-center rounded pagination p-2"> 
                                                            {
                                                                pageData[enabledChip].page !== 1  ? 
                                                                    // <span className="px-3 selector-pointer" onClick={()=>{this.paginateData(-1)}} > ←</span> 
                                                                    <span className="px-3 selector-pointer" onClick={()=>{this.paginateData(-1)}} > <FontAwesomeIcon icon={faLongArrowAltLeft} /></span> 
                                                                    :
                                                                    ''
                                                            }
                                                            {
                                                                Math.ceil(pageData[enabledChip].count / 10) <= 10 ? 
                                                                    Array.from(Array(Math.ceil(pageData[enabledChip].count / 10)).keys()).map((value)=>{
                                                                        return(
                                                                            <span className={pageData[enabledChip].page === value+1 ? "active px-3 selector-pointer" : "px-3 selector-pointer"}
                                                                                onClick={()=>{this.paginateData(0, value+1)}}
                                                                            >                                    
                                                                                {value + 1}
                                                                            </span>
                                                                        )
                                                                    })
                                                                    :
                                                                    <React.Fragment>
                                                                        {
                                                                            pageData[enabledChip].page <= 6 ?
                                                                                Array.from(Array(Math.ceil(pageData[enabledChip].count / 10)).keys()).slice(0, 10).map((value)=>{
                                                                                    return(
                                                                                        <span className={pageData[enabledChip].page === value+1 ? "active px-3 selector-pointer" : "px-3 selector-pointer"}
                                                                                            onClick={()=>{this.paginateData(0, value+1)}}
                                                                                        >                                    
                                                                                            {value + 1}
                                                                                        </span>
                                                                                    )
                                                                                })
                                                                                :
                                                                                <React.Fragment>
                                                                                    {
                                                                                        Math.ceil(pageData[enabledChip].count / 10) - pageData[enabledChip].page <= 4 ?
                                                                                            Array.from(Array(Math.ceil(pageData[enabledChip].count / 10)).keys()).slice(Math.ceil(pageData[enabledChip].count / 10) -10 , Math.ceil(pageData[enabledChip].count / 10)).map((value)=>{
                                                                                                return(
                                                                                                    <span className={pageData[enabledChip].page === value+1 ? "active px-3 selector-pointer" : "px-3 selector-pointer"}
                                                                                                        onClick={()=>{this.paginateData(0, value+1)}}
                                                                                                    >                                    
                                                                                                        {value + 1}
                                                                                                    </span>
                                                                                                )
                                                                                            })
                                                                                            :
                                                                                            Array.from(Array(Math.ceil(pageData[enabledChip].count / 10)).keys()).slice(pageData[enabledChip].page -6, pageData[enabledChip].page + 4).map((value)=>{
                                                                                                return(
                                                                                                    <span className={pageData[enabledChip].page === value+1 ? "active px-3 selector-pointer" : "px-3 selector-pointer"}
                                                                                                        onClick={()=>{this.paginateData(0, value+1)}}
                                                                                                    >                                    
                                                                                                        {value + 1}
                                                                                                    </span>
                                                                                                )
                                                                                            })
                                                                                    }
                                                                                </React.Fragment>
                                                                        }
                                                                    </React.Fragment>
                                                            }                                                
                                                            {/* {
                                                                Array.from(Array(Math.ceil(pageData[enabledChip].count / 10)).keys()).slice(0, 10).map((value)=>{
                                                                    return(
                                                                        <span className={pageData[enabledChip].page === value+1 ? "active px-3 selector-pointer" : "px-3 selector-pointer"}
                                                                            onClick={()=>{this.paginateData(0, value+1)}}
                                                                        >                                    
                                                                            {value + 1}
                                                                        </span>
                                                                    )
                                                                })
                                                            } */}
                                                            {/* <a className="active px-3" href>{pageData[enabledChip] !== undefined ? pageData[enabledChip].page : 0} </a> */}
                                                            {
                                                                pageData[enabledChip].page !== Math.ceil(pageData[enabledChip].count / 10) ? //&& pageData[enabledChip].page !== 10 ?
                                                                    // <span className="px-3 selector-pointer" onClick={()=>{this.paginateData(1)}} > →</span>
                                                                    <span className="px-3 selector-pointer" onClick={()=>{this.paginateData(1)}} > <FontAwesomeIcon icon={faLongArrowAltRight} /></span> 
                                                                    :
                                                                    ''                                                        
                                                            }
                                                            </span> <br />
                                                            <br></br>
                                                            <PulseLoader css={this.override} size={15} color={"#e24402"} margin={2} loading={this.state.pageinationLoader}></PulseLoader>
                                                        <br />
                                                        
                                                    </div>                                           
                                                    : 
                                                    ""
                                                }
                                            </div>
                                                :

                                                <div className="text-center pt-4 pb-5 pagination-wrapper"  > 
                                                    <span className="text-center rounded pagination p-2"> 
                                                        <span className="px-3 selector-pointer" > ←</span> 
                                                        <a className="active px-3" href>0</a>
                                                        <span className="px-3 selector-pointer" > →</span> </span> <br />
                                                    <br />
                                                </div>
                                        }

                                        {/* <div className="text-center pt-4 pb-5 pagination-wrapper" >
                                        <span className="text-center rounded pagination p-2"> 
                                             <PaginationBar/>
                                        </span>     
                                        </div> */}

                                    {/* <PaginationBar/> */}
                                    </React.Fragment>
                                    
                                )
                        }



                        {/* ==== Login Modal ==== */}
                        <LoginModal />

                        {/* ==== Signup Modal ==== */}
                        <SignupModal />

                        {/* ==== Footer section ==== */}
                        <Footer floatBottom={true} />
                    </div>
                </div>
            </React.Fragment >
        )
    }
}


const mapStateToProps = state => ({
    globalDetails: state

})

const mapDispatchToProps = dispatch => ({

    updateAgentLogin: (data) => dispatch(GlobalActions.updateAgentLogin(true)),
    loginCustomer: (data) => dispatch(GlobalActions.loginCustomer(data)),
    disableJwt: () => dispatch(GlobalActions.disableJwt()),
    updateFilter: (data) => dispatch(GlobalActions.updateFilter(data)),


    addRedirect: (data) => dispatch(GlobalActions.addRedirect(data)),
    removeRedirect: (data) => dispatch(GlobalActions.removeRedirect(data)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Search)

// export default Search;