import React from 'react'
import { connect, } from 'react-redux';

import * as GlobalActions from '../store/actions'
import * as PrimaryService from '../services/service'
import * as Const from '../constants/constants'

import LogoutAgent from '../pages/logoutPage'
import * as WebWidgetHelper from '../serviceHelper/webWidgetHelper'
import ZendeskLoginHelper from '../serviceHelper/zendeskLoginHelper'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

import {Redirect, Link} from 'react-router-dom'
import Admin from './Admin';
import { withRouter } from 'react-router-dom/cjs/react-router-dom';

class Header extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            expandedSubMenuList: []
        }
    }

//     componentDidMount() {
//         const params =   this.props?.location?.hash;
//         console.log(params,"..params")

//         const accounts = msalInstance.getAllAccounts();
//         console.log({accounts})
// if (accounts.length > 0) {
//   msalInstance.setActiveAccount(accounts[0]);
// }

// msalInstance.addEventCallback((event) => {
//     // set active account after redirect
//     console.log({event})
//     if (event?.payload?.account) {
//     const account = event.payload.account;
//     msalInstance.setActiveAccount(account);
//   }
// }, error=>{
//   console.log('error', error);
// });
//     }

    logoutUser = () => {
        if (this.props.globalDetails.userType === "customer") {
            // this.props.logoutUser()
            console.log("Logout Init")
            // debugger;            
            // window.open(window.location.origin+'/', "_self")
            // this.props.testInit()
            // console.log("Hamlet -01  !!!")
            // // debugger;
            // console.log(this.props.globalDetails.testInit)
            // console.log("Hamlet -02 !!!")
            PrimaryService.logoutCustomer().then((result) => {
                this.props.logoutUser()
                WebWidgetHelper.logoutUser()
                // debugger;
                // window.open(window.location.origin+'/', "_self")
                window.open(Const.zendeskLogoutUrl, "_blank")
                // window.open(window.location.origin+'/', "_self")
            }).catch((err)=>{
                console.log(err)
            })
        } else {
            window.open(window.location.origin+'/logoutAgent', "_self")
        }

    }

    redirectOtherLinks = (forwardLink) => {
        window.open(forwardLink, "_blank")
    }

    redirectLinkInSameWindow = (forwardLink) => {
        window.open(forwardLink, "_self")
    }

    ticketHandling = (redirectURL) => {
        if(this.props.globalDetails.displayUserCategory === "customer"){
            if(this.props.globalDetails.signState === false){
                ZendeskLoginHelper("newTab")
                this.props.addRedirect({link: redirectURL})
            }else{
                this.redirectOtherLinks(redirectURL)
            }
        }
        else {
            if(this.props.globalDetails.signState === false){
                this.props.globalDetails.loginModal.toggle()
                this.props.addRedirect({link: redirectURL})
            }else{
                this.redirectOtherLinks(redirectURL)
            }            
        }
    }

    apiTabHandler = (redirectLink) => {
        if(this.props.globalDetails.signState === false){
            this.ticketHandling(window.location.origin + redirectLink)
        }else{
            this.redirectLinkInSameWindow(redirectLink)
        }

    }



    toggleSubMenu = (id) => {
        if (this.state.expandedSubMenuList.indexOf(id) == -1) {
            this.setState({ expandedSubMenuList: [...this.state.expandedSubMenuList, id] })
        } else {
            let newList = this.state.expandedSubMenuList.filter((item) => {
                return item !== id
            })

            this.setState({ expandedSubMenuList: newList })
        }
    }

    preLoginTask = () => {
        this.props.removeRedirect()
    }


    
    render() {
        let name =JSON.parse(localStorage.getItem('userName'));
   
        // console.log(name,"....")
        return (
            <React.Fragment>

                {/* Desktop View */}
                <header class="pb-4 p-md-0 bg-white shadow-sm">
                    <div class="bg-darkBlue hide-on-mobile">
                        <div className="row dpk-container">
                            <div className="col-md-2 col-5 invisible">text</div>
                            <div className={`col-md-7 h-sm links text-center fs-2 invisible`}>text</div>
                            <div className="col-md-3 col-7 lg-txt-r" style={{ "padding": "10px 0", 'textAlign': 'center' }}>
                                {
                                    this.props.globalDetails.signState ?
                                        <div>                                    
                                            <span className="text-white-name fs-2 mx-1">{this.props.globalDetails.userName || name} &nbsp;</span>

                                            <button type="button" className=" btn fs-2 btn-outline-white mx-1 wid-100"
                                                onClick={this.logoutUser} >Logout</button>
                                        </div>
                                        :
                                        <div>
                                            {
                                                this.props.globalDetails.displayUserCategory === "customer" ?
                                                (
                                                    <div>
                                                        <button type="button"
                                                            className=" btn fs-2 btn-outline-white mx-1 wid-100" data-bs-toggle="modal" data-bs-target="#signup"> Signup </button>

                                                        <button type="button" class=" btn fs-2 btn-outline-white mx-1 wid-100" onClick={()=>{ZendeskLoginHelper(); this.preLoginTask()}} > Login </button>
                                                    </div>
                                                        
                                                    
                                                )
                                                :
                                                <button type="button" class=" btn fs-2 btn-outline-white mx-1 wid-100"
                                                    data-bs-toggle="modal" data-bs-target="#login" onClick={this.preLoginTask} > Login </button>
                                            }
                                        </div>

                                }
                            </div>
                        </div>
                    </div>
                    <div class="dpk-container ">
                        <div class="row">
                            <div class="col-md-1 col-5"> <a href={Const.ReferenceUrl.aviatrixSite} className="header-logo"> <img src="img/logo.png" /> </a> </div>
                            {
                                // this.props.parent == 'document-page' || this.props.parent == 'SLA' ? (
                                this.props.parent !== "homePage" ? (

                                    <div class={`col-md-8 h-sm links text-center fs-2 `}>
                                        <div>
                                            <a href="/">Support Home</a>
                                            <a className="invisible" target="_blank">Documentation</a>
                                            <a className="invisible" target="_blank">Documentation</a>
                                            <a className="invisible" target="_blank">Documentation</a>
                                            {/* <a className="invisible" target="_blank">Documentation</a> */}
                                        </div>
                                    </div>
                                    // <React.Fragment></React.Fragment>

                                ) : (
                                    <div class={`col-md-10 h-sm links text-center fs-2 `}>                                        
                                        <div class="dropdown"> <a class="" >Support </a>
                                            <div class="container-fluid dropdown-content">
                                                <div class="row">
                                                    {/* <div class="col-md-4 text-start"><a className="d-block py-2 pl-0 color-n mt-10">Support</a> */}
                                                    <div class="col-md-4 text-start">
                                                        <hr></hr>
                                                        <a href="/sla" className="d-block py-2 pl-0">SLA</a>
                                                        {
                                                            this.props.globalDetails.displayUserCategory === "customer" ?
                                                                <div>                                       
                                                                    <a onClick={()=> {this.ticketHandling(Const.zendeskCustomerTicketUrl)}} className="d-block py-2 pl-0">Ticket Portal</a>                             
                                                                    <a onClick={()=> {this.ticketHandling(Const.zendeskCustomerCreateTicketUrl)}} className="d-block py-2 pl-0">Ticket Creation</a>
                                                                </div>
                                                                :
                                                                <div>
                                                                <a onClick={()=> {this.ticketHandling(Const.zendeskCustomerTicketUrl)}} className="d-block py-2 pl-0">Ticket Portal</a>
                                                                {/* <a onClick={()=> {this.ticketHandling(Const.jiraBaseUrl)}} className="d-block py-2 pl-0">Jira</a>                                                                 */}
                                                                </div>
                                                        }

                                                        {/* <a onClick={()=> {this.ticketHandling(Const.zendeskCustomerTicketUrl)}} className="d-block py-2 pl-0">Ticket Portal</a>
                                                        <a onClick={()=> {this.ticketHandling(Const.zendeskCustomerCreateTicketUrl)}} className="d-block py-2 pl-0">Ticket Creation</a> */}
                                                        <a href={Const.ReferenceUrl.fieldNotice} target="_blank" className="d-block py-2 pl-0">Field Notice</a>
                                                        <a href={Const.ReferenceUrl.securityBulletin} target="_blank" className="d-block py-2 pl-0">PSIRT Advisories</a>
                                                        <a href="Aviatrix-EOL-Policy" target="_blank" className="d-block py-2 pl-0">End of Life Policy</a>
                                                        <a href={Const.ReferenceUrl.saleNotice} target="_blank" className="d-block py-2 pl-0">End of Sale Notice</a>
                                                        <a href="service-descriptions" target="_blank" className="d-block py-2 pl-0">Terms of Use</a>     

                                                        {
                                                            this.props.globalDetails.signState && this.props.globalDetails.allowDownload&&
                                                                <div>
                                                                    <a href="/downloads" className="d-block py-2 pl-0">Downloads</a>
                                                                </div>
                                                                
                                                        }                                                   
                                                        
                                                    </div>
                                                    <div class="col-md-8 align-self-end"> <img class="img-fluid"
                                                        src="./img/support.png" alt="" /></div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* ==== Temporary Dropdown for Documentation ==== */}
                                        <div class="dropdown"> <a class="" >Documentation</a>
                                            <div class="container-fluid dropdown-content">
                                                <div class="row">
                                                    <div class="col-md-6 text-start">
                                                        <hr></hr>
                                                        <a href={Const.ReferenceUrl.docs} target="_blank" className="d-block py-2 pl-0">Documentation Library</a>
                                                        <a href={Const.ReferenceUrl.controllerRN} target="_blank" className="d-block py-2 pl-0">Controller and Gateway Release Notes</a>
                                                        <a href={Const.ReferenceUrl.copilotRN} target="_blank" className="d-block py-2 pl-0">CoPilot Release Notes</a>
                                                        <a href={Const.ReferenceUrl.gatewayImageRN} target="_blank" className="d-block py-2 pl-0">Controller/Gateway Image Release Notes</a>
                                                        <a href={Const.ReferenceUrl.copilotImageRN} target="_blank" className="d-block py-2 pl-0">CoPilot Image Release Notes</a>
                                                        <a href={Const.ReferenceUrl.vpnClientRN} target="_blank" className="d-block py-2 pl-0">VPN Client Release Notes</a>                                                                                                       
                                                    </div>
                                                    <div class="col-md-6 align-self-end"> <img class="img-fluid"
                                                        src="./img/docs-support-07.png" alt="" /></div>
                                                </div>
                                            </div>
                                        </div>     

                                        {/* <a href={Const.ReferenceUrl.docs} target="_blank">Documentation</a> */}
                                        {/* <a onClick={()=>{this.apiTabHandler("/apiDownloads")}} >API &nbsp; <FontAwesomeIcon icon={faLock}/></a> */}
                                        {
                                            this.props.globalDetails.signState && this.props.globalDetails.allowDownload&&
                                                <a href="/apiDownloads" >API</a>
                                        }
                                        <a href={Const.ReferenceUrl.professionalServices} target="_blank">Professional Services</a>
                                        <a href={Const.ReferenceUrl.ace} target="_blank">ACE</a>
                                        <a href={Const.ReferenceUrl.community} target="_blank">Community</a>
                                        {
                                            this.props.globalDetails.signState && this.props.globalDetails.allowDownload&&
                                                <a href="/downloads" >Downloads</a>
                                        }

{this.props.globalDetails.userType === "customer" ? <div style={{float:'right'}}>
    
     <Admin/>

     </div> : null
     }
                                        
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </header>

                {/* Mobile View  */}

                <div className="d-sm fs-9">
                    <input type="checkbox" id="trigger" className="mob-menu-trigger" />
                    <label htmlFor="trigger" className="menu-close" />
                    <div className="mob-menu">
                        <div className="mobile-header-ss"><a href="index.html"> <img src="img/logo.png" /> </a></div>
                        {/* <div className="col-12">
                            {
                                this.props.globalDetails.signState ?
                                    <button type="button" className=" btn fs-2 btn-outline-danger mx-1"
                                        onClick={this.logoutUser} > Logout </button>
                                    :
                                    <div>
                                        <button type="button"
                                            className=" btn fs-2 btn-outline-danger mx-1" data-bs-toggle="modal" data-bs-target="#signup"> Signup </button>
                                        <button type="button" class=" btn fs-2 btn-outline-danger mx-1"
                                            data-bs-toggle="modal" data-bs-target="#login"> Login </button>
                                    </div>

                            }
                        </div> */}

                        {/* ==== Mobile view of Header starts here!! ==== */}
                        <ul className="mo-ul">
                            {
                                this.props.globalDetails.signState ?
                                    null
                                    :
                                    <React.Fragment>
                                        <li> <a className="text-black active" data-bs-toggle="modal" data-bs-target="#signup">Signup</a></li>
                                        <li> <a className="text-black active" data-bs-toggle="modal" data-bs-target="#login">Login</a></li>
                                    </React.Fragment>

                            }

                            {/* ==== Mobile Support Dropdown ==== */}
                            <li> <a className="text-black mobile-menu-su" onClick={() => { this.toggleSubMenu(1) }}>Support <i className="fas fa-chevron-down" /></a>
                                <ul onClick={()=>{console.log("UL sub!!")}} className="mobile-sub-menuss fs-4" style={{ "display": this.state.expandedSubMenuList.indexOf(1) !== -1 ? "block" : "none" }}>
                                    <li className="uplift-layer" ><a href="/sla">SLA</a></li>
                                    <li className="uplift-layer"><a onClick={()=> {this.ticketHandling(Const.zendeskCustomerTicketUrl)}}>Ticket Portal</a></li>
                                    {
                                        this.props.globalDetails.displayUserCategory === "customer" && 
                                            <li><a onClick={()=> {this.ticketHandling(Const.zendeskCustomerCreateTicketUrl)}} className="d-block py-2 pl-0">Ticket Creation</a></li>
                                    }
                                    <li className="uplift-layer"> <a href={Const.ReferenceUrl.fieldNotice} target="_blank">Field Notice</a></li>
                                    <li className="uplift-layer"><a href={Const.ReferenceUrl.securityBulletin} target="_blank">PSIRT Advisories</a></li>
                                    <li className="uplift-layer"><a href="Aviatrix-EOL-Policy" target="_blank">End of Life Policy</a></li>
                                    <li className="uplift-layer"><a href={Const.ReferenceUrl.saleNotice} target="_blank">End of Sale Notice</a></li>
                                    <li className="uplift-layer"><a href="service-descriptions" target="_blank">Terms of Use</a></li>
                                </ul>
                            </li>
                            {/* ==== Mobile Documentation Dropdown ==== */}
                            <li> <a className="text-black mobile-menu-su" onClick={() => { this.toggleSubMenu(5) }}>Documentation <i className="fas fa-chevron-down" /></a>
                                <ul onClick={()=>{console.log("UL sub!!")}} className="mobile-sub-menuss fs-4" style={{ "display": this.state.expandedSubMenuList.indexOf(5) !== -1 ? "block" : "none" }}>
                                    <li className="uplift-layer"><a href={Const.ReferenceUrl.docs} target="_blank">Documentation Library</a></li>
                                    <li className="uplift-layer"><a href={Const.ReferenceUrl.controllerRN} target="_blank">Controller and Gateway Release Notes</a></li>
                                    <li className="uplift-layer"><a href={Const.ReferenceUrl.copilotRN} target="_blank">CoPilot Release Notes</a></li>
                                    <li className="uplift-layer"><a href={Const.ReferenceUrl.gatewayImageRN} target="_blank">Controller/Gateway Image Release Notes</a></li>
                                    <li className="uplift-layer"><a href={Const.ReferenceUrl.copilotImageRN} target="_blank">CoPilot Image Release Notes</a></li>
                                    <li className="uplift-layer"><a href={Const.ReferenceUrl.vpnClientRN} target="_blank">VPN Client Release Notes</a></li>                                
                                </ul>
                            </li>
                            {/* <li> <a className="text-black active" href={Const.ReferenceUrl.docs} target="_blank">Documentation</a></li> */}
                            <li> <a className="text-black" href={Const.ReferenceUrl.professionalServices} target="_blank">Professional Services</a></li>
                            {this.props.globalDetails.signState && this.props.globalDetails.allowDownload&&  <li><a href="/apiDownloads" >API</a></li>}
                            <li> <a className="text-black" href={Const.ReferenceUrl.ace} target="_blank">ACE</a></li>
                            <li> <a className="text-black" href={Const.ReferenceUrl.community} target="_blank">Community</a></li>
                            {this.props.globalDetails.signState && this.props.globalDetails.allowDownload&& <li><a href="/downloads" >Downloads</a></li>}

                        </ul>
                        <ul className="tow-menu">
                            <li> <a href="/docs">Tech hub</a></li>
                            <li> <a href={Const.ReferenceUrl.onDemandWebinars} target="_blank" >On Demand Webinars</a></li>
                            {/* <li> <a href={Const.ReferenceUrl.videoLib} target="_blank">Video Libraries</a></li> */}
                            {/* ==== Video Libraries Dropdown ==== */}
                            <li> <a className="mobile-menu-tow" onClick={() => { this.toggleSubMenu(4) }}>Video Libraries<i className="fas fa-chevron-down" /></a>
                                <ul className="mobile-sub-menus-tow fs-4" style={{ "display": this.state.expandedSubMenuList.indexOf(4) !== -1 ? "block" : "none" }}>
                                    <li><a href={Const.ReferenceUrl.mcnaSeries} target="_blank">MCNA Series</a> </li>
                                </ul>
                            </li>
                            {/* ==== CSP Dropdown ==== */}
                            <li> <a className="mobile-menu-tow" onClick={() => { this.toggleSubMenu(2) }}>Cloud Provider Status<i className="fas fa-chevron-down" /></a>
                                <ul className="mobile-sub-menus-tow fs-4" style={{ "display": this.state.expandedSubMenuList.indexOf(2) !== -1 ? "block" : "none" }}>
                                    <li><a href={Const.ReferenceUrl.awsStatus} target="_blank">AWS Status</a> </li>
                                    <li><a href={Const.ReferenceUrl.azureStatus} target="_blank">Azure Status</a></li>
                                    <li><a href={Const.ReferenceUrl.gcpStatus} target="_blank">GCP Status</a></li>
                                    <li><a href={Const.ReferenceUrl.ociStatus} target="_blank">OCI Status</a></li>
                                </ul>
                            </li>
                            {/* ==== Marketplace Dropdown ==== */}
                            <li> <a className="mobile-menu-the" onClick={() => { this.toggleSubMenu(3) }}>Marketplace <i className="fas fa-chevron-down" /></a>
                                <ul className="mobile-sub-menus-the fs-4" style={{ "display": this.state.expandedSubMenuList.indexOf(3) !== -1 ? "block" : "none" }}>
                                    <li><a href={Const.ReferenceUrl.awsMP} target="_blank">AWS</a> </li>
                                    <li><a href={Const.ReferenceUrl.azureMP} target="_blank">Azure</a> </li>
                                    <li><a href={Const.ReferenceUrl.gcpMP}>GCP</a></li>
                                    <li><a href={Const.ReferenceUrl.ociMeteredMP} target="_blank">OCI Metered</a></li>
                                    <li><a href={Const.ReferenceUrl.ociByolMP} target="_blank">OCI BYOL</a></li>
                                </ul>
                            </li>
                            {
                                this.props.globalDetails.signState ?
                                    <li> <a className="text-black active" onClick={this.logoutUser}>Logout</a></li>
                                    :
                                    null

                            }
                        </ul>
                    </div>
                </div>




            </React.Fragment>
        )
    }
}


const mapStateToProps = state => ({
    globalDetails: state

})

const mapDispatchToProps = dispatch => ({
    // updateAgentLogin: (data) => dispatch(GlobalActions.updateAgentLogin(true))
    logoutUser: (data) => dispatch(GlobalActions.logoutUser(data)),
    loginModalInstance: (data) => dispatch(GlobalActions.loginModalInstance(data)),
    testInit: (data) => dispatch(GlobalActions.testInit(data)),

    addRedirect: (data) => dispatch(GlobalActions.addRedirect(data)),
    removeRedirect: (data) => dispatch(GlobalActions.removeRedirect(data)),
})

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Header))

