
import * as Const from '../constants/constants'

var jumpToZendeskLogin = (tabType=null) => {
    // https://{subdomain}.zendesk.com/oauth/authorizations/new?response_type=code&redirect_uri={your_redirect_url}&client_id={your_unique_identifier}&scope=read%20write
    let subdomain = Const.zendeskSubDomain
    // let redirectUrl = "http://localhost:3000/zendeskAuth"
    let redirectUrl = window.location.origin + "/zendeskAuth"
    let clientId = Const.zendeskClientId
    // let scope = "tickets:read hc:read requests:read" 
    let scope = "read" 
    let tabPlan = tabType !== null ? "_blank" : "_self"
    let loginLink = `https://${subdomain}.zendesk.com/oauth/authorizations/new?response_type=code&redirect_uri=${redirectUrl}&client_id=${clientId}&scope=${scope}`
    window.open(loginLink, tabPlan)
    // window.open(loginLink, "_self")
}

export default jumpToZendeskLogin;
