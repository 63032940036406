import React from 'react'
import PulseLoader from "react-spinners/PulseLoader";
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";

import * as PrimaryService from '../services/service'
import ZendeskLoginHelper from '../serviceHelper/zendeskLoginHelper'

class SignupModal extends React.Component{
    
    constructor(props){
        super(props)
        this.state = {
            userEmail: '',
            name: '',
            verification: false,
            loaderProgress: false,

            validationObj: {
                email: false,
                name: false
            },
            postApiMessage: ""
        }
        this.override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
        `;
    }

    componentDidMount() { }


    changeToLoginModal = () => {
        // let modal = new window.bootstrap.Modal(document.getElementById("login"));
        // modal.toggle(true);
        ZendeskLoginHelper()
    }

    inputHandler = (e) => {
        let {validationObj} = this.state
        if(e.target.name === "email"){
            validationObj.email = false
            this.setState({
                userEmail: e.target.value,
                validationObj: validationObj,
                verification: false
            })        
        }
        else if(e.target.name === "name"){
            validationObj.name = false
            this.setState({
                name: e.target.value,
                validationObj: validationObj,
                verification: false
            })
        }
    }

    validateSignup = () => {

        let {validationObj, userEmail, name} = this.state
        let validationFail = false
        let nameRegex = new RegExp(/^[a-zA-Z\s]*$/)
        console.log(nameRegex.test(name.trim()))

        if(userEmail.trim() === ""){
            validationFail = true
            validationObj.email = true
        }

        if(name.trim() === "" || !nameRegex.test(name.trim())) {
            validationFail = true
            validationObj.name = true
        }

        this.setState({
            validationObj: validationObj
        }, () => {
            if(validationFail) {return}
            this.setState({
                loaderProgress: true
            })
            this.initSignUp()
            
        })
    }

    initSignUp = () => {

        let signUpBody = {
            "user": {
              "email": this.state.userEmail,
              "name": this.state.name,
              "verified": true
            }
          }

        PrimaryService.signUpUser(signUpBody).then((result)=>{
            console.log("Printing success")
            console.log(result)
            if(result.status === 200){
                this.setState({
                    userEmail: "",
                    name: "",
                    verification: true,
                    loaderProgress: false,
                    postApiMessage: "Check your email for verification"
                })
            }else{
                this.setState({
                    loaderProgress: false,
                    verification: true,
                    postApiMessage: "Email address already exists. Click on Login"
                })
            }
        }).catch((err)=>{
            console.log("Printing failure")
            this.setState({
                loaderProgress: false,
                postApiMessage: "Credentials are invalid."
            })
            console.log(err)
        })
    }

    render() {
        let {validationObj, verification, userEmail, name, loaderProgress, postApiMessage} = this.state
        return (
            <React.Fragment>
                <div className="modal fade" id="signup" tabIndex={-1} aria-labelledby="loginLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content coachmark-wrapper">
                            <div className="half-col-1 single-on-mobile">
                                <div className="modal-body px-4">
                                    <div className="d-flex justify-content-between pb-3">
                                        <h1 className="fs-3"><strong>Sign up</strong></h1>
                                        <a>Have an account? <span className="color-n cursor-p" data-bs-dismiss="modal" onClick={this.changeToLoginModal}>Login</span></a> </div>
                                    {/* <form> */}
                                        <div className="mb-3">
                                            <input className={validationObj.name ? "form-control border-red" : "form-control"} placeholder="Name" type="email" id="name" value={name} aria-describedby="nameHelp" name="name" onChange={this.inputHandler} />
                                        </div>
                                        <div className="mb-3">
                                            <input className={validationObj.email ? "form-control border-red" : "form-control"} placeholder="Email" type="email" id="email" value={userEmail} aria-describedby="emailHelp" name="email" onChange={this.inputHandler} />
                                            {
                                                loaderProgress && 
                                                <center><br></br><PropagateLoader css={this.override} size={15} color={"#e24402"} loading={true} /></center>    
                                            }
                                            <center><PropagateLoader css={this.override} size={15} color={"#e24402"} loading={false} /></center>
                                            {
                                                verification ? (
                                                    <p className="pt-3 fs-1 alert-text">{postApiMessage}</p>    
                                                )
                                                : 
                                                ''
                                            }
                                        </div>
                                        {/* <button id="login-button" className="btn btn-outline-danger px-4 fs-1" type="submit" name="button"><strong>Signup</strong></button> */}
                                        <div className="row fs-2">
                                            <div className="col-md-6 text-start">
                                                <button id="login-button" className="btn btn-outline-danger" name="button" onClick={this.validateSignup} >Signup</button>
                                            </div>
                                            <div className="col-md-6 text-end">
                                            </div>
                                        </div>
                                    {/* </form> */}
                                </div>
                            </div>
                            <div className="half-col-2 text-start hide-on-mobile">
                                <div>
                                    <h3 className="fw-800"><center>Why should I Sign UP?</center></h3><br></br>
                                    <center><p className="m-0">Once you Sign Up, you'll be able to:</p></center><br></br>
                                    <ul>
                                        {/* <li><p>Search across private articles.</p></li>
                                        <li><p>Raise a tickets on Zendesk.</p></li>
                                        <li><p>Search for your tickets on Zendesk.</p></li> */}
                                        <li><p>Search across Zendesk Knowledge Base.</p></li>
                                        <li><p>Search for your tickets on Zendesk.</p></li>
                                        <li><p>Open a tickets on Zendesk.</p></li>                                        
                                    </ul>
                                    {/* <p className="m-0">Allow Aviatrix to manage your account data</p>
                                    <p className="m-0">Allow Aviatrix to manage your account data</p> */}
                                </div>
                            </div>

                            <div className="text-end modal-x-wrapper">
                                <button type="button" className="btn btn-close px-4 modal-close-btn-signup" data-bs-dismiss="modal" aria-label="Close"> <img src="img/close.png" /> </button>
                            </div>

                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    }
}


export default SignupModal;