// ==== Local modules used ====
import * as PrimaryService from '../services/service'
import * as WebWidgetHelper from './webWidgetHelper'
import * as GlobalActions from '../store/actions'
import * as Const from '../constants/constants'

import { store } from '../store/store'


export function forceLogout() {
    let state = store.getState()
    // let logoutReduxMethod = store.dispatch(GlobalActions.logoutUser())
    store.dispatch(GlobalActions.logoutUser())
    console.log("Printing from force logout!!")
    console.log(state.displayUserCategory)


    if (state.displayUserCategory === "customer") {
        PrimaryService.logoutCustomer().then((result) => {
            // logoutReduxMethod()
            WebWidgetHelper.logoutUser()
            window.open(Const.zendeskLogoutUrl, "_blank")

            window.open(window.location.origin + "/", "_self")
        }).catch((err)=>{
            console.log(err)
        })
    } else {
        window.open(window.location.origin+'/logoutAgent', "_self")
    }
}