import * as React from 'react';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import JoditEditor from 'jodit-react';
import { createNotice } from '../../services/noticesService';
import "./style.css"

function CreateNotices({setOpen,handleTabelApiCall}){
  const editor = React.useRef(null);
    const [type, setType] = React.useState('');
    const [template,setTemplate] = React.useState('');
    const [startDate,setStartDate] = React.useState(new Date())
    const [endDate,setEndDate] = React.useState(new Date())



    const handleChange = (event) => {
      console.log(event)
      setType(event.target.value);
    };
  

    const handleCreateNotice = async()=>{
      // const time = new Date().getTime().toString();
      if(template.trim() !==''&& startDate && endDate && type){

        const response = await  createNotice({
        description:template,
        startDate:startDate.toISOString(),
        endDate:endDate.toISOString(),
        type,
        userIDs:[1],
        Notice_ID:new Date().getTime().toString(),
        active:true,
        createdAt:new Date().toISOString()
       })
       console.log(response,'...response')
      }
      handleTabelApiCall()
      setType('')
      setTemplate('')
      setStartDate(new Date())
      setEndDate(new Date())
      setOpen(false)



    }


    return(
        <>
        <div style={{padding:'30px'}}>
        <h4>Details:</h4>

<div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
        <LocalizationProvider dateAdapter={AdapterDayjs} >
      <DemoContainer
        components={[
          'DatePicker'
        ]}
      >

        <DemoItem label="Start Date*">
          <DatePicker defaultValue={dayjs()}
          onChange={(newValue) => setStartDate(newValue)}
          />
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer
        components={[
          'DatePicker'
        ]}
      >

        <DemoItem label="End Date*" >
          <DatePicker   onChange={(newValue) => setEndDate(newValue)}
 defaultValue={dayjs()} />
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
    <Box sx={{ minWidth: 190 }}>
<label style={{padding:'5px 0',fontFamily:'"Roboto","Helvetica","Arial",sans-serif',fontWeight:'300'}}>Type*</label>
      <FormControl fullWidth>
        {/* <InputLabel id="demo-simple-select-label"> </InputLabel> */}
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={type}
          defaultValue='Information'
        //   label="Age"
          onChange={handleChange}
          name='type'
        >
          <MenuItem value={'Information'}>Information</MenuItem>
          <MenuItem value={'Maintenance'}>Maintenance</MenuItem>
          <MenuItem value={'Upgrade Advise'}>Upgrade Advise</MenuItem>
        </Select>
      </FormControl>
    </Box>



            </div>
    <div style={{margin:"20px 0"}}>
<label style={{padding:'5px 0',fontFamily:'"Roboto","Helvetica","Arial",sans-serif',fontWeight:'300'}}>Support Notice Text*</label>

{/* <textarea style={{width:'100%',minHeight:'244px'}}>
</textarea> */}

                      <JoditEditor
                        ref={editor}
                        value={template}
                        config={{
                            //buttons: ["bold","italic","underline","strikethrough","eraser","ul","ol","font","fontsize","paragraph","classSpan","lineHeight","superscript","subscript","cut","copy","paste","selectall","hr"],
                            disablePlugins: ["ordered-list","about","add-new-line","print","fullsize","preview","find","copyformat","video","image","file"]
                        }}
                        //config={config}
                        //tabIndex={1} // tabIndex of textarea
                        onBlur={newContent => setTemplate(newContent)} // preferred to use only this option to Upgrade the content for performance reasons
                        //onChange={newContent => {}}
                        className='jodit-wysiwyg'
                        
                    />

    </div>
<div style={{ display:'flex' ,justifyContent:'flex-start',alignItems:'center'}}>
<button
onClick={handleCreateNotice}
style={{padding:'10px 40px',borderRadius:'5px',background:'#3d3dbe',color:'#fff',marginRight:'10px',fontWeight:'bold',border:'none'}}>POST</button>
<button onClick={()=>setOpen(false)} style={{padding:'10px 40px',borderRadius:'5px',background:'#a9a9a9',color:'#fff',marginRight:'10px',fontWeight:'bold',border:'none'}}>CANCEL</button>
</div>
{
 !! template.trim() ==='' || startDate === '' || endDate === '' || type === ''?(

<p className="pt-3 fs-1 alert-text">{"Please fill all required fields"}</p>
  ):null
}
</div>
        </>
    )
}

export default CreateNotices;