import React from 'react'
import {Redirect} from 'react-router-dom'
import { connect } from 'react-redux';

import * as PrimaryServices from '../services/service'
import * as GlobalActions from '../store/actions'
import * as UserLogout from '../serviceHelper/processLogout'

class ZendeskAuth extends React.Component{
    
    constructor(props){
        super(props)
        this.state={
            codeApproved: false,
            userBase: null
        }
    }

    componentDidMount(){
        // console.log("Redirect URL : ", this.props.globalDetails.redirectLink)
        // debugger;
        let paramsUrl = new URLSearchParams(this.props.location.search)
        console.log(paramsUrl)
        let errorValue = paramsUrl.get("error")
        let codeValue = paramsUrl.get("code")
        let codeParams = {
            code: codeValue
        }

        // ==== Checking if User has declined the auth grant. If yes, Log out user directly ====
        if (errorValue !== undefined && errorValue !== null){
            UserLogout.forceLogout()
        }else if(codeValue !== null && codeValue !== undefined){
            this.setState({
                userBase: this.props.globalDetails.displayUserCategory
            }, ()=>{
                this.AuthenticateUser(codeParams)
            })
        }


    }

    AuthenticateUser = (codeParams) => {
        PrimaryServices.authCodeGrant(codeParams).then((result)=>{
            let data = {
                userType: this.props.globalDetails.displayUserCategory,
                signState: true,
                userName: result.data.userInfo.user.name,
                userEmail: result.data.userInfo.user.email,
                userId: result.data.userInfo.user.id,
                jwtEnable: false,
                allowDownload:true,
                role:result.data.userInfo.user?.role
            }
            if(this.props.globalDetails.displayUserCategory === "customer"){
                this.props.verifyAgentJira(true)    // ==== Added for Temp basis ====
                this.props.loginCustomer(data)                
            }else{
                this.props.loginCustomer(data)
                this.props.verifyAgentJira(true)    // ==== Added for Temp basis ====
                
            }
             // this.props.loginCustomer(data)
             this.setState({
                codeApproved: true
            })
           
        }).catch((err)=>{
            console.log(err)
        })
    }



    render () {
        return(
            this.state.codeApproved ? 
                <React.Fragment>
                    {
                        this.state.userBase === "customer" ? 
                            <Redirect to="/" />
                            :
                            <Redirect to="/jiraAuth" />
                            // <Redirect to="/" />
                    }
                </React.Fragment>
                :
                <div>
                    Loading...
                </div>
        )
    }
}

const mapStateToProps = state => ({
    globalDetails: state

})

const mapDispatchToProps = dispatch => ({

    updateAgentLogin: (data) => dispatch(GlobalActions.updateAgentLogin(true)),
    loginCustomer: (data) => dispatch(GlobalActions.loginCustomer(data)),
    loginModalInstance: (data) => dispatch(GlobalActions.loginModalInstance(data)),

    verifyAgentJira: (data) => dispatch(GlobalActions.verifyAgentJira(data))

})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ZendeskAuth)

// export default ZendeskAuth;