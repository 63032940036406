export function formatDate(inputDate) {
  const date = new Date(inputDate);

  // Define an array of month names
  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const month = monthNames[date.getMonth()];
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();

  return `${month} ${day} ${year}`;
}

 export function timeAgo(timestamp) {
    const currentDate = new Date();
    const inputDate = new Date(timestamp);
    const timeDifference = currentDate - inputDate;
    
    if (timeDifference < 1000) {
      return 'few seconds ago';
    } else if (timeDifference < 60000) {
      const secondsAgo = Math.floor(timeDifference / 1000);
      return `${secondsAgo} seconds ago`;
    } else if (timeDifference < 3600000) {
      const minutesAgo = Math.floor(timeDifference / 60000);
      return `${minutesAgo} minute${minutesAgo > 1 ? 's' : ''} ago`;
    } else if (timeDifference < 86400000) {
      const hoursAgo = Math.floor(timeDifference / 3600000);
      return `${hoursAgo} hour${hoursAgo > 1 ? 's' : ''} ago`;
    } else {
      const daysAgo = Math.floor(timeDifference / 86400000);
      return `${daysAgo} day${daysAgo > 1 ? 's' : ''} ago`;
    }
  }

 export function getStatus(endDate) {
    const currentDate = new Date();
    const endDateObj = new Date(endDate);
  
    return endDateObj > currentDate ? "Active" : "Expired";
  }
  
//   const inputTime = '2023-08-01T18:07:58.235Z';
//   console.log(timeAgo(inputTime));
  