import * as React from 'react';
import { styled } from '@mui/material/styles';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell, { tableCellClasses } from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
import {Icon, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,Pagination, Stack, Tooltip } from "@mui/material";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { formatDate } from '../../operationalHelper/formattedDate';
import { deleteNotice } from '../../services/noticesService';

// const TableCell = styled(TableCell)(({ theme }) => ({
//   [`&.${tableCellClasses.head}`]: {
//     backgroundColor: theme.palette.common.black,
//     color: theme.palette.common.white,
//   },
//   [`&.${tableCellClasses.body}`]: {
//     fontSize: 14,
//   },
// }));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];



export default function NoticesTable({setOpenUpdate,data=[],loading=false,setSelectedRow,updateTableData}) {
  const history=useHistory()

  // console.log(data?.Items)
const handleDeleteNotice = async(data)=>{
  
  const response = await  deleteNotice({
    ...data,
    active:false
 }).then((suc)=>{
  updateTableData();
 })

}
  return (
    <TableContainer component={Paper} >
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell style={{fontWeight:"700",fontSize:'16px'}}>Notices Text</TableCell>
            <TableCell style={{fontWeight:"700",fontSize:'16px'}} align="right">Start Date</TableCell>
            <TableCell style={{fontWeight:"700",fontSize:'16px'}} align="right">End Date</TableCell>
            <TableCell style={{fontWeight:"700",fontSize:'16px'}} align="right">Type</TableCell>
            <TableCell style={{fontWeight:"700",fontSize:'16px'}} align="right">Created At</TableCell>
            <TableCell style={{fontWeight:"700",fontSize:'16px'}} align="right">Actions</TableCell>
            <TableCell style={{fontWeight:"700",fontSize:'16px'}} align="right">Read by Users</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.filter(item=>item.active===true)?.map((row) => (
            <StyledTableRow key={row.Notice_ID}>
              <TableCell width={'600px'}  component="th" dangerouslySetInnerHTML={{__html:row?.description}} scope="row"/>
            
              <TableCell  align="right">{formatDate(row?.startDate)}</TableCell>
              <TableCell align="right">{formatDate(row?.endDate)}</TableCell>
              <TableCell align="right">{row?.type}</TableCell>
              <TableCell align="right">{formatDate(row?.createdAt)}</TableCell>
              <TableCell align="right" width={"20px"}> 
              <div style={{display:"flex",justifyContent:"space-center",alignItems:"center"}}>
              <IconButton size="small" className="action" onClick={()=>{
                setSelectedRow(row);
                setOpenUpdate(true)}} >
                      <Icon style={{color:"black"}} className="icon-green">edit</Icon>
                  </IconButton>
              <IconButton size="small" className="action" onClick={()=>handleDeleteNotice(row)} >
                      <Icon style={{color:"black"}} color='red' className="icon-green">delete_forever</Icon>
                  </IconButton>

              </div>
                  </TableCell>
              <TableCell align="right">

                <button style={{width:'150px'}}onClick={()=>{
                  history.push({
                    pathname:'/users',
                    state:{userIDs: row?.userIDs}
                })
                  }}>Click to See Users</button>
              </TableCell>
              {/* <TableCell align="right">{row.protein}</TableCell> */}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
