import React from "react";
import Admin from "../../components/Admin";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { getNoticeDetail, updateSeenByUser } from "../../services/noticesService";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import "./style.css"
import Loader from "../../components/Loader";
import { useSelector } from "react-redux";
import { getStatus, timeAgo } from "../../operationalHelper/formattedDate";

const NotificationDetails = ()=>{
    const params = useLocation()
    console.log({params})
  
    const [data,setData]= React.useState(null)
    const [loading,setLoading]= React.useState(false)


React.useEffect(()=>{
    getNotifications()
},[])

const getNotifications = ()=>{
  console.log(params,"...ges")
    setLoading(true)
    getNoticeDetail({id:params?.state}).then((res)=>{
        console.log({res})
        setData(res.data?.response)
        setLoading(false);
      updateSeenByUser({id:params?.state,userId:user?.userId}).then((res)=>{
         console.log(res,'... updated')
      }).catch((error)=>{
        console.log(error)
      })
    })
}


const user = useSelector(storeState=>storeState);
console.log(user)

    return (
<>
<Header parent='homePage'/>
{/* <div style={{}}> */}
             <Admin/>
        {/* </div> */}

<h2  className="notification-detail-heading">Notification Details</h2>
<div style={{display:'flex',justifyContent:'center',textAlign:'center',width:'100%',padding:'0 30px'}} className="deatils-section">
  {
    loading ? <Loader/> :
    // <p dangerouslySetInnerHTML={{__html:data?.Item?.description}}/>
<div
  style={{width:'60%',minHeight:'550px',maxHeight:'550px',overflowY:'auto'}}
   className={`notification-content-div
    ${!data?.Item?.userIDs.includes(user?.userId) ? 'conditional-background':''}
    
    ${getStatus(data?.Item?.endDate) === 'Expired'? 'disabled' : ''}
    `}
//     onClick={()=>history.push({
//     pathname:'/notification-details',
//     state: data?.Item?.Notice_ID
// })}


>
<div id="example1">
  <p 
  dangerouslySetInnerHTML={{__html:data?.Item?.description}}
  />
</div>

<div className="flex-time-status">
<p style={{color:'gray'}}>{timeAgo(data?.Item?.createdAt)}</p>
<p style={{fontWeight:'700'}}>{getStatus(data?.Item?.endDate)}</p>
</div>

</div>
  }
{/*   
<p>
In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available. It is also used to temporarily replace text in a process called greeking, which allows designers to consider the form of a webpage or publication, without the meaning of the text influencing the design.
</p>
<p>The Lorem ipsum text is derived from sections 1.10.32 and 1.10.33 of Cicero's 'De finibus bonorum et malorum'.[7][8] The physical source may have been the 1914 Loeb Classical Library edition of De finibus, where the Latin text, presented on the left-hand (even) pages, breaks off on page 34 with "Neque porro quisquam est qui do-" and continues on page 36 with "lorem ipsum ...", suggesting that the galley type of that page was mixed up to make the dummy text seen today.[1]</p>

<p>[32] Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos, qui ratione voluptatem sequi nesciunt, neque porro quisquam est, qui dolorem ipsum, quia dolor sit amet consectetur adipisci[ng] velit, sed quia non numquam [do] eius modi tempora inci[di]dunt, ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum[d] exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? [D]Quis autem vel eum i[r]ure reprehenderit, qui in ea voluptate velit esse, quam nihil molestiae consequatur, vel illum, qui dolorem eum fugiat, quo voluptas nulla pariatur? [33] At vero eos et accusamus et iusto odio dignissimos ducimus, qui blanditiis praesentium voluptatum deleniti atque corrupti, quos dolores et quas molestias excepturi sint, obcaecati cupiditate non provident, similique sunt in culpa, qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem reru[d]um facilis est e[r]t expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio, cumque nihil impedit, quo minus id, quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellend[a]us. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet, ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.</p>
<p>The Lorem ipsum text is derived from sections 1.10.32 and 1.10.33 of Cicero's 'De finibus bonorum et malorum'.[7][8] The physical source may have been the 1914 Loeb Classical Library edition of De finibus, where the Latin text, presented on the left-hand (even) pages, breaks off on page 34 with "Neque porro quisquam est qui do-" and continues on page 36 with "lorem ipsum ...", suggesting that the galley type of that page was mixed up to make the dummy text seen today.[1]</p>
<p>The Lorem ipsum text is derived from sections 1.10.32 and 1.10.33 of Cicero's 'De finibus bonorum et malorum'.[7][8] The physical source may have been the 1914 Loeb Classical Library edition of De finibus, where the Latin text, presented on the left-hand (even) pages, breaks off on page 34 with "Neque porro quisquam est qui do-" and continues on page 36 with "lorem ipsum ...", suggesting that the galley type of that page was mixed up to make the dummy text seen today.[1]</p> */}
</div>


<div className="footer-absolute">

</div>
<Footer/>
</>
    )
}

export default NotificationDetails;