import React from "react"
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'


class PaginationBar extends React.Component{
    
    constructor(props){
        super(props)
        this.state = {}
    }

    render() {
        return(
            <Pagination aria-label="Page navigation example">

                <PaginationItem disabled>
                    <PaginationLink previous href="#" />
                </PaginationItem>

                <PaginationItem active>
                    <PaginationLink href="#">
                    1
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink href="#">
                    2
                    </PaginationLink>
                </PaginationItem>

                <PaginationItem>
                    <PaginationLink next href="#" />
                </PaginationItem>


            </Pagination>


            // <React.Fragment>
            //     <div className="text-center pt-4 pb-5 pagination-wrapper"  > 
            //         <span className="text-center rounded pagination p-2"> 
            //             <span className="px-3 selector-pointer" > ←</span> 
            //             <a className="active px-3" href>{pageData[enabledChip] !== undefined ? pageData[enabledChip].page : 0} </a>
            //             <span className="px-3 selector-pointer" onClick={this.paginateData} > →</span> </span> <br />
            //         <br />
            //     </div>
            // </React.Fragment>




        )
    }
}

export default PaginationBar;