import axios from 'axios'
import * as Const from '../constants/constants'

import * as LogoutMethod from '../serviceHelper/processLogout'

let primaryServer = axios.create({
    baseURL: Const.primaryServer,
    headers: {
        "content-Type": "application/json"
    }
})

primaryServer.interceptors.request.use(
    (response) => {
        console.log("Printing from response!!")
        console.log(response)
        console.log(response.status)
        // LogoutMethod.forceLogout()
        // window.open("https://google.com", "_self")
        return response
    },
    (error) => {
        console.log("Printing from Error!")
        console.log(error)
        return error
    }
)

primaryServer.interceptors.response.use(
    (response) => {
        console.log("Printing from response v2")
        console.log(response)
        console.log(response.status)
        
        return response
    },
    (error) => {
        console.log("Printing from Error! v2")
        console.log(error.response)
        if(error.response.status === 401){
            if(error.response.data.event === "logout"){
                LogoutMethod.forceLogout()
                return
            }
        }
        return error.response
    }
)

export default primaryServer;