import * as msal from "@azure/msal-browser";
export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID,
        // authority: 'https://login.microsoftonline.com/common',
        authority: process.env.REACT_APP_AUTHORITY,
        redirectUri: 'http://localhost:3000',
        
        // redirectUri: 'https://support.staging.aviatrix.com/',
        // postLogoutRedirectUri: "https://support.staging.aviatrix.com/",
        // navigateToLoginRequestUrl: false,
        // redirectUri: 'https://google.com',
        
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: true,
    },
    
};

export const scopes = [ 'user.read' ];

export const homeAccountId = 'homeAccountID';

// Singleton class example
class MsalInstanceSingleton {
    constructor() {
      // Check if an instance already exists
      if (!MsalInstanceSingleton.instance) {
        // If not, create a new instance
        // this.data = 0;
        MsalInstanceSingleton.instance = this;
      }
  
      return new msal.PublicClientApplication(msalConfig);
    }
  
    // Additional methods and properties can be added here
  }

  export {MsalInstanceSingleton}

// export const msalInstance = new msal.PublicClientApplication(msalConfig);