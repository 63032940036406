import React from 'react'
import { Link } from 'react-router-dom'
import ls from 'local-storage'
import { connect } from 'react-redux';
// import JSEncrypt from 'jsencrypt'


import Header from '../components/header'
import Footer from '../components/footer'
import LoginModal from '../components/loginModal'
import SignupModal from '../components/signupModal'

import * as PrimaryServices from '../services/service'
import * as GlobalActions from '../store/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import * as SetWidget from '../operationalHelper/populateWidget'
import * as Const from '../constants/constants'


class EOL extends React.Component {

    constructor(props) {
        super(props)
        this.state = {

        }
    }

    componentDidMount() {
        document.title = "EOL, EOS Policy - Aviatrix"
        SetWidget.populateWidget()
    }



    render() {
        return (
            <React.Fragment>
                <div className="min-height-full">
                    {/* ==== Header for Page ==== */}
                    <Header parent="SLA"></Header>
                    <br />
                    <br />
                    <br className="hide-on-mobile"/>
                    <br className="hide-on-mobile"/>
                    <br className="hide-on-mobile"/> 
                    <div className="container width-wrapper pt-5 p-md-b-5">
                        <div className="row">
                        <p className="m-0 p-0 fs-6">
                                <strong>Aviatrix End of Life Policy
                                    </strong>
                        </p>
                        <p className="m-0 pt-4 p-0 fs-2 ">
                        This document sets out the Aviatrix End of Life (EOL) and End of Support (EOS) policies. These policies are provided to assist Aviatrix customers and partners in effective planning and management of the software.
                        <br/><br/>
                        EOL is defined as the date when engineering will no longer publish new patches for a release.  <br /><br/>
                        EOL is 12 months after a software release’s GA date. Aviatrix will make commercially reasonable efforts to port fixes back to earlier supported versions, however, resolution may require upgrading to the most current GA release. Aviatrix adopts a general fix forward policy to continuously improve our software consistent with the evolving nature of public cloud computing environments.<br /><br/>

                        EOS is defined as the date when support will no longer be available for a release and the customer will need to upgrade to a supported release for continued access to Aviatrix Technical Support. <br /><br/>

                        EOS is 3 months after EOL.<br /><br/>

                        Aviatrix CoPilot operates in a  fix forward model, certain features may require a most recent Controller version to function. Please refer to Copilot release notes.<br /><br/>
                        </p>
                        <p className="m-0 p-0 fs-6">
                                <strong>Updates Required by Customer

                                    </strong>
                        </p>
                        <p className="m-0 pt-4 p-0 fs-2 ">
                        Proper lifecycle management of Aviatrix software ensures that you have the most secure, performant, and scalable solution. Therefore, it is the customer’s responsibility to install software updates, patches, and fixes as we make them available to you in order to maintain the software at its current version.<br/><br/>
                        Customers should target adopting the most current GA release based on their operating model. Older releases are perceived to be more stable. Newer releases offer more features and higher scalability limits.<br/><br/>

                        </p>
                        

                        <p className="m-0 p-0 fs-6">
                                <strong>Aviatrix EOL & EOS Schedule </strong>
                        </p>

                        
                        <div className='col-md-12 mx-auto'>
                        <div className="row">

                       
                        <table class="table text-center samll-font-EOL">
                            <thead>
                                <tr className='bg-danger text-white border border-2 border-end-0 border-white' >
                                    <th scope="col" className='col-md-2 ' >Release</th>
                                    <th scope="col" className='col-md-3  fw-normal' >GA Date</th>
                                    <th scope="col" className='col-md-3  fw-normal' >EOL Date</th>
                                    <th scope="col" className='col-md-3  fw-normal' >EOS Date</th>
                                </tr>
                            </thead>
                            <tbody>
                               
                                <tr>
                                <th scope="row" className='bg-danger text-white border border-2 border-white'>6.8</th>
                                    <td className='bg-light border border-2 border-white'>AUG 09 2022</td>
                                    <td className='bg-light border border-2 border-white'>AUG 09 2023</td>
                                    <td className='bg-light border border-2 border-white'>NOV 09 2023</td>
                                </tr>
                                <tr>
                                    
                                <th scope="row" className='bg-danger text-white border border-2 border-white'>6.9</th>
                                    <td className='bg-light border border-2 border-white'>SEP 09 2022</td>
                                    <td className='bg-light border border-2 border-white'>JAN 09 2024*</td>
                                    <td className='bg-light border border-2 border-white'>APR 09 2024*</td>
                                </tr>
                                <tr>
                                <th scope="row" className='bg-danger text-white border border-2 border-white'>7.0</th>
                                    <td className='bg-light border border-2 border-white'>DEC 08 2022</td>
                                    <td className='bg-light border border-2 border-white'>APR 08 2024*</td>
                                    <td className='bg-light border border-2 border-white'>JUL 08 2024*</td>
                                </tr>
                                <tr>
                                <th scope="row" className='bg-danger text-white border border-2 border-white'>7.1</th>
                                    <td className='bg-light border border-2 border-white'>MAY 11 2023</td>
                                    <td className='bg-light border border-2 border-white'>SEPT 11 2024*</td>
                                    <td className='bg-light border border-2 border-white'>DEC 11 2024*</td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                        <div>
                            <p className="mt-2 ml-2 p-0 fs-2 ">
                            *These dates have been extended due to 
                       <a href='https://docs.aviatrix.com/documentation/latest/release-notes/field-notices/fn-46.html' target='_blank'><span style={{textAlign:'left',color:'blue',borderBottom:'solid blue 0.5px',cursor:'pointer'}}> Field Notice 46</span></a>
                            </p>

                        </div>
                        </div>
                        
                    
                        
                      
                        <p className="m-0 pt-4 p-0 fs-2 ">
                        Note that the EOL and EOS dates apply to the release and all of its patches.

                        </p>
                         
                      
                        
                        </div>
                        {/* <div className="row">
                            
                            
                            <p className="fs-3 p-0  mt-5 text-danger"><a href={Const.ReferenceUrl.howToOpenTicketLink} target="_blank">
                                <strong>How to open a support ticket?</strong></a>
                                </p>
                            <p className="fs-3 mb-4 p-0"><strong>Support:</strong> +1 (888) 311-8328</p>
                            <p className="fs-3 pb-4 text-danger p-0"><a href="service-descriptions" target="_blank">
                                <strong>Service Descriptions</strong></a>
                                </p>
                        </div> */}
                    </div>

                    {/* ==== Login Modal ==== */}
                    <LoginModal></LoginModal>

                    {/* ==== Sign up Modal ==== */}
                    <SignupModal></SignupModal>

                    {/* ==== Footer Component ==== */}
                    <Footer></Footer>
                    <br className="hide-on-mobile"/> 
                    <br className="hide-on-mobile"/> 
                    <br className="hide-on-mobile"/> 
                    

                </div>
            </React.Fragment >
        )
    }
}

export default EOL


// export default Home;