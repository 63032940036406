import {forumbeeResponseHandler} from './forumbeeServiceHelper'
import SetChip from '../operationalHelper/searchResultChipSelector'

var deepFilter = (filterName, detailedResults, forumbeeResponse) => {
    switch (filterName){

        case "zendesk": return {key: "zendeskArticles", value: detailedResults.zendesk.results, count: detailedResults.zendesk.count, nameKey: "Knowledge Base", counter: "kbCount"}

        case "git": return {key: "gitArticles", value: detailedResults.git.results, count: detailedResults.git.count, nameKey: "Documentation", counter: "docsCount"}

        case "forumbee": return {key: "forumbeeArr", value: forumbeeResponse.data, count: forumbeeResponse.paging.count, nameKey: "Community", counter: "communityCount"}

        case "zendeskRequests": return {key: "zendeskRequests", value: detailedResults.zendeskRequests.requests, count: detailedResults.zendeskRequests.count, nameKey: "My Requests", counter: "requestCount"}

        case "zendeskTickets": return {key: "zendeskTickets", value: detailedResults.zendeskTickets.results, count: detailedResults.zendeskTickets.count, nameKey: "Tickets", counter: "ticketCount"}

        case "jiraIssues": return {key: "issuesArr", value: detailedResults.jiraIssues.issues, count: detailedResults.jiraIssues.total, nameKey: "Jira", counter: "jiraCount"}

        default: return {}
    }

    
}



var filteredResponse = (result, appliedFilterList, tempPageData) => {
    console.log("A")
    let detailedResults = result.data
    let forumbeeResponse = forumbeeResponseHandler(detailedResults.forumbee)
    
    let filterSoloCount = {
        kbCount: 0,
        communityCount: 0,
        docsCount: 0,
        ticketCount: 0,
        requestCount: 0,
        jiraCount: 0
    }

    let newStateObj = {}
    let totalCount = 0
    let individualResult = {}
    appliedFilterList.map((filterName)=>{

        individualResult = deepFilter(filterName, detailedResults, forumbeeResponse)
        newStateObj[individualResult.key] = individualResult.value
        totalCount = totalCount + individualResult.count
        filterSoloCount[individualResult.counter] = individualResult.count
        tempPageData[individualResult.nameKey].count = individualResult.count
        
    })

    newStateObj.pageData = tempPageData
    newStateObj.totalResults = totalCount
    newStateObj.searchInProgress = false
    newStateObj.enabledChip = SetChip(tempPageData)
    newStateObj.resultCount = filterSoloCount

    return newStateObj

}

export default filteredResponse;