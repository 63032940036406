import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';

class OktaSignInWidget extends Component {
  componentDidMount() {
    const el = ReactDOM.findDOMNode(this);
    this.widget = new OktaSignIn({
      baseUrl: this.props.baseUrl,
      logo: 'https://itcentralstation-res.cloudinary.com/image/upload/f_auto,q_auto/KDHonPs3zpbuzd1XicNLUvJj.png',
    });
    this.widget.renderEl({el}, this.props.onSuccess, this.props.onError);
  }

//   componentWillUnmount() {
//     this.widget.remove();
//   }

  render() {
    return <div />;
  }
};

export default OktaSignInWidget