// export const primaryServer = "http://ec2-3-87-9-180.compute-1.amazonaws.com:3001"

// ==== Primary webapp user details ====
export const userType = "agent"
// export const userType = "customer"

// ==== Local base Aviatrix Zendesk Value ====
// export const zendeskUrl = "https://pencapcorpv3.zendesk.com/api/v2"
// export const zendeskCustomerTicketUrl = "https://pencapcorpv3.zendesk.com/hc/en-us/requests"
// export const zendeskCustomerCreateTicketUrl = "https://pencapcorpv3.zendesk.com/hc/en-us/requests/new"
// export const zendeskAgentTicketUrl = "https://pencapcorpv3.zendesk.com/agent/tickets"
// export const zendeskRegexUrl = new RegExp(/^https:\/\/pencapcorpv3.zendesk.com\/api\/v2\/tickets|^https:\/\/pencapcorpv3.zendesk.com\/api\/v2\/requests|\.json$/gi)
// export const zendeskSubDomain = "pencapcorpv3"
// export const zendeskClientId = "test-dev-client-01"


// ==== Stage base Aviatrix Zendesk Value ====
export const zendeskUrl = "https://aviatrix.zendesk.com/api/v2"
export const zendeskLogoutUrl = "https://aviatrix.zendesk.com/access/logout"
export const zendeskCustomerTicketUrl = "https://aviatrix.zendesk.com/hc/en-us/requests"
export const zendeskCustomerCreateTicketUrl = "https://aviatrix.zendesk.com/hc/en-us/requests/new"
export const zendeskAgentTicketUrl = "https://aviatrix.zendesk.com/agent/tickets"
export const zendeskRegexUrl = new RegExp(/^https:\/\/aviatrix.zendesk.com\/api\/v2\/tickets|^https:\/\/aviatrix.zendesk.com\/api\/v2\/requests|\.json$/gi)
export const zendeskSubDomain = "aviatrix"
export const zendeskClientId = "aviatrix-support-01"

// export const jiraIssueUrl = "https://mugsandhammer.atlassian.net/browse/"
// export const jiraBaseUrl = "https://mugsandhammer.atlassian.net"

export const jiraIssueUrl = "https://aviatrix.atlassian.net/browse/"
export const jiraBaseUrl = "https://aviatrix.atlassian.net/"

// export const forumbeeBaseUrl = "https://aviatrixtest.forumbee.com"
export const forumbeeBaseUrl = "https://community.aviatrix.com"

export const requestDemoUrl = "https://aviatrix.com/schedule-demo/"

export const techHubCategory = "360005335892"
export const supportKnowledgeCategory = "360004231251"



// ===== Secure Production Server Consts =====
// export const primaryServer = "http://localhost:3001"
export const primaryServer = process.env.REACT_APP_API_FQDN
// export const jiraAuthenticationUrl = "https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=IlPrsOVwgWew0y6L5vksuEdIg1EpKdV4&scope=offline_access%20read%3Ajira-user%20read%3Ajira-work&redirect_uri=https%3A%2F%2Faviatrix-internal.qabinaryveda.com%2Fcode&state=agentps5&response_type=code&prompt=consent"

// Afnan atlassian app
export const jiraAuthenticationUrl = "https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=cVRsY7PApDZi8QQmRE0lReifX4YupgQ5&scope=offline_access%20read%3Aissue%3Ajira%20read%3Aavatar%3Ajira%20read%3Aissue-details%3Ajira%20read%3Aissue-meta%3Ajira%20read%3Aaudit-log%3Ajira%20read%3Afield-configuration%3Ajira&redirect_uri=https%3A%2F%2Fsupport-internal.aviatrix.com%2Fcode&state=uipath123&response_type=code&prompt=consent"

// ==== Secure Stage Server Constants ====
// export const primaryServer = "https://aviatrix-api.qabinaryveda.com"
// export const jiraAuthenticationUrl = "https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=IlPrsOVwgWew0y6L5vksuEdIg1EpKdV4&scope=offline_access%20read%3Ajira-user%20read%3Ajira-work&redirect_uri=https%3A%2F%2Faviatrix-internal.qabinaryveda.com%2Fcode&state=agentps5&response_type=code&prompt=consent"

// ==== Stage Server Constants ====
// export const primaryServer = "https://d3j7n3orw01ha5.cloudfront.net"
// export const jiraAuthenticationUrl = "https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=IlPrsOVwgWew0y6L5vksuEdIg1EpKdV4&scope=offline_access%20read%3Ajira-user&redirect_uri=https%3A%2F%2Fd1ybw01xz5a62p.cloudfront.net%2Fcode&state=agentps5&response_type=code&prompt=consent"
// export const oktaClientId = "0oa3a83u7zcaoSgYi5d6"


// ==== Local Server Constants ====
// export const primaryServer = "http://localhost:3001"
// export const jiraAuthenticationUrl = "https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=k8q5vG0I3Pqy5mrp4HRlpmgtxxXDb5tC&scope=offline_access%20read%3Ajira-user%20read%3Ajira-work&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fcode&state=agentxbox&response_type=code&prompt=consent"
// export const jiraAuthenticationUrl = "https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=M1PExK5T8T0XV8Nl9J1PdKTsI7RR6tkx&scope=offline_access%20read%3Ajira-user%20read%3Ajira-work&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fcode&state=agentXBOX&response_type=code&prompt=consent"
// export const jiraAuthenticationUrl = "https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=Vsk55o3sJUZtB5ANTaqRQWdDNqXVEDsE&scope=read%3Ajira-user%20read%3Ajira-work&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2FjiraAuth&state=ps1223&response_type=code&prompt=consent",
// export const jiraAuthenticationUrl = "https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=Vsk55o3sJUZtB5ANTaqRQWdDNqXVEDsE&scope=offline_access%20read%3Ajira-user%20read%3Ajira-work&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fcode&state=playCrade&response_type=code&prompt=consent"

// export const oktaClientId = "0oa1gv4udUnV5PBzW5d6" // Local Personal Zendesk Client.
// export const oktaClientId = "0oa2id85i1Opn3vBC4x7" // Local Aviatrix Zendesk Client.
export const oktaClientId = "0oa2id7u3yf6FOUIf4x7"  // Stage Aviatrix Okta Client


// ==== This is an object of all external links that will be needed throughout the website. ====
export const ReferenceUrl = {
    
    // ==== top header ====
    aviatrixSite: "https://aviatrix.com/",
    fieldNotice: "https://docs.aviatrix.com/HowTos/field_notices.html#field-notices",
    tou: "https://2ujst446wdhv3307z249ttp0-wpengine.netdna-ssl.com/wp-content/uploads/2020/04/Aviatrix-Software-Support-Services-STOS-Mar-2020.pdf",
    professionalServices: "https://aviatrix.com/professional-services/",
    ace: "https://aviatrix.com/ace/",
    community: "https://community.aviatrix.com/",
    eol: "https://aviatrix.com/wp-content/uploads/2021/08/Aviatrix-EOL-Policy.pdf",
    securityBulletin: "https://docs.aviatrix.com/HowTos/PSIRT_Advisories.html",
    saleNotice: "https://aviatrix.com/aviatrix-end-of-sale-notice/",

    // ==== top header docs ====
    docs: "https://docs.aviatrix.com/",
    controllerRN: "https://docs.aviatrix.com/HowTos/Controller_and_Software_Release_Notes.html#",
    copilotRN: "https://docs.aviatrix.com/HowTos/copilot_release_notes.html",
    gatewayImageRN: "https://docs.aviatrix.com/HowTos/image_release_notes.html",
    copilotImageRN: "https://docs.aviatrix.com/HowTos/copilot_release_notes_images.html",
    vpnClientRN: "https://docs.aviatrix.com/HowTos/Aviatrix_VPN_Client_Release_Notes.html",

    // ==== Middle bar ====
    onDemandWebinars: "https://aviatrix.com/webinars-and-podcasts/",
    videoLib: "https://www.youtube.com/playlist?list=PLS_Z06KvSDsa_ZKIUwgiQb_mSGQNMfcnj",
    mcnaSeries: "https://aviatrix.com/resources/youtube-multi-cloud-network-architecture-series",
    awsStatus: "https://status.aws.amazon.com/",
    azureStatus: "https://status.azure.com",
    gcpStatus: "https://status.cloud.google.com",
    ociStatus: "https://ocistatus.oraclecloud.com/",
    awsMP: "https://aws.amazon.com/marketplace/seller-profile?id=ba8fdede-964b-475d-b679-0f9f899f6293",
    azureMP: "https://azure.microsoft.com/en-us/search/?q=aviatrix&products=undefined&docsids=",
    gcpMP: "https://console.cloud.google.com/marketplace/browse?_ga=2.134935345.1797412516.1622074723-1229520431.1622068690&q=aviatrix",
    ociMeteredMP: "https://cloudmarketplace.oracle.com/marketplace/en_US/listing/65804594",
    ociByolMP: "https://cloudmarketplace.oracle.com/marketplace/en_US/listing/65847636",

    // ==== Tiles ====
    knowledgeBaseUrl: "https://aviatrix.zendesk.com/hc/en-us/categories/360004231251",

    // ==== SLA Links ====
    howToOpenTicketLink: "https://aviatrix.zendesk.com/hc/en-us/articles/360054841492-Aviatrix-Support-Ticket-Submission-Priority-Guidelines",
    // supplementalTou: "https://2ujst446wdhv3307z249ttp0-wpengine.netdna-ssl.com/wp-content/uploads/2020/04/Aviatrix-Software-Support-Services-STOS-Mar-2020.pdf",
    softwareSupportServices:"https://aviatrix.com/wp-content/uploads/2022/09/software-support-services-service-description.pdf",
    premiumSupportServices:"https://aviatrix.com/wp-content/uploads/2023/06/aviatrix-premium-support-services.pdf",
    configurationServices:"https://aviatrix.com/wp-content/uploads/2022/09/cs-service-description.pdf",
    embeddedEngineer:"https://aviatrix.com/wp-content/uploads/2022/09/ee-service-description.pdf",
    managedServices:"https://aviatrix.com/wp-content/uploads/2022/09/ms-service-description.pdf",
    cloudNDeviceSupport : "https://aviatrix.com/wp-content/uploads/2023/06/aviatrix-cloudn-device-support.pdf"

}

