import React from 'react'
import { Link } from 'react-router-dom'
import ls from 'local-storage'
import { connect } from 'react-redux';
// import JSEncrypt from 'jsencrypt'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Label, Input } from 'reactstrap';
import Header from '../components/header'
import Footer from '../components/footer'
import LoginModal from '../components/loginModal'
import SignupModal from '../components/signupModal'
import LoginExplanatoryScreen from '../components/loginExplanatoryScreen'

import * as PrimaryServices from '../services/service'
import * as GlobalActions from '../store/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";
import ZendeskLoginHelper from '../serviceHelper/zendeskLoginHelper'
import * as Const from "../constants/constants"
import * as SetWidget from '../operationalHelper/populateWidget'


class Home extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            searchValue: '',
            latestKBArr: [],
            mostPopularArr: [],
            mostPopularArrKeys: [],

            selectedFilter: [],
            isOpen: false,
            appliedFilterList: [],
            dropdownKey: Math.floor((Math.random() * 100) + 1),
            videoDropdownVisible: false,
            cspDropdownVisible: false,
            marketDropdownVisible: false,
            appliedFilterNameList: [],
        }
    }

    componentDidMount() {
        // console.log("Redirect URL : ", this.props.globalDetails.redirectLink)
        // debugger;
        this.setState({
            appliedFilterList: this.props.globalDetails.selectedFilter,
            appliedFilterNameList: this.props.globalDetails.selectedFilterNames
        })
        this.getLatestKB()
        SetWidget.populateWidget()
        this.testRedirect()
        
    }

    testRedirect = ()=> {
        if(this.props.globalDetails.redirectLink !== null ) {
            if(this.props.globalDetails.signState){
                window.open(this.props.globalDetails.redirectLink, "_self")            
                // window.open(this.props.globalDetails.redirectLink, "_blank")
            }
            if(this.props.globalDetails.authLevel !== "okta"){
                this.props.removeRedirect()
            }            
        }
    }

    // ==== API calling methods Start here ====

    getLatestKB = () => {
        PrimaryServices.getLatestKB().then((result) => {
            console.log(result)
            this.setState({
                latestKBArr: result.data.zendesk.articles,
                mostPopularArr: result.data.mostPopular,
            })
        }).catch((err) => {
            console.log(err)
        })
    }


    // ==== API calling methods End here ====



    // ==== Additional handlers start here ====

    inputHandler = (e) => {
        if (e.target.name === "search") {
            this.setState({ searchValue: e.target.value })
        }
    }

    addToFiltersList = (val) => {
        let { appliedFilterList, appliedFilterNameList } = this.state
        if (appliedFilterList.indexOf(val.selectorKey) == -1) {
            appliedFilterList = [...appliedFilterList, val.selectorKey]
            appliedFilterNameList = [...appliedFilterNameList, val.name]
        } else {
            let newList = appliedFilterList.filter((filter) => { return filter !== val.selectorKey });
            let newNameList = appliedFilterNameList.filter((filter) => { return filter !== val.name });
            appliedFilterList = newList
            appliedFilterNameList = newNameList
        }

        this.setState({ appliedFilterList: appliedFilterList, appliedFilterNameList: appliedFilterNameList, dropdownKey: Math.floor((Math.random() * 100) + 1) }, () => {
            this.props.updateFilter({
                keys: this.state.appliedFilterList,
                names: this.state.appliedFilterNameList
            })
        });
    }

    toggleMiddleBarDropdowns = (key) => {
        this.setState({ [key]: !this.state[key] })
    }


    initSearch = (e) => {
        if(e.keyCode === 13){
            this.searchHandler()
        }
    }

    searchHandler = () => {

        // ==== add ls command ====
        if(this.state.searchValue.trim() === ""){
            return
        }
        ls.set("searchParam", this.state.searchValue)

        window.open(window.location.href + "search", "_self")
    }

    toggleDropdown = (e) => {
        this.setState({ isOpen: !this.state.isOpen });
    }

    // redirectCustomer = (forwardLink, segment = null) => {
    //     let jwtEnable = this.props.globalDetails.jwtEnable
    //     let userType = this.props.globalDetails.userType
    //     if (userType !== null && userType !== undefined && userType === "customer") {
    //         if (jwtEnable === true) {
    //             ;
    //             let jwtLink = this.props.globalDetails.jwtToken
    //             jwtLink = jwtLink + `&return_to=${forwardLink}`
    //             window.open(jwtLink, "_blank")
    //             this.props.disableJwt()
    //         } else {
    //             window.open(forwardLink, "_blank")
    //         }
    //     }
    //     else if (segment !== null && (userType === null || userType === undefined)) {
    //         this.props.globalDetails.loginModal.toggle()
    //     }
    //     else {
    //         window.open(forwardLink, "_blank")
    //     }
    // }

    redirectCustomer = (forwardLink, segment=null) => {
        let userType = this.props.globalDetails.displayUserCategory
        let signState = this.props.globalDetails.signState
        if(segment === null || signState === true){
            // this.redirectOtherLinks(forwardLink)
            window.open(forwardLink, "_blank")
        }
        else{
            this.props.addRedirect({link: forwardLink})
            if(userType === "customer"){
                // this.props.addRedirect({link: forwardLink})
                ZendeskLoginHelper("newTab");
            }else{
                this.props.globalDetails.loginModal.toggle()
            }
        }
    }

    filterHandler = (e) => {
        console.log(e.target.value)
    }

    // ==== Additional handlers end here ====

    render() {
        let { searchValue, latestKBArr, mostPopularArr, appliedFilterList, appliedFilterNameList } = this.state
        let dummyArr = Array.from(Array(5).keys())
        console.log(" ##### Running Redux update #####")
        return (
            <React.Fragment>
                <div className="min-height-full">
                    {/* ==== Header for Page ==== */}
                    <Header parent="homePage"></Header>



                    {/* ==== Search Menu ==== */}
                    <div className="d-flex flex-column flex-center banner">
                        <br />

                        <br />
                        <h1 className="fs-12 text-white mt-5"><strong> We are here to help</strong></h1>
                        <br />
                        <div className="multiselect-wrapper">
                            <div className="input-group mt-4 py-2 w-74 rounded outline-0"> <span className="input-group-text outline-0" id="basic-addon1"> <img id="inputImg" src="./img/Vector.png" height={24} /> </span>
                                <input type="text" className="form-control ffff py-2 border-0 no-border" name="search" value={searchValue} placeholder="Ask us your Question" onChange={this.inputHandler} onKeyDown={this.initSearch} />
                            </div>
                            <div className="multiselect-mobile-wr">
                                {/* <select id="multiselect" multiple="multiple">
                                    {
                                        this.props.globalDetails.searchFilters.map((value) => {
                                            return (
                                                <option value={value.name} onClick={() => console.log("a")}>{value.name}</option>
                                            )
                                        })
                                    }
                                </select> */}
                                <Dropdown isOpen={this.state.isOpen} toggle={this.toggleDropdown} key={this.state.dropdownKey}>
                                    <DropdownToggle className="border-0" caret>
                                        {(appliedFilterList.length > 1) ? (<span>{`${appliedFilterList.length} selected`}</span>) : appliedFilterList.length > 0 ? (appliedFilterNameList[0]) : 'Select an Option'}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {
                                            this.props.globalDetails.searchFilters.map((value) => {
                                                return (
                                                    <div onClick={() => { this.addToFiltersList(value) }}>
                                                        <DropdownItem header > <Label>
                                                            <Input type="checkbox" checked={appliedFilterList.indexOf(value.selectorKey) !== -1} style={{ border: '1px solid #bcbcbc' }} />{' '}
                                                            <span className="dropdown-li-label fs-2">{value.name}</span>
                                                        </Label></DropdownItem>
                                                    </div>
                                                )
                                            })
                                        }

                                    </DropdownMenu>
                                </Dropdown>
                                <input type="submit" onClick={this.searchHandler} className="btn btn-primary" defaultValue="Search" value="Search" />
                            </div>
                        </div>
                    </div>



                    {/* ==== Middle Menu bar ==== */}

                    <div className="d-flex justify-content-around h-sm bg-darkBlue text-center avn wrapper-bar px-xl-3">
                        <h2>&nbsp;</h2>
                        <h2 className="fs-4 fs-sm-2"><a href="/docs">Tech Hub</a></h2>
                        {/* <h2 className="fs-4 fs-sm-2"><a href="#">Support knowledge</a></h2> */}
                        <h2 className="fs-4 fs-sm-2"><a href={Const.ReferenceUrl.onDemandWebinars} target="_blank">On Demand Webinars</a></h2>
                        {/* <h2 className="fs-4 fs-sm-2"><a href={Const.ReferenceUrl.videoLib} target="_blank">Video Libraries</a></h2> */}

                        {/* ==== test Video library ==== */}
                        <div className="dropdown-1">
                            <div className="home-middlebar-dropdown">
                                <Dropdown isOpen={this.state.videoDropdownVisible} toggle={() => { this.toggleMiddleBarDropdowns("videoDropdownVisible") }}>
                                    <DropdownToggle className="fs-4 fs-sm-2">
                                        Video Libraries
                                </DropdownToggle>
                                    <DropdownMenu className="video-custom-positioning" >
                                        <div>
                                            <DropdownItem>
                                                <a className="pb-3 fs-2" href={Const.ReferenceUrl.mcnaSeries} target="_blank">MCNA Series</a>
                                            </DropdownItem>
                                        </div>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>
                        
                        <div className="dropdown-1">
                            {/* <h2 className="fs-4 fs-sm-2 clickme-menu" onClick={() => { this.openDropdown('cspDropdownVisible') }}>CSP Map</h2>
                            <div className="dropdown-content-1 text-start sub-menuss-open" style={{ display: this.state.cspDropdownVisible ? 'block' : 'none' }}> <br />
                                <a className="pb-3 fs-2" href="#">CSS Help</a> <br />
                                <a className="pb-3 fs-2" href="#">Maps</a> <br />
                                <a className="pb-3 fs-2" href="#">Align Maps</a> <br />
                            </div> */}

                            <div className="home-middlebar-dropdown">
                                <Dropdown isOpen={this.state.cspDropdownVisible} toggle={() => { this.toggleMiddleBarDropdowns('cspDropdownVisible') }}>
                                    <DropdownToggle className="fs-4 fs-sm-2">
                                        Cloud Provider Status
                                </DropdownToggle>
                                    <DropdownMenu>
                                        <div>
                                            <DropdownItem>
                                                <a className="pb-3 fs-2" href={Const.ReferenceUrl.awsStatus} target="_blank" >AWS Status</a>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <a className="pb-3 fs-2" href={Const.ReferenceUrl.azureStatus} target="_blank">Azure Status</a>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <a className="pb-3 fs-2" href={Const.ReferenceUrl.gcpStatus} target="_blank">GCP Status</a>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <a className="pb-3 fs-2" href={Const.ReferenceUrl.ociStatus} target="_blank">OCI Status</a>
                                            </DropdownItem>
                                        </div>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="dropdown-1">
                            <div className="home-middlebar-dropdown">
                                <Dropdown isOpen={this.state.marketDropdownVisible} toggle={() => { this.toggleMiddleBarDropdowns('marketDropdownVisible') }}>
                                    <DropdownToggle className="fs-4 fs-sm-2">
                                        Marketplace
                                </DropdownToggle>
                                    <DropdownMenu className="custom-positioning">
                                        <div>
                                            <DropdownItem>
                                                <a className="pb-3 fs-2" href={Const.ReferenceUrl.awsMP} target="_blank">AWS</a>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <a className="pb-3 fs-2" href={Const.ReferenceUrl.azureMP} target="_blank">Azure</a>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <a className="pb-3 fs-2" href={Const.ReferenceUrl.gcpMP}>GCP</a>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <a className="pb-3 fs-2" href={Const.ReferenceUrl.ociMeteredMP} target="_blank">OCI Metered</a>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <a className="pb-3 fs-2" href={Const.ReferenceUrl.ociByolMP} target="_blank">OCI BYOL</a>
                                            </DropdownItem>
                                        </div>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>
                        {/* <h2 className="fs-4 fs-sm-2"><a href="#">Cloud Provider</a></h2> */}
                        <h2>&nbsp;</h2>
                    </div>



                    {/* ==== Grid section for attributes ==== */}

                    <div className="mb-0" style={{ padding: '5rem 3rem 9rem' }}>
                        <div className="container popular-containers">
                            <div className="row">
                                <div className="col-md-1" />
                                <div className="col-md-5 cards mb-md-0 mb-5 ">
                                    <div className="bg-white rounded p-md-5 p-3 me-0 me-md-3 popular-white min-card-height" style={{ boxShadow: '0 0 20px 0 rgb(62 57 107 / 7%), 0 2px 9px 0 rgb(62 57 107 / 6%)' }}>
                                        <div className="d-flex justify-content-between">
                                            <h1 className="fs-4 avn font-bold"> Most Popular</h1>
                                            <img className="text-end" src="img/popular.png" height={32} /> </div>
                                        <hr />
                                        <div>
                                            <ul className="avn">
                                                {
                                                    mostPopularArr.length !== 0 ?
                                                        mostPopularArr.map((value) => {
                                                            return (
                                                                <li className="py-2 fw-800"> <a href={value.link} target="_blank">{value.name}</a></li>
                                                            )
                                                        })
                                                        :
                                                        // dummyArr.map((value) => {
                                                        //     return (
                                                        //         <li className="py-2 fw-800"> <a href="#">Adding SAAS Application Tenants </a></li>
                                                        //     )
                                                        // })
                                                        <div className="pt-5"><center><PropagateLoader css={this.override} size={15} color={"#e24402"} loading={true} /></center></div>
                                                }
                                            </ul>
                                            <a href="#" className="text-end d-block invisible">Show More</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 cards ">
                                    <div className="bg-white rounded p-md-5 p-3 ms-0 ms-md-2 popular-white min-card-height" style={{ boxShadow: '0 0 20px 0 rgb(62 57 107 / 7%), 0 2px 9px 0 rgb(62 57 107 / 6%)' }}>
                                        <div className="d-flex justify-content-between">
                                            {/* <h1 className="fs-4 avn font-bold"><a href="/docs?cat=supportKnowledge" >Knowledge Base &nbsp;<FontAwesomeIcon icon={faLock} /></a></h1> */}
                                            <h1 className="fs-4 avn font-bold"><a onClick={()=>{this.redirectCustomer(Const.ReferenceUrl.knowledgeBaseUrl, true)}} className="selector-pointer">Knowledge Base &nbsp;<FontAwesomeIcon icon={faLock} /></a></h1>
                                            <img className="text-end" src="img/know.png" height={32} /> </div>
                                        <hr />
                                        <div>
                                            <ul className="avn">
                                                {
                                                    latestKBArr.length !== 0 ?
                                                        latestKBArr.map((value) => {
                                                            return (
                                                                <li className="py-2 selector-pointer fw-800" onClick={() => { this.redirectCustomer(value.html_url, value.user_segment_id) }}>{value.title} &nbsp;
                                                                    {value.user_segment_id === null ? '' : <FontAwesomeIcon icon={faLock} />} </li>
                                                            )
                                                        })
                                                        :
                                                        // dummyArr.map((value) => {
                                                        //     return (
                                                        //         <li className="py-2 fw-800"> <a href="#">Adding SAAS Application Tenants </a></li>
                                                        //     )
                                                        // })
                                                        <div className="pt-5"><center><PropagateLoader css={this.override} size={15} color={"#e24402"} loading={true} /></center></div>
                                                }
                                                {/* <li className="py-2"> <a href="#">Adding SAAS Application Tenants </a></li>
                                                <li className="py-2"> <a href="#">Forward and convert your support email </a> s to tickets </li>
                                                <li className="py-2"> <a href="#">Understanding the basics of Agents </a></li>
                                                <li className="py-2"> <a href="#">Understanding the basics of Agents </a></li>
                                                <li className="py-2"> <a href="#">Understanding the basics of Agents </a></li> */}
                                            </ul>
                                            {
                                                latestKBArr.length !==0 && <a onClick={()=>{this.redirectCustomer(Const.ReferenceUrl.knowledgeBaseUrl, true)}} className="text-end d-block selector-pointer">Show More &nbsp;<FontAwesomeIcon icon={faLock} /></a>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-1" />
                            </div>
                        </div>
                    </div>


                    {/* ==== Login Modal ==== */}
                    <LoginModal></LoginModal>

                    {/* ==== Sign up Modal ==== */}
                    <SignupModal></SignupModal>

                    {/* ==== Login Explanatory Modal ====  */}
                    {/* <LoginExplanatoryScreen></LoginExplanatoryScreen> */}

                    {/* ==== Footer Component ==== */}
                    <Footer></Footer>

                </div>
            </React.Fragment >
        )
    }
}

const mapStateToProps = state => ({
    globalDetails: state

})

const mapDispatchToProps = dispatch => ({

    updateAgentLogin: (data) => dispatch(GlobalActions.updateAgentLogin(data)),
    disableJwt: () => dispatch(GlobalActions.disableJwt()),
    
    addRedirect: (data) => dispatch(GlobalActions.addRedirect(data)),
    removeRedirect: (data) => dispatch(GlobalActions.removeRedirect(data)),
    
    updateFilter: (data) => dispatch(GlobalActions.updateFilter(data)),

    addRedirect: (data) => dispatch(GlobalActions.addRedirect(data)),
    removeRedirect: (data) => dispatch(GlobalActions.removeRedirect(data)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home)


// export default Home;