import {
    
    UPDATE_AGENT_LOGIN,
    LOGIN_CUSTOMER,
    VERIFY_AGENT_JIRA,
    LOG_OUT_USER, 
    DISABLE_JWT,
    LOGIN_MODAL_INSTANCE,
    TEST_INIT,
    UPDATE_FILTER,
    LOGIN_AGENT,
    ADD_REDIRECT_LINK,
    REMOVE_REDIRECT_LINK

} from './actionTypes'


export const updateAgentLogin = (data) => {
    return {
        type: UPDATE_AGENT_LOGIN,
        payload: data
    }
}

export const loginCustomer = (data) => {
    console.log(" ###### STARTING LOGINS CUSTOMER ######",data)
    return {
        type: LOGIN_CUSTOMER,
        payload: data
    }
}

export const verifyAgentJira = (data) => {
    return {
        type: VERIFY_AGENT_JIRA,
        payload: data
    }
}

export const logoutUser = (data) => {
    return {
        type: LOG_OUT_USER,
        payload: data
    }
}

export const disableJwt = (data) => {
    return {
        type: DISABLE_JWT,
        payload: data
    }
}

export const loginModalInstance = (data) => {
    return {
        type: LOGIN_MODAL_INSTANCE,
        payload: data
    }
}

export const updateFilter = (data) => {
    return {
        type: UPDATE_FILTER,
        payload: data
    }
}

export const testInit = (data) => {
    // alert("This is amazing!!")
    return {
        type: TEST_INIT,
        payload: data
    }
}

export const loginAgent = (data) => {
    console.log(data,"login agent")
    return {
        type: LOGIN_AGENT,
        payload: data        
    }
}

export const addRedirect = (data) => {
    return {
        type: ADD_REDIRECT_LINK,
        payload: data
    }
}

export const removeRedirect = (data) => {
    return {
        type: REMOVE_REDIRECT_LINK,
        payload: data
    }
}