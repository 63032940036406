import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import { connect, } from "react-redux";
import { compose } from 'redux'; // Import compose

import { Security, LoginCallback } from '@okta/okta-react'
import { OktaAuth } from '@okta/okta-auth-js'
import Home from '../pages/Home'
import SearchPage from '../pages/Search'
import Agreement from '../pages/Agreement'
import EOL from '../pages/EOL'
import Tou from '../pages/Tou'
import NoPage from '../pages/404'
import DocumentTemplate from '../pages/DocumentPage';
import JiraAuthCode from '../pages/jiraCodeAuth'
import ZendeskAuth from '../pages/zendeskCodeAuth'
import Download from '../pages/Download'
import APIPage from '../pages/apiPage'
import DownloadProcess from '../pages/DownloadProcess'

import * as Const from '../constants/constants'

import LogoutAgent from '../pages/logoutPage'
import SupportNotices from '../pages/supportNotices'
import UsersTable from '../pages/supportNotices/Users'
import NotificationList from '../pages/supportNotices/NotificationList'
import NotificationDetails from '../pages/supportNotices/NotificationDetails'


// export default withRouter(
    class MainAppRoute extends React.Component{
        
        constructor(props){
            super(props)
            this.state = {}
            this.onAuthRequired = this.onAuthRequired.bind(this)
        }

        onAuthRequired() {
            this.props.history.push('/')
        }

        jumpToZendeskLogin = () => {
            // https://{subdomain}.zendesk.com/oauth/authorizations/new?response_type=code&redirect_uri={your_redirect_url}&client_id={your_unique_identifier}&scope=read%20write
            let subdomain = Const.zendeskSubDomain
            let redirectUrl = window.location.origin + "/zendeskAuth"
            // let redirectUrl = "http://localhost:3000/zendeskAuth"
            let clientId = Const.zendeskClientId
            let scope = "read"
            window.location.href = `https://${subdomain}.zendesk.com/oauth/authorizations/new?response_type=code&redirect_uri=${redirectUrl}&client_id=${clientId}&scope=${scope}`
            return null
        }
        

        render() {
console.log(this.props.user.userId,'...new props')
            const oktaAuth = new OktaAuth({
                // issuer:'https://dev-6271244.okta.com/oauth2/default',
                issuer:'https://aviatrix.okta.com/oauth2/default',
                clientId: Const.oktaClientId,    // ==== Client Id for local env ====
                redirectUri:window.location.origin + '/login/callback',

                // ==== Adding global login success ====
                // clientId: "0oa3a83u7zcaoSgYi5d6",
                // clientId: "0oa9mb13cJCyMC7jx5d6"     // ==== Client Id for Stage env ====
                // redirectUri: "http://aviatrix-dev-web.s3-website-us-east-1.amazonaws.com" + "/login/callback",
            });

            return(

                <Security
                    onAuthRequired={this.onAuthRequired}
                    oktaAuth = {oktaAuth} >
                    <Switch>
                                                
                    <Route path="/" exact={true} component={Home}></Route>                
                    <Route path='/login/callback' component={LoginCallback} />
                    <Route path="/search" component={SearchPage}></Route>
                    <Route path="/sla" component={Agreement}></Route>
                    <Route path="/Aviatrix-EOL-Policy" component={EOL}></Route>
                    <Route path="/service-descriptions" component={Tou}></Route>
                    <Route path="/downloads" component={Download}></Route>
                    <Route path="/download-process" component={DownloadProcess}></Route>
                    <Route path="/apiDownloads" component={APIPage}></Route>
                    <Route path="/docs" component={DocumentTemplate}></Route>
                    <Route path="/code" component={JiraAuthCode} />
                    <Route path="/jiraAuth" component={()=>{
                        window.location.href = Const.jiraAuthenticationUrl;
                        return null
                    }} />

                    <Route path="/zendeskAuth" component={ZendeskAuth} />
                    <Route path="/zendeskInitAuth" component={this.jumpToZendeskLogin} />
                    <Route path="/logoutAgent" component={LogoutAgent} />
                    {
                        this.props?.user?.userId &&
                    <Route path="/support-notices" component={SupportNotices} />
                    }
                    {
                        this.props?.user?.userId &&
                        <Route path="/users" component={UsersTable} />
                    }
                    {
                         this.props?.user?.userId &&
                         <Route path="/notifications" component={NotificationList} />
                        }
                    {
                        this.props?.user?.userId &&
                        <Route path="/notification-details" component={NotificationDetails} />

                    }
                    {/* <Route path="/create-notices" component={Crea} /> */}
                    <Route path="*" component={NoPage} />
                    </Switch>

                    

                </Security>
            )
        }

    }
// )
const mapStateToProps = (storeState) => {
    return {
      user: storeState,
    };
  };

// export default withRouter(MainAppRoute);

// Use compose to connect to Redux and withRouter in one line
export default compose(
    connect(mapStateToProps, null),
    withRouter
  )(MainAppRoute);