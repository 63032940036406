import logo from './logo.svg';
import './App.css';
import Home from './pages/Home'
import MainHome from './route/mainAppRoute'
import SearchPage from './pages/Search'

import { BrowserRouter as Router} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'

function App() {
  return (
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
    // <Home></Home>
    <Router>
      <MainHome/>
    </Router>
    // <MainHome></MainHome>
    // <SearchPage></SearchPage>
  );
}

export default App;
