// ==== Libraries used ====
import React from 'react'
import ls from 'local-storage'
import {Redirect} from 'react-router-dom'
import { connect } from 'react-redux';

// ==== Local modules used ====
import * as PrimaryService from '../services/service'
import * as GlobalActions from '../store/actions'

import * as UserLogout from '../serviceHelper/processLogout'


class JiraCodeAuth extends React.Component{
    
    constructor(props){
        super(props)
        this.state = {
            codeValue: null,
            codeApproved: false
        }
    }

    componentDidMount(){
        // console.log("Redirect URL : ", this.props.globalDetails.redirectLink)
        // debugger;
        let paramsUrl = new URLSearchParams(this.props.location.search)
        let errorValue = paramsUrl.get("error")
        let codeValue = paramsUrl.get("code")
        let codeParams = {
            code: codeValue
        }
        if(errorValue !== null && errorValue !== undefined){
            UserLogout.forceLogout()
        }
        else if(codeValue !== null && codeValue !== undefined){
            this.AuthenticateUser(codeParams)
        }
        
    }


    AuthenticateUser = (codeParams) => {
        PrimaryService.AuthenticateJiraCode(codeParams).then((result)=>{
            // ls.set("jiraTokenDetails", result.data.jiraAuth) 
            // ls.set("jiraToken", result.data.jiraAuth.access_token)
            // ls.set("agentLogSuccess", true)
            this.props.verifyAgentJira(true)
            //    debugger;
            this.setState({
                codeApproved: true
            })
        }).catch((err)=>{
            console.log(err)
        })
    }
    

    render(){
        let {codeApproved } = this.state
        return(
            codeApproved ? 
                <Redirect to="/" />
                :
                <React.Fragment>

                </React.Fragment>
        )
    }
}


const mapStateToProps = state => ({
    globalDetails : state

})

const mapDispatchToProps = dispatch => ({
    
    updateAgentLogin: (data) => dispatch(GlobalActions.updateAgentLogin(true)),
    loginCustomer: (data) => dispatch(GlobalActions.loginCustomer(data)),
    verifyAgentJira: (data) => dispatch(GlobalActions.verifyAgentJira(data))
    
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(JiraCodeAuth)

// export default JiraCodeAuth;