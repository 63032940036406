import React from 'react'
import { Link } from 'react-router-dom'
import ls from 'local-storage'
import { connect } from 'react-redux';
// import JSEncrypt from 'jsencrypt'
import img from '../'
import { css } from "@emotion/core";



class DownloadProcess extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            enableView: false,
            collections: [],

            searchInProgress: true
        }
        this.override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
        `;
    }

    componentDidMount() {

    }



   



    render() {
        let {enableView, collections, images, searchInProgress} = this.state
        return (
            <React.Fragment>
                <div className="min-height-full">
                   
                   <img src="/img/downloadProcess.png" ></img>

                </div>
            </React.Fragment >
        )
    }
}



// export default Download

const mapStateToProps = state => ({
    globalDetails: state

})

const mapDispatchToProps = dispatch => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DownloadProcess)




// export default Home;