function truncate(str, n) {
    if(str.length <= n ){
        return str
    }else{
        let subStr = str.substring(0, n)
        let wordBoundary = subStr.substring(0, subStr.lastIndexOf(" ")) + "..."
        return wordBoundary
    }
    
}

export default truncate;