import React from "react";
import * as msal from "@azure/msal-browser";

// import styles from "./home.module.css";
// import { Button } from "@mui/material";
// import AzureLogo from "../../assets/azure-logo.png";
import { msalConfig, scopes, homeAccountId, MsalInstanceSingleton,  } from "../config";

import * as PrimaryService from '../services/service'
import { useMsal } from "@azure/msal-react";

const msalInstance = new MsalInstanceSingleton();
const AzureADLogin =  ({onSuccess,onError}) => {

    const { instance, accounts, inProgress } = useMsal();


    // console.log(localStorage.getItem('Azure_AD'),"...")



    // const [ isAuthenticated, setIsAuthenticated ] = React.useState(localStorage.getItem('Azure_AD')||false);
    const [ isAuthenticated, setIsAuthenticated ] = React.useState(false);
    // const [ user, setUser ] = useState({});
    // const [ error, setError ] = useState(null);

    // const msalInstance = new msal.PublicClientApplication(msalConfig);
    
    
    
    
    const login = async () => {
        try {
            // Account selection logic is app dependent. Adjust as needed for different use cases.
// Set active acccount on page load
// const accounts = msalInstance.getAllAccounts();
// if (accounts.length > 0) {
//   msalInstance.setActiveAccount(accounts[0]);
// }

// msalInstance.addEventCallback((event) => {
//     // set active account after redirect
//     if (event?.payload?.account) {
//       console.log(event)
//     const account = event.payload.account;
//     msalInstance.setActiveAccount(account);
//   }
// }, error=>{
//   console.log('error', error);
// });

// // console.log('get active account', msalInstance.getActiveAccount());

// // handle auth redired/do all initial setup for msal
// msalInstance.handleRedirectPromise().then(authResult=>{
//   // Check if user signed in 
//   const account = msalInstance.getActiveAccount();
//   console.log(account,'promise')
//   if(!account){
//     // redirect anonymous user to login page 
//     msalInstance.loginRedirect();
//   }
// }).catch(err=>{
//   // TODO: Handle errors
//   console.log(err);
// });
            // await msalInstance.initialize();
        //   await  msalInstance["browserStorage"].clear();

        //     await msalInstance.loginPopup({
        //         scopes: scopes,
        //         prompt: 'select_account'
        //     }).then((response) => {
        //       console.log(response,"...response")
        //     //   localStorage.setItem('Azure_AD',response.accessToken)
        //         onSuccess(response)
        //         AuthenticateUser(response?.accessToken)
        //         setIsAuthenticated(true);
        //         // setError(null);
        //     });

        instance.loginPopup()
        .then((response) => {
                  console.log(response,"...response new")
                //   localStorage.setItem('Azure_AD',response.accessToken)
                    onSuccess(response)
                    AuthenticateUser(response?.accessToken)
                    setIsAuthenticated(true);
                    // setError(null);
                });
        } catch(err) {
            console.log(err,"..err")
            onError()
            setIsAuthenticated(false);
            // setUser({});
            // setError(err);
        }
    }

    const logout = async () => {
        localStorage.removeItem('Azure_AD');
        sessionStorage.removeItem('msal.interaction.status');
        msalInstance.logoutPopup(homeAccountId);
        
        // const currentAccount = msalInstance.getAccountByHomeId(homeAccountId);
        // await msalInstance.logoutRedirect({
        //     account: currentAccount,
        //     postLogoutRedirectUri: 'http://localhost:3000',

        // })
        setIsAuthenticated(false)
    }

  function  AuthenticateUser (codeParams)  {
        PrimaryService.AuthenticateAzureADCode(codeParams).then((result)=>{
            console.log(result,"...suc AZURE")
            // ls.set("jiraTokenDetails", result.data.jiraAuth) 
            // ls.set("jiraToken", result.data.jiraAuth.access_token)
            // ls.set("agentLogSuccess", true)
            // this.props.verifyAgentJira(true)
            // //    debugger;
            // this.setState({
            //     codeApproved: true
            // })
            setIsAuthenticated(true)
        }).catch((err)=>{
            console.log(err,"Err azure")
        })
    }
    

    return (
        <div className={''}>
            {/* <img className={''} src={AzureLogo} alt="azure-logo" /> */}
            {
                isAuthenticated ? (
                    <>
                        <p className={""}>Successfully logged in</p>
                        <button className={""}   onClick={logout}>Logout</button>
                    </>
                ) : (
                    <>
                        <button className={''} 
                        style={{
                            background: '#0078D4',
                            color: 'white',
                            border: 'none',
                            padding: '20px 30px',
                            borderRadius: '8px',
                            fontSize: '18px',
                            fontWeight: 700,
    }}  onClick={login}>Login with Azure AD</button>
                    </>
                )
            }
        </div>
    );
}

export default AzureADLogin;