import API from './interceptor'

// ==== Sign up user ====
export function createNotice(body){
    return API ({
        method: "POST",
        url: "api/noticesModule/notice",
        data: JSON.stringify(body),
        withCredentials: true
    })
}
export function getAllNotices(body){
    return API ({
        method: "GET",
        url: "api/noticesModule/notice",
        // data: JSON.stringify(body),
        withCredentials: true
    })
}
export function getNoticeDetail(id){
    return API ({
        method: "GET",
        url: "api/noticesModule/notice-id",
        // data: JSON.stringify(body),
        params:id,
        withCredentials: true
    })
}
export function updateSeenByUser(body){
    console.log(body,'..body')
    return API ({
        method: "POST",
        url: "api/noticesModule/seen-by-users",
        data: JSON.stringify(body),
        withCredentials: true
    })
}
export function getSeenByUsers(userIds){
    console.log(userIds,'..userIds')
    return API ({
        method: "GET",
        url: "api/noticesModule/seen-by-users",
        params:userIds,
        withCredentials: true
    })
}


export function deleteNotice(body){
    return API ({
        method: "POST",
        url: "api/noticesModule/notice-delete",
        data: JSON.stringify(body),
        withCredentials: true
    })
}