import * as React from 'react';
import { styled } from '@mui/material/styles';
import {Icon, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,Pagination, Stack, Tooltip } from "@mui/material";
import Admin from '../../components/Admin';
import Header from '../../components/header';
import Footer from '../../components/footer';
import {  getSeenByUsers } from '../../services/noticesService';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import Loader from '../../components/Loader';
import "./style.css"


const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}


export default function UsersTable({setOpenUpdate}) {
  
  const params = useLocation()
  
  const [users,setusers] = React.useState([])
  const [loading,setLoading] = React.useState(false)

  React.useEffect(()=>{
    getUsersList()
  },[setusers])
  
  const getUsersList =()=>{
    setLoading(true)
    getSeenByUsers({userIds:params?.state?.userIDs}).then((res)=>{
      console.log(res)
      setusers(res?.data?.users);
      setLoading(false)
    }).catch((err)=>setLoading(false))

  }
  return (
    <>
    <Header parent='homePage'/>
     <div style={{display:"flex",justifyContent:'flex-end',padding:'20px 30px'}}>
        <Admin/>
            {/* </IconButton> */}
        </div>
<h2 className='users-heading'>Users</h2>
    <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',padding:'0px 30px'}}>

{
  loading ? 
<Loader/> : !users?.length ? <p>No Users found!</p>: 

    <TableContainer component={Paper} style={{width:'100%',textAlign:'center'}}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell className='user-table-cells' >User ID</TableCell>
            <TableCell className='user-table-cells' >User Name</TableCell>
            <TableCell className='user-table-cells'  align="left">User Email</TableCell>
            <TableCell className='user-table-cells' align="left">Status</TableCell>
            <TableCell className='user-table-cells' align="left">Role</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
          users.map((row) => (
            <StyledTableRow key={row?.User_ID}>
              <TableCell align="left">{row?.User_ID}</TableCell>
              <TableCell align='left'  component="th" scope="row">
                {row?.name}</TableCell>
              <TableCell align="left">{row?.email}</TableCell>
              <TableCell align="left">{'Read'}</TableCell>
              <TableCell align="left">{row?.role}</TableCell>
            </StyledTableRow>
          ))
          
          }
        </TableBody>
      </Table>
    </TableContainer>
}
    </div>
            <Footer/>
              </>

              
  );
}
