import React from 'react'
import { connect } from 'react-redux';
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";

import * as GlobalActions from '../store/actions'
import * as PrimaryService from '../services/service'
import * as Const from '../constants/constants'

class DocNavigationMenu extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            expandedMenuItemsList: [],
            sectionArr: [],
            articleMap: {},
            activeSection: null,
            activeArticle: null,

            activeCategory: null,
            activeTitle: "Tech Hub"
        }
        this.override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
        `;
    }

    componentDidMount() {

        console.log(window.location)
        console.log(window.location.search)

        this.defineCategory()


        // let supportKnowledgeCategory = {
        //     categoryId: "360004231251"
        // }
        // PrimaryService.getArticleDetails(supportKnowledgeCategory).then((result) => {
        //     this.setState({
        //         sectionArr: result.data.section["sections"],
        //         articleMap: result.data.articleMap
        //     }, () => {
        //         this.initialLoad()
        //     })
        // })
    }

    defineCategory = () => {
        let activeLocalCategory = Const.techHubCategory
        let activeLocalCategoryName = "Tech Hub"

        this.setState({
            activeCategory: activeLocalCategory,
            activeTitle: activeLocalCategoryName
        }, () => {
            this.fetchCategoryDetails()
        })
    }

    fetchCategoryDetails = () => {
        let categoryDetails = {
            categoryId: this.state.activeCategory
        }
        PrimaryService.getArticleDetails(categoryDetails).then((result) => {
            this.setState({
                sectionArr: result.data.section["sections"],
                articleMap: result.data.articleMap
            }, () => {
                this.initialLoad()
            })
        })
    }

    externalFetchCategoryId = () => {
        console.log("Returning from external", this.state.activeCategory)
        return this.state.activeCategory
    }


    // ==== Initial load helps to load the first article and expand the index when the page initially loads. ====
    initialLoad = () => {
        let firstSection = this.state.sectionArr[0]
        let firstArticle = this.state.articleMap

        let firstArticleData = firstArticle[firstSection.id].articles[0]
        this.selectArticle(firstArticleData.id, firstSection.id, firstArticleData)
        this.expandMenuItem(firstSection.id)
    }


    // ==== This method helps us to load an article when selected from the search options ====
    redirectLoad = (articleId, sectionId, articleDetails) => {
        this.selectArticle(articleId, sectionId, articleDetails)
        this.expandMenuItem(sectionId, true)
    }


    expandMenuItem = (indexPosition, redirectionCall = false) => {
        let menuItemsList = []
        if (this.state.expandedMenuItemsList.indexOf(indexPosition) == -1) {
            menuItemsList = [...this.state.expandedMenuItemsList, indexPosition];
        } else if (redirectionCall) {
            menuItemsList = this.state.expandedMenuItemsList
        } else {
            menuItemsList = this.state.expandedMenuItemsList.filter((index) => {
                return index !== indexPosition
            })
        }
        this.setState({ expandedMenuItemsList: menuItemsList });
    }

    selectArticle = (articleId, sectionId, articleDetails) => {
        this.setState({
            activeArticle: articleId,
            activeSection: sectionId
        }, () => {
            this.props.openArticle(articleDetails.body, articleDetails.title)
        })
    }

    render() {
        let { sectionArr, articleMap, activeSection, activeArticle } = this.state

        return (
            <React.Fragment>
                <div className={`doc-sidebar ${this.props.isOpen ? '' : 'hide-on-mobile'}`}>
                    <h6 className="mb-5">{this.state.activeTitle}</h6>
                    <span className="doc-ham-close show-on-mobile" onClick={() => { this.props.toggleIndexMenu(false) }}>X</span>
                    {/* ==== Trial List ==== */}
                    {
                        sectionArr.length !== 0 ?
                            sectionArr.map((value) => {
                                return (
                                    <ul className="doc-menu-ul">
                                        <h6 className={activeSection === value.id ? "active" : ""} onClick={() => { this.expandMenuItem(value.id) }}>{value.name}  <img id="inputImg" src="./img/down-arrow-active.png" /></h6>
                                        <div className="dropdown-links-wrapper" >
                                            {
                                                this.state.expandedMenuItemsList.indexOf(value.id) !== -1 ? (
                                                    articleMap[value.id].articles.map((articleValue) => {
                                                        return (
                                                            <li className={activeArticle === articleValue.id ? "active" : ""} onClick={() => { this.selectArticle(articleValue.id, value.id, articleValue) }} >
                                                                {articleValue.title}
                                                            </li>
                                                        )
                                                    })
                                                ) : null
                                            }
                                        </div>
                                    </ul>
                                )
                            })

                            :
                            
                            <center><PropagateLoader css={this.override} size={10} color={"#e24402"} loading={true} /></center>

                    }
                    {/* ==== Trial List ends ==== */}


                    {/* <ul className="doc-menu-ul">
                        <h6 className="active" onClick={() => { this.expandMenuItem(1) }}>Getting Started  <img id="inputImg" src="./img/down-arrow-active.png" /></h6>
                        {
                            this.state.expandedMenuItemsList.indexOf(1) !== -1 ? (
                                <div className="dropdown-links-wrapper" >
                                    <li className="active">
                                        <span> -- </span>  Avaitrix Overview
                                            </li>
                                    <li>
                                        AWS Startup guide
                                            </li>
                                    <li>
                                        Azure Startup guide
                                            </li>
                                    <li>
                                        Oracle Cloud Infrastructure (OCI)
                                        Startup Guide
                                            </li>
                                    <li>
                                        Google Startup guide
                                            </li>
                                </div>
                            ) : null
                        }
                    </ul>

                    <ul className="doc-menu-ul">
                        <h6 onClick={() => { this.expandMenuItem(2) }}>Onboarding and Accounts <img id="inputImg" src="./img/down-arrow-active.png" /></h6>
                        {
                            this.state.expandedMenuItemsList.indexOf(2) !== -1 ? (
                                <div className="dropdown-links-wrapper">
                                    <li>
                                        Avaitrix Overview
                                            </li>
                                    <li>
                                        AWS Startup guide
                                            </li>
                                    <li>
                                        Azure Startup guide
                                            </li>
                                    <li>
                                        Oracle Cloud Infrastructure (OCI)
                                        Startup Guide
                                            </li>
                                    <li>
                                        Google Startup guide
                                            </li>
                                </div>
                            ) : null
                        }
                    </ul>

                    <ul className="doc-menu-ul">
                        <h6 onClick={() => { this.expandMenuItem(3) }}>Gateway   <img id="inputImg" src="./img/down-arrow-active.png" /></h6>
                        {
                            this.state.expandedMenuItemsList.indexOf(3) !== -1 ? (
                                <div className="dropdown-links-wrapper">
                                    <li>
                                        Avaitrix Overview
                                            </li>
                                    <li>
                                        AWS Startup guide
                                            </li>
                                    <li>
                                        Azure Startup guide
                                            </li>
                                    <li>
                                        Oracle Cloud Infrastructure (OCI)
                                        Startup Guide
                                            </li>
                                    <li>
                                        Google Startup guide
                                            </li>
                                </div>
                            ) : null
                        }
                    </ul>

                    <ul className="doc-menu-ul">
                        <h6 className="active" onClick={() => { this.expandMenuItem(4) }}>Getting Started  <img id="inputImg" src="./img/down-arrow-active.png" /></h6>
                        {
                            this.state.expandedMenuItemsList.indexOf(4) !== -1 ? (
                                <div className="dropdown-links-wrapper" >
                                    <li className="active">
                                        <span> -- </span>  Avaitrix Overview
                                            </li>
                                    <li>
                                        AWS Startup guide
                                            </li>
                                    <li>
                                        Azure Startup guide
                                            </li>
                                    <li>
                                        Oracle Cloud Infrastructure (OCI)
                                        Startup Guide
                                            </li>
                                    <li>
                                        Google Startup guide
                                            </li>
                                </div>
                            ) : null
                        }
                    </ul>

                    <ul className="doc-menu-ul">
                        <h6 onClick={() => { this.expandMenuItem(5) }}>Onboarding and Accounts <img id="inputImg" src="./img/down-arrow-active.png" /></h6>
                        {
                            this.state.expandedMenuItemsList.indexOf(5) !== -1 ? (
                                <div className="dropdown-links-wrapper">
                                    <li>
                                        Avaitrix Overview
                                            </li>
                                    <li>
                                        AWS Startup guide
                                            </li>
                                    <li>
                                        Azure Startup guide
                                            </li>
                                    <li>
                                        Oracle Cloud Infrastructure (OCI)
                                        Startup Guide
                                            </li>
                                    <li>
                                        Google Startup guide
                                            </li>
                                </div>
                            ) : null
                        }
                    </ul>

                    <ul className="doc-menu-ul">
                        <h6 onClick={() => { this.expandMenuItem(6) }}>Gateway   <img id="inputImg" src="./img/down-arrow-active.png" /></h6>
                        {
                            this.state.expandedMenuItemsList.indexOf(6) !== -1 ? (
                                <div className="dropdown-links-wrapper">
                                    <li>
                                        Avaitrix Overview
                                            </li>
                                    <li>
                                        AWS Startup guide
                                            </li>
                                    <li>
                                        Azure Startup guide
                                            </li>
                                    <li>
                                        Oracle Cloud Infrastructure (OCI)
                                        Startup Guide
                                            </li>
                                    <li>
                                        Google Startup guide
                                            </li>
                                </div>
                            ) : null
                        }
                    </ul> */}


                </div>
            </React.Fragment>
        )
    }
}

export default DocNavigationMenu
