import API from './interceptor'



export function searchAnon(searchParams, dataBody){
    return API({
        // method: "GET",
        method: "POST",
        url: "api/aggregator/search",
        params: searchParams,
        data: JSON.stringify(dataBody),
        withCredentials: true
    })
}


export function authZendeskUser(userDetails){
    return API({
        method: "POST",
        url: "api/zendesk/authenticate",
        withCredentials: true,
        data: JSON.stringify(userDetails)
    })
}

export function searchCustomer(searchParam, dataBody){
    return API({
        method: "POST",
        url: "api/aggregator/customerSearch",
        params: searchParam,
        data: JSON.stringify(dataBody),
        withCredentials: true,
    })
}


export function AuthenticateJiraCode(codeParam){
    return API({
        method: "GET",
        url: "api/jira/authenticateCode",
        params: codeParam,
        withCredentials: true,
    })
}

export function AuthenticateAzureADCode(codeParam){
    return API({
        method: "GET",
        url: "api/azureAD/authenticateCode",
        params: codeParam,
        withCredentials: true,
    })
}

export function logoutAzureAD(codeParam){
    return API({
        method: "GET",
        url: "api/azureAD/logoutAzureAD",
        // params: codeParam,
        withCredentials: true,
    })
}

export function searchAgent(searchParam, dataBody){
    return API({
        method: "POST",
        url: "api/aggregator/agentSearch",
        params: searchParam,
        data: JSON.stringify(dataBody),
        withCredentials: true,
    })
}


// ==== Logout Methods ====

export function logoutCustomer(){
    return API({
        method: "POST",
        url: "api/aggregator/logoutCustomer",
        withCredentials: true
    })
}

export function logoutAgent() {
    return API ({
        method: "POST",
        url: "api/aggregator/logoutAgent",
        withCredentials: true
    })
}

// ==== KB and Suport knowledge based ====
export function getLatestKB() {
    return API ({
        method: "GET",
        url: "/api/aggregator/latestKB",
        withCredentials: true
    })
}


export function getArticleDetails(categoryParam) {
    return API({
        method: "GET",
        url: "/api/zendesk/getArticleDetails",
        params: categoryParam,
        withCredentials: true
    })
}

export function searchArticlesByCategory(searchParams){
    return API ({
        method: "GET",
        url: "/api/zendesk/searchArticlesByCategory",
        params: searchParams,
        withCredentials: true
    })
}



// ==== Sign up user ====
export function signUpUser(userBody){
    return API ({
        method: "POST",
        url: "/api/zendesk/createUser",
        data: JSON.stringify(userBody),
        withCredentials: true
    })
}


// ==== Service method for Widget tokens ====
export function getWidgetToken(userDetails){
    return API ({
        method: "GET",
        url: "/api/zendesk/widgetToken",
        params: userDetails,
        withCredentials: true
    })
}




// ==== APIs for Individual pages for Services ====
export function getGitPage(searchParams) {
    return API({
        method: "GET",
        url: '/api/git/searchGit',
        params: searchParams,
        withCredentials: true
    })
    
}

export function getKbPage(searchParams) {
    return API({
        method: "GET",
        url: "/api/zendesk/searchPagedKb",
        params: searchParams,
        withCredentials: true
    })
}

export function getRequestPage(searchParams) {
    return API({
        method: "GET",
        url: "/api/zendesk/searchPagedRequests",
        params: searchParams,
        withCredentials: true
    })
}

export function getTicketPage(searchParams) {
    return API({
        method: "GET",
        url: "/api/zendesk/searchPagedTickets",
        params: searchParams,
        withCredentials: true
    })
}

export function getCommunityPage(searchParams) {
    return API({
        method: "GET",
        url: "/api/forumbee/searchPageCommunity",
        params: searchParams,
        withCredentials: true
    })
}

export function getIssuePage(searchParams) {
    return API({
        method: "GET",
        url: "/api/jira/searchPagedIssue",
        params: searchParams,
        withCredentials: true
    })
}






// ===== Secondary trial Services ====

export function authCodeGrant(codeParam){
    return API({
        method: "POST",
        url: "/api/zendesk/authCodeGrant",
        params: codeParam,
        withCredentials: true
    })
}





// ==== Download Services ====

export function getDownloadImageList(userData) {
    return API({
        method: "POST",
        url: "/api/downloads/list-image-files",
        withCredentials: true,
        data: userData
    })
}


export function getDownloadCollectionList(){
    return API({
        method: "POST",
        url: "/api/downloads/list-api-files",
        withCredentials: true
    })
}


export function downloadFile(fileDetails) {
    return API({
        method: "POST",
        url: "/api/downloads/download",
        withCredentials: true,
        data: fileDetails
    })
}