import React from 'react'
import { Link } from 'react-router-dom'
import ls from 'local-storage'
import { connect } from 'react-redux';
// import JSEncrypt from 'jsencrypt'


import Header from '../components/header'
import Footer from '../components/footer'
import LoginModal from '../components/loginModal'
import SignupModal from '../components/signupModal'

import * as PrimaryServices from '../services/service'
import * as GlobalActions from '../store/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import * as SetWidget from '../operationalHelper/populateWidget'

import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";
import { Form, FormGroup, Label, Input, FormText,Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Button } from 'reactstrap';
 const   Countries_List = [
        "Afghanistan",
        "Aland Islands",
        "Albania",
        "Algeria",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antarctica",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia, Plurinational State of",
        "Bonaire, Sint Eustatius and Saba",
        "Bosnia and Herzegovina",
        "Botswana",
        "Bouvet Island",
        "Brazil",
        "British Indian Ocean Territory",
        "Brunei Darussalam",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Cape Verde",
        "Cayman Islands",
        "Central African Republic",
        "Chad",
        "Chile",
        "China",
        "Christmas Island",
        "Cocos (Keeling) Islands",
        "Colombia",
        "Comoros",
        "Congo",
        "Congo, the Democratic Republic of the",
        "Cook Islands",
        "Costa Rica",
        "Cote d’Ivoire",
        "Croatia",
        "Cuba",
        "Curaçao",
        "Cyprus",
        "Czech Republic",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Ethiopia",
        "Falkland Islands (Malvinas)",
        "Faroe Islands",
        "Fiji",
        "Finland",
        "France",
        "French Guiana",
        "French Polynesia",
        "French Southern Territories",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Greenland",
        "Grenada",
        "Guadeloupe",
        "Guatemala",
        "Guernsey",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Heard Island and McDonald Islands",
        "Holy See (Vatican City State)",
        "Honduras",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Isle of Man",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jersey",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "North Korea",
        "South Korea",
        "Kuwait",
        "Kyrgyzstan",
        "Lao People’s Democratic Republic",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macao",
        "Macedonia, the former Yugoslav Republic of",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Martinique",
        "Mauritania",
        "Mauritius",
        "Mayotte",
        "Mexico",
        "Moldova, Republic of",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Niue",
        "Norfolk Island",
        "Norway",
        "Oman",
        "Pakistan",
        "Palestine",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Pitcairn",
        "Poland",
        "Portugal",
        "Qatar",
        "Reunion",
        "Romania",
        "Russia",
        "Rwanda",
        "Saint Barthélemy",
        "Saint Helena, Ascension and Tristan da Cunha",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Martin (French part)",
        "Saint Pierre and Miquelon",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Sint Maarten (Dutch part)",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Georgia and the South Sandwich Islands",
        "South Sudan",
        "Spain",
        "Sri Lanka",
        "Sudan",
        "Suriname",
        "Svalbard and Jan Mayen",
        "Swaziland",
        "Sweden",
        "Switzerland",
        "Syria",
        "Taiwan",
        "Tajikistan",
        "Tanzania, United Republic of",
        "Thailand",
        "Timor-Leste",
        "Togo",
        "Tokelau",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks and Caicos Islands",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom",
        // "United States",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Venezuela",
        "Vietnam",
        "Virgin Islands, British",
        "Wallis and Futuna",
        "Western Sahara",
        "Yemen",
        "Zambia",
        "Zimbabwe"
    ]
const embargoed=['North Korea', 'Cuba', 'Iran', 'Syria', 'Venezuela', 'Russia']


class Download extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            stage1:false,
            stage2:false,
            stage3: false,
            selectedCountry: '',
            deploymentAddress1: '',
            deploymentAddress2: '',
            companyAddress1: '',
            companyAddress2: '',
            deploymentCountry: '',
            deploymentState:'',
            companyCountry: '',
            companyState:'',
            countries: Countries_List,
            countryError: '',
            images: [],
            collections: [],
            imagesMap:{},
            searchInProgress: true
        }
        this.override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
        `;
    }


    
    
      handleForm = (event) => {
        this.setState({ [event.target.name]: event.target.value });
      };
    
      handleCountryChange = (event) => {
        this.setState({ country: event.target.value });
      };
    
      handleSubmit = (event) => {
        event.preventDefault();
    
        const { companyAddress1,companyAddress2,companyCountry,deploymentAddress1,deploymentAddress2,deploymentCountry } = this.state;
        
       

       let Err = (embargoed.indexOf(deploymentCountry)!=-1)||(embargoed.indexOf(companyCountry)!=-1)
        if(Err)
        {
            this.setState({ countryError: 'Aviatrix Edge Image is not permitted to be deployed in country selected as it is an Embargoed Country of the United States. Please contact Aviatrix Sales if assistance is needed.' });
          return;
        }
        let userDetails = {
            userId: this.props.globalDetails.userId
        }
        this.setState({searchInProgress: true,})
        PrimaryServices.getDownloadImageList(userDetails).then((result) => {
                console.log(result)
                this.setState({
                    images: result.data.imageFiles,
                    searchInProgress: false,
                    imagesMap:result.data.imageFiles.reduce((a, v) => ({ ...a, [v]: v}), {}) 
                })
                
            }).catch((err)=>{
                console.log(err)
            })
    
        this.setState({stage2:false,stage3:true})
        // Perform action with the email and country (e.g., send to server, etc.)
      };

      handleStage1 = ()=>
      {
        if(this.state.selectedCountry==='US')
        {
            let userDetails = {
                userId: this.props.globalDetails.userId
            }
            this.setState({searchInProgress: true,})

            PrimaryServices.getDownloadImageList(userDetails).then((result) => {
                console.log(result)
                this.setState({
                    images: result.data.imageFiles,
                    stage3: true,
                    searchInProgress: false,
                    imagesMap:result.data.imageFiles.reduce((a, v) => ({ ...a, [v]: v}), {}) 
                })
            }).catch((err)=>{
                console.log(err)
            })
            this.setState({stage1:false,stage3:true})
        }
        else
        this.setState({stage1:false,stage2:true})
    }
    componentDidMount() {
        if(this.props.globalDetails.signState){
            // let userDetails = {
            //     userId: this.props.globalDetails.userId
            // }
            // PrimaryServices.getDownloadImageList(userDetails).then((result) => {
            //     console.log(result)
            //     this.setState({
            //         images: result.data.imageFiles,
            //         stage3: true,
            //         searchInProgress: false,
            //     })
            // }).catch((err)=>{
            //     console.log(err)
            // })
            this.setState({stage1:true,searchInProgress:false})
        }else{
            this.setState({
                searchInProgress: false
            })
        }
        SetWidget.populateWidget()
    }



    downloadFile = (fileType, fileName) => {
        let mainFileType = ""
        if(fileType === "Postman"){
            mainFileType = "collectionBucket"
        }else{
            mainFileType = "imageBucket"
        }
        
        let userDetails = {
            user: this.props.globalDetails.userName,
            email: this.props.globalDetails.userEmail,
            deploymentCountry: this.state.selectedCountry==='US'?'United States':this.state.deploymentCountry,
            fileType: fileType,
            fileName: fileName
        }
        if(this.state.selectedCountry!='US')
        {
            userDetails = {...userDetails,
            companyCountry:this.state.companyCountry,
            deploymentAddress1:this.state.deploymentAddress1,
            deploymentAddress2: this.state.deploymentAddress2,
            deployementState:this.state.deploymentState,

            companyAddress1:this.state.companyAddress1,
            companyAddress2: this.state.companyAddress2,
            companyState:this.state.companyState,
            }
        }
        

        let fileDetails = {
            bucket: mainFileType,
            key: fileName,
            userDetails: userDetails
        }

        PrimaryServices.downloadFile(fileDetails).then((result)=>{
            console.log(result)
            window.open(result.data.url, "_blank")
        }).catch((err)=>{
            console.log(err)
        })
    }
    handleChange = (event) => {
        this.setState({ selectedCountry: event.target.value });
      };



    render() {
        let {stage1,stage2,stage3,selectedCountry, collections, images,imagesMap, searchInProgress,companyAddress1,companyAddress2,companyCountry,companyState,deploymentState,deploymentAddress1,deploymentAddress2,deploymentCountry, countries,countryError} = this.state
        return (
            <React.Fragment>
                <div className="min-height-full">
                    {/* ==== Header for Page ==== */}
                    <Header parent="SLA"></Header>
                    <br />
                    <br />
                    <br className="hide-on-mobile"/>
                    <br className="hide-on-mobile"/>
                    {/* <br className="hide-on-mobile"/>  */}
                    <div className="container width-wrapper pt-5 p-md-b-5" style={{fontFamily:'Avenir'}}>
                        <div className="row">
                        {!stage3 ?<h2 className="m-0 p-0 fs-8"><strong style={{fontFamily:'Interstate'}}>Aviatrix Edge Image Downloads</strong></h2>: <>Download Aviatrix Edge Image based on the Platform being used AND Aviatrix Controller Version</>}                     
                        </div>

                        <br></br>
                        {
                            searchInProgress ?
                                <center>
                                    <center><PropagateLoader css={this.override} size={20} color={"#e24402"} loading={true} /></center>
                                </center>
                                :
                                ''
                        }

                        {/*  ==== Anonymous user ==== */}
                        {
                            this.props.globalDetails.signState === false && 
                                <p >Please login to view and download Aviatrix Edge Images.</p>
                        }
                        {/*  ==== Stage  1 ==== */}
                        {
                            stage1 && this.props.globalDetails.signState&&
                            <div>
                                 <Form onSubmit={this.handleStage1}>
                                <Card>
                                    <CardBody>
                                    <CardTitle><legend >Is Aviatrix Edge being deployed in United States?</legend></CardTitle>
                                    <CardSubtitle></CardSubtitle>
                                    <CardText>
                                        <br/>
                                       
                                        <FormGroup >
                                        <FormGroup check>
                                            <Label check>
                                            <Input type="radio" 
                                            value="US" required
                                            checked={selectedCountry === 'US'}
                                            onChange={this.handleChange}

                                            name="radio1" />{' '}
                                            Yes
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Label check>
                                            <Input type="radio" required
                                            value="Outside"

                                             checked={selectedCountry === 'Outside'}
                                             onChange={this.handleChange}

                                              name="radio1" />{' '}
                                            No 
                                            </Label>
                                        </FormGroup>
                                        </FormGroup>
                                        
                                </CardText>
                                    </CardBody>
                                </Card>
                                <br/>
                                <div className='text-center'>
                                <Button style={{color:'black',backgroundColor:'#FD6321', borderRadius:'4rem',borderColor:'#FD6321'}}type="submit" value="Submit"  >Next</Button>
                                </div>
                                </Form>
                               
                               
                            </div>
                        }
                        {/*  ==== Stage 2 ==== */}
                         {
                            stage2 && this.props.globalDetails.signState&&
                            <div>
                                 {!countryError?<Form onSubmit={this.handleSubmit}>
                                <Card>
                                    <CardBody>
                                    <CardTitle className="m-0 p-0 fs-6"><strong>Deployment & Company Details</strong></CardTitle>
                                    <CardSubtitle>For deployments outside of the United States to meet US Export Reporting requirements we need the deployment address of the Aviatrix Edge Image and your company headquarter address.</CardSubtitle>
                                    <hr/>
                                    <CardText>
                                        <br/>
                                        <legend >Deployemnt Address</legend>
                                            <FormGroup>
                                            <Label for="deploymentAddress1">Address Line 1<sup>*</sup></Label>
                                            <Input
                                                required={true}
                                                type="deploymentAddress1"
                                                name="deploymentAddress1"
                                                id="deploymentAddress1"
                                                value={deploymentAddress1}
                                                onChange={this.handleForm}
                                            />
                                            </FormGroup>
                                            <br/>
                                            <FormGroup>
                                            <Label for="deploymentAddress2">Address Line 2</Label>
                                            <Input
                                                
                                                type="deploymentAddress2"
                                                name="deploymentAddress2"
                                                id="deploymentAddress2"
                                                value={deploymentAddress2}
                                                onChange={this.handleForm}
                                            />
                                            </FormGroup>
                                            <br/>
                                            <FormGroup>
                                            <Label for="deploymentState">City/State/Province<sup>*</sup></Label>
                                            <Input
                                                required
                                                type="deploymentState"
                                                name="deploymentState"
                                                id="deploymentState"
                                                value={deploymentState}
                                                onChange={this.handleForm}
                                            />
                                            </FormGroup>
                                            <br/>
                                            <FormGroup>
                                            <Label for="deploymentCountry">Country<sup>*</sup></Label>
                                            <Input required type="select" name="deploymentCountry" id="deploymentCountry" value={deploymentCountry} onChange={this.handleForm}>
                                                <option value="">Select a country</option>
                                                {countries.map((c) => (
                                                <option key={c} value={c}>
                                                    {c}
                                                </option>
                                                ))}
                                            </Input>
                                            {/* {countryError && <FormText color="danger">{countryError}</FormText>} */}

                                            </FormGroup>
                                            {/* <FormGroup>
                                            <Input type="submit" value="Submit" />
                                            </FormGroup> */}
                                        <hr/>

                                        
                                        <legend >Corporate Headquarter Address</legend>
                                       
                                        <FormGroup>
                                            <Label for="companyAddress1">Address Line 1<sup>*</sup></Label>
                                            <Input
                                                required
                                                type="companyAddress1"
                                                name="companyAddress1"
                                                id="companyAddress1"
                                                value={companyAddress1}
                                                onChange={this.handleForm}
                                            />
                                            </FormGroup>
                                            <br/>
                                            <FormGroup>
                                            <Label for="companyAddress2">Address Line 2</Label>
                                            <Input
                                                
                                                type="companyAddress2"
                                                name="companyAddress2"
                                                id="companyAddress2"
                                                value={companyAddress2}
                                                onChange={this.handleForm}
                                            />
                                            </FormGroup>
                                            <br/>
                                            <FormGroup>
                                            <Label for="companyState">City/State/Province<sup>*</sup></Label>
                                            <Input
                                                required
                                                type="companyState"
                                                name="companyState"
                                                id="companyState"
                                                value={companyState}
                                                onChange={this.handleForm}
                                            />
                                            </FormGroup>
                                            <br/>
                                            <FormGroup>
                                            <Label for="companyCountry">Country<sup>*</sup></Label>
                                            <Input required type="select" name="companyCountry" id="companyCountry" value={companyCountry} onChange={this.handleForm}>
                                                <option value="">Select a country</option>
                                                {countries.map((c) => (
                                                <option key={c} value={c}>
                                                    {c}
                                                </option>
                                                ))}
                                            </Input>

                                            </FormGroup>
                                            {/* <FormGroup>
                                            <Input type="submit" value="Submit" />
                                            </FormGroup> */}
                                                                          
                                </CardText>
                                    </CardBody>
                                </Card>
                                <br/>
                                <div className='text-center'>
                                <Button style={{color:'black',backgroundColor:'#FD6321', borderRadius:'4rem',borderColor:'#FD6321'}} color='primary'type="submit" value="Submit" >Next</Button>
                                </div>
                                </Form>:
                                <FormText color="danger">{countryError}</FormText>
                                 }
                               
                               
                            </div>
                        }
                        
                        {/* ==== List of Software images found ==== */}
                        {
                            (images.length !== 0 && this.props.globalDetails.signState) && stage3 &&
                                    <div className="row" >
                                        {/* <p className="shadow-p">Software Images</p> */}
                                            {/* <div className="col-sm-9 col-md-8 table-col"> */}
                                                {
                                                //    images.map((value)=>{
                                                //         return(
                                                //             <div className="row" >
                                                //             <div className="col-sm-8 col-md-8 table-col">
                                                //             <span>{value=='MD5_SHA.txt'?'MD5 and SHA of Images':value=='avx-edge-gateway-vmware-2022-10-26.ova'?'Aviatrix Edge Image for VMware ESXi hypervisor':'Aviatrix Edge Image for KVM/QEMU based hypervisor'}</span>
                                                //             </div>
                                                //             <div className="col-sm-4 col-md-4 table-col text-center">
                                                //             <span className="text-danger selector-pointer" onClick={()=>{value=='MD5_SHA.txt'?this.downloadFile("Text", value):this.downloadFile("Image", value)}}>Download</span>

                                                //                 </div>
                                                //             </div>
                                                //         )
                                                //     })
                                                }

                                             <table class="table text-center samll-font-EOL">
                                                <thead>
                                                    <tr className='bg-danger text-white' >
                                                        <th scope="col" className='col-md-1 border border-2 border-white' >Controller Release</th>
                                                        <th scope="col" className='col-md-3  fw-normal border border-2 border-white' >Vmware ESXi</th>
                                                        <th scope="col" className='col-md-3  fw-normal border border-2 border-white' >KVM/QEMU</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                
                                                    <tr style={{backgroundColor:'#F3CECC'}}>
                                                        <td className='border border-2 border-white'>7.1</td>
                                                        <td className='border border-2 border-white '><div onClick={()=>{this.downloadFile("Image", imagesMap["avx-edge-esxi-7.1-2023-04-24.ova"])}} className='text-danger selector-pointer'>Download Image</div>   <br/><div onClick={()=>{this.downloadFile("Text", imagesMap["avx-edge-esxi-7.1-2023-04-24.ova.metadata"])}} className='text-danger selector-pointer'>MD5, SHA</div> </td>
                                                        <td className='border border-2 border-white '><div onClick={()=>{this.downloadFile("Image", imagesMap["avx-edge-kvm-7.1-2023-04-24.qcow2"])}} className='text-danger selector-pointer'>Download Image</div> <br/><div onClick={()=>{this.downloadFile("Text", imagesMap["avx-edge-kvm-7.1-2023-04-24.qcow2.metadata"])}} className='text-danger selector-pointer'>MD5, SHA</div></td>
                                                    </tr>
                                                    <tr style={{backgroundColor:'#F9E8E7'}}>
                                                        <td className='border border-2 border-white'>6.8,6.9,7.0</td>
                                                        <td className='border border-2 border-white '><div onClick={()=>{this.downloadFile("Image", imagesMap["avx-edge-gateway-vmware-7.0-2023-05-18.ova"])}} className='text-danger selector-pointer'>Download Image</div><br/><div onClick={()=>{this.downloadFile("Text", imagesMap["avx-edge-gateway-vmware-7.0-2023-05-18.ova.metadata"])}} className='text-danger selector-pointer'>MD5, SHA</div></td>
                                                        <td className='border border-2 border-white '><div onClick={()=>{this.downloadFile("Image", imagesMap["avx-edge-gateway-kvm-7.0-2023-05-18.qcow2"])}} className='text-danger selector-pointer'>Download Image</div> <br/><div onClick={()=>{this.downloadFile("Text", imagesMap["avx-edge-gateway-kvm-7.0-2023-05-18.qcow2.metadata"])}} className='text-danger selector-pointer'>MD5, SHA</div></td>
                                                    </tr>
                                                
                                                </tbody>
                                             </table>
                                    </div>
                        }

                        <br></br>

                    </div>
                    <br className="hide-on-mobile"/>
                    <br className="hide-on-mobile"/>


                    {/* ==== Login Modal ==== */}
                    <LoginModal></LoginModal>

                    {/* ==== Sign up Modal ==== */}
                    <SignupModal></SignupModal>

                    {/* ==== Footer Component ==== */}
                    <Footer></Footer>

                </div>
            </React.Fragment >
        )
    }
}



// export default Download

const mapStateToProps = state => ({
    globalDetails: state

})

const mapDispatchToProps = dispatch => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Download)




// export default Home;