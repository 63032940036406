import React from 'react'
import { Breadcrumbs, Link, Typography, ThemeProvider, createMuiTheme } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";

import Header from '../components/header'
import Footer from '../components/footer'
import LoginModal from '../components/loginModal'
import SignupModal from '../components/signupModal'
import DocNavigationMenu from '../components/docNavigationMenu'
import ReactParser, {attributesToProps, domToReact} from 'html-react-parser'

import * as PrimaryService from '../services/service'
import * as SetWidget from '../operationalHelper/populateWidget'



//Material UI Theme for Chips
const BreadcrumbsTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#FF6307',
        },
        secondary: {
            main: '#000',
        },
    },
});

class DocumentTemplate extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            expandedMenuItemsList: [],
            selectedArticle: null,
            selectedArticleTitle: '',
            displayState: "empty",     // ==== Enum = empty | search | article  ====

            searchValue: '',
            searchArr: [],
            searchCount: 0,
            isIndexMenuOpen: false
        }
        this.override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
        `;
        this.indexRef = React.createRef()

        // ==== Options for React HTML Parser ====
        this.parserOptions = {
            replace: domNode => {
              if (domNode.attribs && domNode.name === 'img') {                
                domNode.attribs.style = domNode.attribs.style ? domNode.attribs.style + " max-width:65%; height:auto; width:auto;" : "max-width:65%; height:auto; width: auto; object-fit:contain;"
                const props = attributesToProps(domNode.attribs);
                return <img {...props} />;
              }
              else if(domNode.attribs && domNode.name === 'a'){
                domNode.attribs.style = "color:#E24402;"
                domNode.attribs.target = "_blank"
                const props = attributesToProps(domNode.attribs);

                return <u><a {...props} >{domToReact(domNode.children, this.parserOptions)}</a></u>;
              }
            }
          };
    }

    componentDidMount() {
        SetWidget.populateWidget()
    }

    inputHandler = (e) => {
        console.log(e.target.value)
        // console.log(e.keyCode)
        if (e.target.name === "search") {
            this.setState({
                searchValue: e.target.value
            })
        }
    }

    toggleIndexMenu = (val) => {
        this.setState({ isIndexMenuOpen: val });
    }

    jumpToHome = () => {
        window.open(window.location.origin, "_self")
    }

    // ==== API based Methods ====
    searchArticles = (e) => {
        if (e.keyCode !== 13) {
            return
        }
        let searchParams = {
            searchValue: this.state.searchValue,
            // categoryId: "360004231251",
            categoryId: this.indexRef.current.externalFetchCategoryId()
        }

        console.log("Printing Search Param !! ", searchParams.categoryId)

        PrimaryService.searchArticlesByCategory(searchParams).then((result) => {
            this.setState({
                searchArr: result.data.articles.results,
                searchCount: result.data.articles.count,
                displayState: "search"
            })
        }).catch((err) => {
            console.log(err)
        })
    }


    showArticle = (articleBody, articleTitle) => {
        this.setState({
            selectedArticle: articleBody,
            selectedArticleTitle: articleTitle,
            displayState: "article"
        }, () => {
            window.scroll(0, 0)
        })
    }


    testRender = () => {
        let basicHTML = `<main class="prettify" style="background: #fff; text-align: center;"></main>`

        // console.log("Starting print!!")
        // console.log(basicHTML)
        // console.log(ReactParser(basicHTML, this.parserOptions))
    }



    render() {
        let { searchArr, searchCount } = this.state
        this.testRender()

        console.log(this.state.selectedArticle);
        return (
            <React.Fragment>
                <div className="min-height-full">
                    {/* ==== Header for Page ==== */}
                    <Header parent="document-page"></Header>
                    <br />
                    <br className="hide-on-mobile" />
                    <br className="hide-on-mobile" />
                    <br className="hide-on-mobile" />
                    <div className="document-wrapper">

                        <DocNavigationMenu ref={this.indexRef} openArticle={this.showArticle} isOpen={this.state.isIndexMenuOpen} toggleIndexMenu={this.toggleIndexMenu}/>
                        <div className="document-body-wrapper">
                            <div className="document-body-head">
                                {/* <div className="breadcrum-wrapper invisible hide-on-mobile">
                                    <ThemeProvider theme={BreadcrumbsTheme}>
                                        <Breadcrumbs aria-label="breadcrumb">
                                            <Link color="secondary" href="/">
                                                <Typography color="secondary">Docs</Typography>
                                            </Link>
                                            <Link href="/getting-started/installation/">
                                                <Typography color="primary">Getting Started</Typography>
                                            </Link>

                                        </Breadcrumbs>
                                    </ThemeProvider>
                                </div> */}
                                <div>
                                    <button type="button"
                                                className=" btn fs-2 btn-outline-danger mx-1 m-2 " onClick={this.jumpToHome} > <FontAwesomeIcon icon={faLongArrowAltLeft} /> &nbsp; &nbsp; Back to Home</button>
                                </div>
                                <div className="show-on-mobile mobile-index-ham" onClick={() => { this.toggleIndexMenu(true) }}>
                                    <img src="../img/menu.png" width="25px"></img>
                                </div>

                                <div className="search-bar-wrapper">
                                    <div className="input-group outline-0 "> <span className="input-group-text outline-0 border-0" id="basic-addon1"> <img id="inputImg" src="./img/Vector.png" height={24} /> </span>
                                        <input type="text" className="form-control ffff py-2 border-0" placeholder="Search" name="search" onChange={this.inputHandler} onKeyDown={this.searchArticles} />
                                    </div>
                                </div>
                            </div>

                            {/* ==== Document Body ==== */}
                            {
                                this.state.displayState === "empty" ?
                                    <div className="pt-5"><center><PropagateLoader css={this.override} size={15} color={"#e24402"} loading={true} /></center></div>
                                    :
                                    <React.Fragment>
                                {/* // this.state.selectedArticle !== null ? */}
                                    {
                                        this.state.displayState === "article" ?
                                            <div className="pt-3 px-3 htmlParsedSection">
                                                <h2 className="doc-title">{this.state.selectedArticleTitle}</h2>
                                                {/* {ReactParser(this.state.selectedArticle)} */}
                                                {ReactParser(this.state.selectedArticle, this.parserOptions)}
                                            </div>
                                            :
                                            (<React.Fragment>
                                                <br />
                                                <h2 className="doc-search-title" onClick={this.dummyMethod} >Search Results </h2>
                                                <p className="color-n border-b pb-3"> {searchCount} Results Found</p>
                                                <div className="doc-search-wrapper">
                                                    {
                                                        searchArr.length !== 0 ?
                                                            searchArr.map((value) => {
                                                                return (
                                                                    <React.Fragment>
                                                                        <div className="selector-pointer" onClick={() => { this.indexRef.current.redirectLoad(value.id, value.section_id, value) }} >
                                                                            <h2 className="fs-5 color-n"> {value.title}</h2>
                                                                            <p className="fs-3"> {ReactParser(value.snippet)}</p>
                                                                        </div>
                                                                        <hr></hr>
                                                                    </React.Fragment>
                                                                )
                                                            })
                                                            :
                                                            <div className="no-results-overlay">
                                                                <h4 className="fs-4 fw-800">No Search Results</h4>
                                                                <p className="fs-2">Check your spelling or try reframing the question</p>
                                                            </div>
                                                    }
                                                </div>

                                                {/* {
                                                    true == false ? (
                                                        <div className="doc-search-wrapper">
                                                            <div className="">
                                                                <h2 className="fs-5 color-n"> Adding SAAS Application Tenants</h2>
                                                                <p className="fs-3"> This deployement guide provides information on prerequisites,how to deplay a ZPA Private Service
                                                    Edge on Amazon Web Services(AWS) and verification checks</p>
                                                            </div>
                                                            <hr></hr>
                                                            <div className="">
                                                                <h2 className="fs-5 color-n"> Adding SAAS Application Tenants</h2>
                                                                <p className="fs-3"> This deployement guide provides information on prerequisites,how to deplay a ZPA Private Service
                                                    Edge on Amazon Web Services(AWS) and verification checks</p>
                                                            </div>
                                                            <hr></hr>
                                                            <div className="">
                                                                <h2 className="fs-5 color-n"> Adding SAAS Application Tenants</h2>
                                                                <p className="fs-3"> This deployement guide provides information on prerequisites,how to deplay a ZPA Private Service
                                                    Edge on Amazon Web Services(AWS) and verification checks</p>
                                                            </div>
                                                            <hr></hr>
                                                            <div className="">
                                                                <h2 className="fs-5 color-n"> Adding SAAS Application Tenants</h2>
                                                                <p className="fs-3"> This deployement guide provides information on prerequisites,how to deplay a ZPA Private Service
                                                    Edge on Amazon Web Services(AWS) and verification checks</p>
                                                            </div>
                                                            <hr></hr>
                                                            <div className="">
                                                                <h2 className="fs-5 color-n"> Adding SAAS Application Tenants</h2>
                                                                <p className="fs-3"> This deployement guide provides information on prerequisites,how to deplay a ZPA Private Service
                                                    Edge on Amazon Web Services(AWS) and verification checks</p>
                                                            </div>
                                                            <hr></hr>
                                                            <div className="">
                                                                <h2 className="fs-5 color-n"> Adding SAAS Application Tenants</h2>
                                                                <p className="fs-3"> This deployement guide provides information on prerequisites,how to deplay a ZPA Private Service
                                                    Edge on Amazon Web Services(AWS) and verification checks</p>
                                                            </div>
                                                            <hr></hr>
                                                        </div>
                                                    ) : (
                                                            <div className="no-results-overlay">
                                                                <h4 className="fs-4 fw-800">No Search Results</h4>
                                                                <p className="fs-2">Check your spelling or try reframing the question</p>
                                                            </div>
                                                        )
                                                } */}
                                    </React.Fragment>)
                                }
                                </React.Fragment>
                            }
                        </div>
                    </div>

                    {/* ==== Login Modal ==== */}
                    <LoginModal></LoginModal>

                    {/* ==== Sign up Modal ==== */}
                    <SignupModal></SignupModal>

                    {/* ==== Footer Component ==== */}
                    <Footer></Footer>

                </div>
            </React.Fragment >
        )
    }
}

export default DocumentTemplate


// export default Home;