import React from "react";
import { IconButton } from "@mui/material";
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import "./style.css"
import { getAllNotices } from "../../services/noticesService";
import { getStatus } from "../../operationalHelper/formattedDate";


const Admin = ()=>{
    const history = useHistory()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [list,setList]= React.useState([])


    React.useEffect(()=>{
     if(user?.userType === 'customer'){
         getNotifications()

     }
     },[])


const getNotifications = ()=>{
    // setLoading(true)
    getAllNotices().then((res)=>{
        console.log({res})
        setList(res.data?.response)
        // setLoading(false)
    })
}
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const user = useSelector((storeState)=>storeState);
    let countNotification = list?.Items?.filter((item)=>!item?.userIDs.includes(user.userId) && item?.endDate> new Date().toJSON())
    // let activeNoticesCount = countNotification
    // .filter(notice=>getStatus(notice?.endDate))
   console.log(countNotification,"...countNotification")
//    console.log(user,"...user")
//    console.log(countNotification.filter(a=>a.Notice_ID==1693518483787),"...countNotification")
    return(
        <div className="admin-div">
        <IconButton  size="small" className="action" onClick={handleClick} >
        {/* <Icon color='red' className="icon-green">account _circle</Icon> */}
        <AccountCircleRoundedIcon style={{fontSize:'2.5rem',color:"black"}}/>
    
    </IconButton>
         <Menu
    id="demo-positioned-menu"
    aria-labelledby="demo-positioned-button"
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    anchorOrigin={{
        vertical: 'bottom',
    horizontal: 'right',
    }}
    transformOrigin={{
    vertical: 'bottom',
    horizontal: 'right',
}}
    >
    <MenuItem onClick={()=>{
    window.location.href = '/';
    handleClose()
}}>Home</MenuItem>
    <MenuItem onClick={()=>{
    history.push('/notifications');
    handleClose()
}}>Notifications</MenuItem>

{user?.role === 'admin' &&
    <MenuItem onClick={()=>{
    history.push('/support-notices');
    
    handleClose()
}}>Manage Support Notices</MenuItem>
}

    </Menu> 
    {
        user?.role !== 'admin'  ?
      <IconButton  size="small" className="action realtive-icon" onClick={()=>history.push('/notifications')} >
        <CircleNotificationsIcon style={{fontSize:'2.5rem',color:"black"}}/>

       { countNotification?.length ? <div className="icon-style" style={{right:-10,top:4}}>
         <p style={{textAlign:'center',marginTop:2,color:"white",fontSize:'14px'}}>
         {countNotification?.length}
            </p> 
        </div>:null }  
    
    </IconButton>:null
    }
</div>
    )
}


export default Admin