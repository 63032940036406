import React from 'react'
import { Link } from 'react-router-dom'
import ls from 'local-storage'
import { connect } from 'react-redux';
// import JSEncrypt from 'jsencrypt'


import Header from '../components/header'
import Footer from '../components/footer'
import LoginModal from '../components/loginModal'
import SignupModal from '../components/signupModal'

import * as PrimaryServices from '../services/service'
import * as GlobalActions from '../store/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import * as SetWidget from '../operationalHelper/populateWidget'

import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";



class ApiPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            enableView: false,
            collections: [],

            searchInProgress: true
        }
        this.override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
        `;
    }

    componentDidMount() {
        if(this.props.globalDetails.signState){
            let userDetails = {
                userId: this.props.globalDetails.userId
            }
            PrimaryServices.getDownloadCollectionList(userDetails).then((result) => {
                console.log(result)
                this.setState({
                    collections: result.data.collectionFiles,
                    enableView: true,
                    searchInProgress: false,
                })
            }).catch((err)=>{
                console.log(err)
            })
        }else{
            this.setState({
                searchInProgress: false
            })
        }
        SetWidget.populateWidget()
    }



    downloadFile = (fileType, fileName) => {
        let mainFileType = ""
        if(fileType === "Postman"){
            mainFileType = "collectionBucket"
        }else{
            mainFileType = "imageBucket"
        }

        let userDetails = {
            user: this.props.globalDetails.userName,
            email: this.props.globalDetails.userEmail,
            fileType: fileType,
            fileName: fileName
        }

        let fileDetails = {
            bucket: mainFileType,
            key: fileName,
            userDetails: userDetails
        }

        PrimaryServices.downloadFile(fileDetails).then((result)=>{
            console.log(result)
            window.open(result.data.url, "_self")
        }).catch((err)=>{
            console.log(err)
        })
    }



    render() {
        let {enableView, collections, images, searchInProgress} = this.state
        return (
            <React.Fragment>
                <div className="min-height-full">
                    {/* ==== Header for Page ==== */}
                    <Header parent="SLA"></Header>
                    <br />
                    <br />
                    <br className="hide-on-mobile"/>
                    <br className="hide-on-mobile"/>
                    <br className="hide-on-mobile"/> 
                    <div className="container width-wrapper pt-5 p-md-b-5">
                        <div className="row">
                        <h2 className="m-0 p-0 fs-8"><strong>Aviatrix API Collection</strong></h2>                        
                        </div>

                        <br></br>
                        {
                            searchInProgress ?
                                <center>
                                    <center><PropagateLoader css={this.override} size={20} color={"#e24402"} loading={true} /></center>
                                </center>
                                :
                                ''
                        }

                        {/*  ==== Anonymous user ==== */}

                        {
                            this.props.globalDetails.signState === false && 
                                <p>Please login to view and Download files.</p>
                        }

                        {/* ==== List of Collection files ==== */}
                        {
                            (collections.length !== 0 && this.props.globalDetails.signState) &&
                                    <div className="row" >
                                        <p className="shadow-p">Postman Collection</p>
                                            <div className="download-table-col">
                                                {
                                                   collections.map((value)=>{
                                                        return(
                                                            <div className="download-flex-row">
                                                                <span className="download-flex-content">{value}</span>
                                                                <span className="text-danger selector-pointer download-flex-btn" onClick={()=>{this.downloadFile("Postman", value)}}>Download</span>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                    </div>
                        }

                        <br></br><br></br>                

                        {
                            !searchInProgress && collections.length > 0 && this.props.globalDetails.signState ? 
                                <div className="row">
                                    <p className="fs-3 text-danger"><a href="https://aviatrix-support-collection-document.s3.amazonaws.com/Aviatrix+Postman+API+Guide.pdf" target="_blank">
                                        <strong>Aviatrix API Guide</strong>  (How to use this Postman Collection?)</a>
                                        </p>
                                </div>
                                :
                                ''
                        }

                    </div>

                    {/* ==== Login Modal ==== */}
                    <LoginModal></LoginModal>

                    {/* ==== Sign up Modal ==== */}
                    <SignupModal></SignupModal>

                    {/* ==== Footer Component ==== */}
                    <Footer></Footer>

                </div>
            </React.Fragment >
        )
    }
}



// export default Download

const mapStateToProps = state => ({
    globalDetails: state

})

const mapDispatchToProps = dispatch => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ApiPage)




// export default Home;