// ==== Libraries used ====
import { createStore, compose, applyMiddleware } from 'redux'
import { persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage' 
import {createStateSyncMiddleware, initMessageListener} from "redux-state-sync";

// ==== Local modules used ====
import GlobalDetails from './reducer'


// ==== Compose Enhancers are used for adding multiple Enhancers or Higher order functions to a single store ====
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const reduxStateSyncConfig = {}
// const composeEnhancers = [applyMiddleware(createStateSyncMiddleware(reduxStateSyncConfig),  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose )]
const persistConfig = {
    key: 'root',
    storage
}



const persistReducerVal = persistReducer(persistConfig, GlobalDetails )
// let store = createStore(persistReducerVal, composeEnhancers())
// let store = createStore(persistReducerVal, applyMiddleware(createStateSyncMiddleware(reduxStateSyncConfig)))
let store = createStore(persistReducerVal, compose(
    applyMiddleware(createStateSyncMiddleware(reduxStateSyncConfig)),
    composeEnhancers()
))
let persistor = persistStore(store)

initMessageListener(store)

export {store, persistor}
// export const storeKey = store
// export const persistorKey = persistor